import { useEffect, useState } from "react";
import "./AdminSpecificUser.scss";
import { IActivity, IBooking, ICourt, IUser } from "../../types/api";
import { capitalizeWords } from "../../shared/utility";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";

import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { StatusEnum } from "../../shared/enums";
import { toast } from "react-toastify";

interface IAdminSpecificUserProps {}

function AdminSpecificUser(props: IAdminSpecificUserProps) {
  const { memberId } = useParams<string>();
  const [currentHistoryType, setCurrentHistoryType] = useState<
    "bookings" | "activities"
  >("bookings");
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [activityBookings, setActivityBookings] = useState<IActivity[]>([]);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const fetchChosenItems = async ({
    fetchUserData = false,
    fetchBookingsByMember = false,
    fetchCourts = false,
    fetchActiveActivities = false,
  }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        member_id: memberId,
        fetchUserData: fetchUserData,
        active: false,
        fetchBookingsByMember: fetchBookingsByMember,
        fetchActiveActivities: fetchActiveActivities,
        fetchCourts: fetchCourts,
      });

      if (
        fetchUserData &&
        response.userData &&
        !("message" in response.userData)
      ) {
        setUser(response.userData);
      }
      if (
        fetchBookingsByMember &&
        response.bookingsByMember &&
        !("message" in response.bookingsByMember)
      ) {
        setBookings(response.bookingsByMember);
      }
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }

      if (
        fetchActiveActivities &&
        response.activeActivities &&
        !("message" in response.activeActivities)
      ) {
        setActivityBookings(response.activeActivities);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchUserData: true,
      fetchBookingsByMember: true,
      fetchCourts: true,
      fetchActiveActivities: true,
    });
  }, []);

  const handleToggleHistoryType = (type: "bookings" | "activities") => {
    setCurrentHistoryType(type);
  };

  if (!user || loading) return <Spinner />;
  return (
    <div className="admin-handle-specific-user-layout">
      <h1 className="text-l primary-text">
        Historik för{" "}
        {capitalizeWords(user.first_name) +
          " " +
          capitalizeWords(user.last_name)}
      </h1>
      <div className="handle-specific-user-actions-wrapper">
        <div className="history-toggle-wrapper">
          <button
            className={`toggle-button text-m ${
              currentHistoryType === "bookings" ? "active" : ""
            }`}
            onClick={() => handleToggleHistoryType("bookings")}
          >
            Bokningar
          </button>
          {activityBookings.length > 0 && (
            <button
              className={`toggle-button text-m ${
                currentHistoryType === "activities" ? "active" : ""
              }`}
              onClick={() => handleToggleHistoryType("activities")}
            >
              Aktiviteter
            </button>
          )}
        </div>
        <div className="users-add-wrapper"></div>
      </div>
      <table className="history-table">
        <thead>
          <tr>
            <th className="text-m secondary-text">Datum & tid</th>
            <th className="text-m secondary-text">Bana</th>
            {currentHistoryType === "bookings" ? (
              <>
                <th className="text-m secondary-text">Status på bokning</th>
                <th className="text-m secondary-text">Bokare</th>
                <th className="text-m secondary-text">Spelare</th>
              </>
            ) : (
              <>
                <th className="text-m secondary-text">Aktivitet</th>
                <th className="text-m secondary-text">Spelare</th>
                <th className="text-m secondary-text">Färg</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {currentHistoryType === "bookings" &&
            bookings.map((booking) => {
              const playerAmount =
                booking.co_players.length +
                booking.guests.length +
                1 +
                booking.coach_id.length;
              const courtData = courts.find(
                (court) => booking.court_id === court.court_id
              );

              return (
                <tr key={booking.booking_id}>
                  <td className="text-m">
                    {booking.datetime_start.slice(0, 10)} ---{" "}
                    {booking.datetime_start.slice(11, 16)}
                  </td>
                  <td className="text-m">{courtData?.name}</td>
                  <td className="text-m">{booking.status}</td>
                  <td className="text-m">
                    {capitalizeWords(booking.member_name)}
                  </td>
                  <td className="text-m">{playerAmount}</td>
                </tr>
              );
            })}
          {currentHistoryType === "activities" &&
            activityBookings.map((activity, index) => {
              const courtData = courts.find(
                (court) => activity.court_id[0] === court.court_id
              );
              return (
                <tr key={index}>
                  <td className="text-m">
                    {activity.datetime_start.slice(0, 10)} ---{" "}
                    {activity.datetime_start.slice(11, 16)}
                  </td>
                  <td className="text-m">{courtData?.name}</td>
                  <td className="text-m">{activity.name.split("%-%")[0]}</td>
                  <td className="text-m">{activity.participants.length}</td>
                  <td className="text-m">
                    <div
                      className={`activity-color-blob ${activity.color}`}
                    ></div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminSpecificUser;
