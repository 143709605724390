import "./EditUserPopup.scss";
import { Fragment, useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IEditUser, IPermission, IUser } from "../../../types/api";
import { toast } from "react-toastify";
import {
  deleteUser,
  editUser,
  getUserByMemberId,
} from "../../../api/users/users";
import {
  capFirstAndRemoveDash,
  capitalizeWords,
  formatPersonalNumber,
  formatPhoneNumber,
} from "../../../shared/utility";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { TitleEnum } from "../../../shared/enums";
import {
  emailRegex,
  phoneRegex,
  socialSecurityRegex,
} from "../../../shared/regex";
import remove from "../../../assets/images/remove.svg";
import {
  activateMembership,
  deactivateMembership,
} from "../../../api/queueUser/queueUsers";

interface IEditUserPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  userId: string;
  permissions: IPermission[];
}

const initialUserData: IEditUser = {
  account_type: "",
  first_name: "",
  last_name: "",
  member_type: "AKTIV_SPELRATT",
  email: "",
  email_cc: "",
  phone_number: "",
  work_phone: "",
  home_phone: "",
  birth_data: "",
  adress: "",
  massemail: false,
  city: "",
  rehab_access: false,
  gym_access: false,
  gender: "",
  comment: "",
  zip_code: "",
  country: "",
  company: "",
  title: "",
  ranking: -1,
  hidden_info: false,
  reference: "",
  noshow_count: 0,
};

function EditUserPopup(props: IEditUserPopupProps) {
  const [hasUserDataChanged, setHasUserDataChanged] = useState<boolean>(false);
  const [showHandleDeleteMemeberPopup, setShowHandleDeleteMemeberPopup] =
    useState<boolean>(false);

  const [showHandleActivateMemeberPopup, setShowHandleActivateMemeberPopup] =
    useState<boolean>(false);

  const [
    showHandleDeactivateMemeberPopup,
    setShowHandleDeactivateMemeberPopup,
  ] = useState<boolean>(false);
  const [userData, setUserData] = useState(initialUserData);
  const [activationPermission, setActivationPermission] =
    useState<IPermission | null>(null);
  const [originalData, setOriginalData] = useState<IEditUser>(initialUserData);
  const [memberTypeHasChanged, setMemberTypeHasChanged] =
    useState<boolean>(false);
  const titleValues = Object.values(TitleEnum);
  const rankingValues = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const [home_phoneToggle, setHomePhoneToggle] = useState(false);
  const [work_phoneToggle, setWorkPhoneToggle] = useState(false);
  const handleClose = () => {
    setActivationPermission(null);
    setUserData(initialUserData);
    setOriginalData(initialUserData);
    props.onClose();
  };

  const [ccEmails, setCcEmails] = useState<string[]>([]);

  const currentPermission = props.permissions.find(
    (permission) => permission.member_type === userData.member_type
  );
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!props.userId) return;
        const response = await getUserByMemberId(props.userId);
        if (response) {
          const filteredData = Object.keys(initialUserData).reduce(
            (acc, key) => {
              if (response.hasOwnProperty(key)) {
                if (key === "home_phone") {
                  if (response[key] === null) setHomePhoneToggle(false);
                  else setHomePhoneToggle(true);
                }
                if (key === "work_phone") {
                  if (response[key] === null) setWorkPhoneToggle(false);
                  else setWorkPhoneToggle(true);
                }
                if (key === "massemail") {
                  //saved in database as string, need to convert to boolean
                  acc[key] = response[key] === "true";
                  return acc;
                }
                if (response[key] === null) {
                  acc[key] = "";
                  return acc;
                }
                acc[key] = response[key];
              }
              return acc;
            },
            {} as Partial<IUser>
          );
          setUserData({ ...initialUserData, ...filteredData });
          setOriginalData({ ...initialUserData, ...filteredData });
          setCcEmails((filteredData.email_cc || "").split(";").filter(Boolean));
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    fetchUser();
  }, [props.userId]);

  useEffect(() => {
    const currentPermission = props.permissions.find(
      (permission) => permission.member_type === userData.member_type
    );
    if (currentPermission) {
      setUserData((prevData) => ({
        ...prevData,
        hidden_info: currentPermission.hidden,
      }));
    }
  }, [memberTypeHasChanged]);

  const checkIfCcEmailsChanged = (
    currentCcEmails: string[],
    originalCcEmails: string
  ) => {
    const originalCcEmailsArray = originalCcEmails
      ? originalCcEmails.split(";").filter(Boolean)
      : [];
    return (
      JSON.stringify(currentCcEmails) !== JSON.stringify(originalCcEmailsArray)
    );
  };
  const handleCcEmailChange = (index: number, value: string) => {
    setCcEmails((prevEmails) => {
      const updatedEmails = prevEmails.map((email, i) =>
        i === index ? value : email
      );
      setHasUserDataChanged(
        checkIfCcEmailsChanged(
          updatedEmails,
          originalData.email_cc?.toString() || ""
        )
      );
      return updatedEmails;
    });
  };

  const addCcEmail = () => {
    setCcEmails((prevEmails) => {
      const updatedEmails = [...prevEmails, ""];
      setHasUserDataChanged(
        checkIfCcEmailsChanged(
          updatedEmails,
          originalData.email_cc?.toString() || ""
        )
      );
      return updatedEmails;
    });
  };
  const removeCcEmail = (index: number) => {
    setCcEmails((prevEmails) => {
      const updatedEmails = prevEmails.filter((_, i) => i !== index);
      setHasUserDataChanged(
        checkIfCcEmailsChanged(
          updatedEmails,
          originalData.email_cc?.toString() || ""
        )
      );
      return updatedEmails;
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const isInput = (element: EventTarget): element is HTMLInputElement =>
      element instanceof HTMLInputElement;

    const { name, value } = e.target;

    let inputValue: string | boolean | number = value;
    if (isInput(e.target) && e.target.type === "checkbox") {
      inputValue = !e.target.checked;
    }
    if (name === "email") inputValue = value.toLowerCase();
    if (name === "birth_data") {
      inputValue = formatPersonalNumber(value);
    }
    if (
      name === "phone_number" ||
      name === "home_phone" ||
      name === "work_phone"
    ) {
      inputValue = formatPhoneNumber(value);
    }

    if (name === "member_type") {
      setMemberTypeHasChanged(!memberTypeHasChanged);
      if (
        props.permissions.find((permission) => permission.member_type === value)
          ?.gym_access === false
      ) {
        setUserData((prevData) => ({
          ...prevData,
          gym_access: false,
        }));
      }
      if (
        value !== "Aktiv spelrätt" &&
        value !== "Temporär spelrätt" &&
        value !== "Företag" &&
        value !== "Företag B" &&
        value !== "Hedersledarmot" &&
        value !== "Elitnål"
      ) {
        setUserData((prevData) => ({
          ...prevData,
          title: "",
          ranking: -1,
        }));
      }
    }
    setUserData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const checkIfDataHasChanged = (
    currentData: IEditUser,
    originalData: IEditUser
  ): boolean => {
    return (Object.keys(originalData) as Array<keyof IEditUser>).some(
      (key) => currentData[key] !== originalData[key]
    );
  };

  useEffect(() => {
    const hasChanged = checkIfDataHasChanged(userData, originalData);
    setHasUserDataChanged(hasChanged);
  }, [userData, originalData]);

  const handleEditUser = async () => {
    const changes: Partial<IEditUser> = {};

    const concatenatedCcEmails = ccEmails.filter(Boolean).join("; ");

    const updatedUserData: IEditUser = {
      ...userData,
      email_cc: concatenatedCcEmails,
    };

    Object.keys(updatedUserData).forEach((key) => {
      if (updatedUserData[key] !== originalData[key]) {
        changes[key] = updatedUserData[key];
      }
    });

    try {
      const editResponse = await editUser(
        props.userId,
        changes,
        originalData.email
      );

      if (!editResponse)
        throw new Error("Något gick fel vid redigering av användare");
      toast.success(`Användare: ${userData.first_name} uppdaterad`);
      handleClose();
      props.onConfirm();
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };

  const handleActivateUser = async () => {
    if (!activationPermission) return;
    try {
      const response = await activateMembership(
        props.userId,
        activationPermission?.member_type || ""
      );
      if (response && !("message" in response)) {
        toast.success(`Användare: ${userData.first_name} aktiverad`);
      }
      setShowHandleActivateMemeberPopup(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };

  const handleDeactivateUser = async () => {
    try {
      const response = await deactivateMembership(props.userId);
      if (response && !("message" in response)) {
        toast.success(`Användare: ${userData.first_name} deaktiverad`);
      }
      setShowHandleDeactivateMemeberPopup(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };

  useEffect(() => {
    !home_phoneToggle &&
      setUserData((prevData) => ({
        ...prevData,
        home_phone: "",
      }));
  }, [home_phoneToggle]);

  useEffect(() => {
    !work_phoneToggle &&
      setUserData((prevData) => ({
        ...prevData,
        work_phone: "",
      }));
  }, [work_phoneToggle]);

  const handleActivationPermissionSelect = async (memberType: string) => {
    const permission = props.permissions.find(
      (permission) => permission.member_type === memberType
    );
    if (!permission) return;
    setActivationPermission(permission);
  };
  const handleDeleteUser = async () => {
    try {
      const deleteUserResponse = await deleteUser(props.userId);
      if (deleteUserResponse && !("message" in deleteUserResponse)) {
        toast.success(`Avändare: ${userData.first_name} borttagen`);
      }
      setShowHandleDeleteMemeberPopup(false);
      handleClose();
      props.onConfirm();
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">Redigera medlem {props.userId}</span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleEditUser();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="firstName" className="text-m">
                    Förnamn*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="firstName"
                    name="first_name"
                    required
                    placeholder="Förnamn"
                    value={capitalizeWords(userData?.first_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="lastName" className="text-m">
                    Efternamn*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="lastName"
                    name="last_name"
                    required
                    placeholder="Efternamn"
                    value={capitalizeWords(userData?.last_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="birthData" className="text-m">
                    Personnummer*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="birthData"
                    name="birth_data"
                    placeholder="yymmdd-xxxx"
                    pattern={socialSecurityRegex}
                    required
                    value={userData.birth_data}
                    maxLength={11}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="phoneNr" className="text-m">
                    Telefon
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="phoneNr"
                    name="phone_number"
                    placeholder="+46 70-123 4567"
                    pattern={phoneRegex}
                    required
                    maxLength={18}
                    value={userData.phone_number}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Hem telefon?</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="home_phone_toggle"
                      checked={home_phoneToggle}
                      onChange={(e) => {
                        setHomePhoneToggle((prevToggle) => !prevToggle);
                        if (e.target.value === "true") {
                          setUserData((prevData) => ({
                            ...prevData,
                            home_phone: "",
                          }));
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {home_phoneToggle && (
                  <div className="general-popup-form-field-divided-section">
                    <input
                      className="on-transparent text-m"
                      type="text"
                      id="homePhoneNr"
                      name="home_phone"
                      placeholder="+46 70-123 4567"
                      pattern={phoneRegex}
                      value={userData.home_phone}
                      maxLength={18}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Jobb telefon?</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="work_phone_toggle"
                      checked={work_phoneToggle}
                      onChange={(e) => {
                        setWorkPhoneToggle((prevToggle) => !prevToggle);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {work_phoneToggle && (
                  <div className="general-popup-form-field-divided-section">
                    <input
                      className="on-transparent text-m"
                      type="text"
                      id="workPhoneNr"
                      name="work_phone"
                      placeholder="+46 70-123 4567"
                      pattern={phoneRegex}
                      value={userData.work_phone}
                      maxLength={18}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="gender" className="text-m">
                    Kön
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    required
                    value={userData.gender}
                    onChange={(e) => handleInputChange(e)}
                    className="text-m"
                  >
                    <option value="" disabled>
                      Välj kön
                    </option>
                    <option value="man">Man</option>
                    <option value="kvinna">Kvinna</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="membership" className="text-m">
                Medlemskap*
              </label>
              <select
                id="membership"
                name="member_type"
                value={userData.member_type}
                onChange={(e) => handleInputChange(e)}
                className="text-m"
                disabled={userData.member_type === "Kö"}
              >
                <option value="" disabled>
                  Välj medlemskap
                </option>
                {props.permissions.map((permission) => {
                  const memberType = capFirstAndRemoveDash(
                    permission.member_type
                  );
                  if (
                    userData.member_type !== "Kö" &&
                    permission.member_type === "Kö"
                  )
                    return null;

                  return (
                    <option
                      value={permission.member_type}
                      key={permission.permission_id}
                    >
                      {memberType}
                    </option>
                  );
                })}
              </select>
              {props.permissions.map((permission, index) => {
                if (permission.member_type !== userData.member_type) return;
                const ranking = permission.ranking;
                const payment_card = permission.payment_card;
                const title = permission.title;
                const gym_access = permission.gym_access;
                return (
                  <Fragment key={index}>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Gym access</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={gym_access} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Betalkort</span>
                      <label className="toggle text-m">
                        <input
                          type="checkbox"
                          checked={payment_card}
                          readOnly
                        />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                    <div className="general-popup-flex-between">
                      <span className="text-m">Ranking</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={ranking} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>

                    <div className="general-popup-flex-between">
                      <span className="text-m">Titel</span>
                      <label className="toggle text-m">
                        <input type="checkbox" checked={title} readOnly />
                        <span className="slider round">
                          <i className="fa-regular fa-lock" />
                        </span>
                      </label>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {userData.member_type === "Aktiv spelrätt" ||
            userData.member_type === "Företag" ||
            userData.member_type === "Företag B" ||
            userData.member_type === "Hedersledarmot" ||
            userData.member_type === "Elitnål" ? (
              <div className="general-popup-form-field">
                <label htmlFor="title" className="text-m">
                  Titel
                </label>
                <select
                  id="title"
                  name="title"
                  value={userData.title}
                  onChange={(e) => handleInputChange(e)}
                  className="text-m"
                >
                  <option value="">Ingen titel</option>
                  {titleValues.map((title, index) => {
                    return (
                      <option key={index} value={title}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            {userData.member_type === "Aktiv spelrätt" ||
            userData.member_type === "Temporär spelrätt" ||
            userData.member_type === "Företag" ||
            userData.member_type === "Företag B" ||
            userData.member_type === "Hedersledarmot" ||
            userData.member_type === "Elitnål" ? (
              <div className="general-popup-form-field">
                <label htmlFor="ranking" className="text-m">
                  Ranking
                </label>
                <select
                  id="ranking"
                  name="ranking"
                  value={userData.ranking}
                  onChange={(e) => handleInputChange(e)}
                  className="text-m"
                >
                  <option value={-1}>Ingen ranking</option>
                  {rankingValues.map((ranking, index) => {
                    return (
                      <option key={index} value={ranking}>
                        {ranking / 2}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            <div className="general-popup-form-field">
              <div className="text-m">Övrigt - kan redigeras</div>
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Sökbar för medlemmar</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="hidden_info"
                      checked={!userData.hidden_info}
                      onChange={(e) => handleInputChange(e)}
                      className="text-m"
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Massmail</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="massemail"
                      checked={userData.massemail}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          massemail: e.target.checked,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {currentPermission?.gym_access && (
                  <div className="general-popup-form-field-divided-section flex-between">
                    <span className="text-m">Gym</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        name="gym_access"
                        checked={userData.gym_access}
                        onChange={(e) =>
                          setUserData((prevData) => ({
                            ...prevData,
                            gym_access: e.target.checked,
                          }))
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                )}

                <div className="general-popup-form-field-divided-section flex-between">
                  <span className="text-m">Rehab access</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="rehab_access"
                      checked={userData.rehab_access}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          rehab_access: e.target.checked,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="email" className="text-m">
                    {" "}
                    E-postadress*
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Ange E-postadress"
                    value={userData.email}
                    pattern={emailRegex}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {ccEmails.map((email, index) => (
                  <div
                    className="general-popup-form-field-divided-section cc-wrapper"
                    key={index}
                  >
                    <label htmlFor={`emailCC${index}`} className="text-m">
                      E-postadress cc {index !== 0 && index + 1}
                    </label>
                    <input
                      className="on-transparent text-m"
                      type="email"
                      id={`emailCC${index}`}
                      name={`email_cc_${index}`}
                      placeholder="Ange E-postadress"
                      pattern={emailRegex}
                      value={email}
                      onChange={(e) =>
                        handleCcEmailChange(index, e.target.value)
                      }
                    />
                    {index !== 0 && (
                      <img
                        src={remove}
                        className="pointer remove-cc-email"
                        onClick={() => removeCcEmail(index)}
                        alt="Remove cc address"
                      />
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addCcEmail}
                  className="text-m add-cc-button"
                >
                  Lägg till cc e-postadress
                </button>
              </div>
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="company" className="text-m">
                Företag
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="company"
                name="company"
                placeholder="Ange företag"
                value={userData.company}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="comment" className="text-m">
                Kommentar (Vissa företag har företagsnamn här - flytta till
                företags fält)
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="comment"
                name="comment"
                placeholder="Ange företag"
                value={userData.comment}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="adress" className="text-m">
                    Adress
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="adress"
                    name="adress"
                    placeholder="Ange adress"
                    value={userData.adress}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="city" className="text-m">
                    Stad
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Ange stad"
                    value={userData.city}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="zipcode" className="text-m">
                    Postnummer
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="zipcode"
                    name="zip_code"
                    placeholder="Ange postnummer"
                    maxLength={6}
                    value={userData.zip_code}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="country" className="text-m">
                    Land
                  </label>
                  <input
                    className="on-transparent text-m"
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Ange land"
                    value={userData.country}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>

            {userData.member_type === "Kö" && (
              <div className="general-popup-form-field">
                <label htmlFor="membership" className="text-m">
                  Medlemskap för aktivering av medlemskap
                </label>
                <select
                  id="membership"
                  name="member_type"
                  value={activationPermission?.member_type || ""}
                  onChange={(e) =>
                    handleActivationPermissionSelect(e.target.value)
                  }
                  className="text-m"
                >
                  <option value="" disabled>
                    Välj medlemskap
                  </option>
                  {props.permissions.map((permission) => {
                    const memberType = capFirstAndRemoveDash(
                      permission.member_type
                    );
                    if (permission.member_type === "Kö") return null;

                    return (
                      <option
                        value={permission.member_type}
                        key={permission.permission_id}
                      >
                        {memberType}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="deleteButton text-m"
                  type="button"
                  onClick={() => setShowHandleActivateMemeberPopup(true)}
                  disabled={activationPermission === null}
                >
                  Aktivera
                </button>
              </div>
            )}
          </div>

          <div className="general-popup-bottom-buttons-wrapper">
            <button
              className="deleteButton text-m"
              type="button"
              onClick={() => setShowHandleDeleteMemeberPopup(true)}
            >
              Radera medlem permanent
            </button>
            {userData.member_type !== "Kö" && (
              <button
                className="deleteButton text-m"
                type="button"
                onClick={() => setShowHandleDeactivateMemeberPopup(true)}
              >
                Gör till kö-medlem
              </button>
            )}
            <button
              type="submit"
              className="text-m"
              disabled={!hasUserDataChanged}
            >
              Redigera medlem
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteMemeberPopup}
        onClose={() => setShowHandleDeleteMemeberPopup(false)}
        onConfirm={() => handleDeleteUser()}
        questionText={`Är du säker på att du vill radera ${userData.first_name} (${props.userId})?`}
        confirmText="Radera medlem permanent"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showHandleActivateMemeberPopup}
        onClose={() => setShowHandleActivateMemeberPopup(false)}
        onConfirm={() => handleActivateUser()}
        questionText={`Är du säker på att du vill aktivera ${userData.first_name} (${props.userId}) till medlemskap: ${activationPermission?.member_type}?`}
        confirmText="Aktivera medlem"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showHandleDeactivateMemeberPopup}
        onClose={() => setShowHandleDeactivateMemeberPopup(false)}
        onConfirm={() => handleDeactivateUser()}
        questionText={`Är du säker på att du vill göra ${userData.first_name} (${props.userId}) till en kö-medlem?`}
        confirmText="Byt till kö-medlem"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditUserPopup;
