import "./NonAuthLayout.scss";
import "../Layout/Layout.scss";


interface INonAuthLayoutProps {
  children: React.ReactNode;
}

function NonAuthLayout(props: INonAuthLayoutProps) {
  return (
    <div className="app-wrapper non-auth">
      <div className="non-auth-top">
        <div className="dev-banner-wrapper">
        </div>
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
}

export default NonAuthLayout;
