import { useEffect, useState } from "react";
import "./AdminVisualArtPreview.scss";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner/Spinner";
import VisualArtUrl from "../VisualArtUrl/VisualArtUrl";
interface IAdminVisualArtPreviewProps {}

function AdminVisualArtPreview(props: IAdminVisualArtPreviewProps) {
  const {} = props;

  if (false) {
    return <Spinner />;
  }

  return (
    <div className="admin-visual-art-preview-layout">
      <h1 className="text-l primary-text">Integrationer</h1>
      <VisualArtUrl />
    </div>
  );
}

export default AdminVisualArtPreview;
