import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IBooking} from "../../types/api";

export interface ICoachAvailabilityBatch {
  id: string;
  startTime: string;
  endTime: string;
  price: number;
  date: Date;
  booking: IBooking | null;
}

type InitialBatchType = ICoachAvailabilityBatch[];

const useCoachAvailabilityBatches = (
  initialBatches: InitialBatchType
): {
  coachAvailabilityBatches: ICoachAvailabilityBatch[];
  setBatches: (newBatches: InitialBatchType) => void;
  addCoachAvailabilityBatch: () => void;
  removeCoachAvailabilityBatch: (id: string) => void;
  updateStartTime: (id: string, newStartTime: string) => void;
  updateEndTime: (id: string, newEndTime: string) => void;
  updateDate: (id: string, newDate: Date) => void;
  updatePrice: (id: string, newPrice: number) => void;
} => {
     const [coachAvailabilityBatches, setCoachAvailabilityBatches] =
       useState<ICoachAvailabilityBatch[]>(initialBatches);
  const defaultCoachAvailabilityBatch: ICoachAvailabilityBatch = {
    id: uuidv4(),
    startTime: "07:00:00",
    endTime: "23:00:00",
    date: new Date(),
    price: 0,
    booking:null
  };

 const addCoachAvailabilityBatch = () => {
   setCoachAvailabilityBatches((prevBatches) => [
     ...prevBatches,
     {
       ...defaultCoachAvailabilityBatch,
       id: uuidv4(),
       date: new Date(
         prevBatches.length > 0
           ? new Date(prevBatches[prevBatches.length - 1].date).setDate(
               new Date(prevBatches[prevBatches.length - 1].date).getDate() + 1
             )
           : new Date()
       ),
     },
   ]);
 };


  
  const removeCoachAvailabilityBatch = (idToRemove: string) => {
    setCoachAvailabilityBatches((prevBatches) =>
      prevBatches.filter((batch) => batch.id !== idToRemove)
    );
  };

  interface startTimeUpdater {
    (id: string, newStartTime: string): void;
  }
  const updateStartTime: startTimeUpdater = (
    id: string,
    newStartTime: string
  ) => {
    setCoachAvailabilityBatches((prevBatches) =>
      prevBatches.map((batch) =>
        batch.id === id ? { ...batch, startTime: newStartTime } : batch
      )
    );
  };
  interface endTimeUpdater {
    (id: string, newEndTime: string): void;
  }
  const updateEndTime: endTimeUpdater = (id: string, newEndTime: string) => {
    setCoachAvailabilityBatches((prevBatches) =>
      prevBatches.map((batch) =>
        batch.id === id ? { ...batch, endTime: newEndTime } : batch
      )
    );
  };
  interface dateUpdater {
    (id: string, newDate: Date): void;
  }

  const updateDate: dateUpdater = (id: string, newDate: Date) => {
    setCoachAvailabilityBatches((prevBatches) =>
      prevBatches.map((batch) =>
        batch.id === id ? { ...batch, date: newDate } : batch
      )
    );
  }
  interface priceUpdater {
    (id: string, newPrice: number): void;
  }
    const updatePrice: priceUpdater = (id: string, newPrice: number) => {
        setCoachAvailabilityBatches((prevBatches) =>
        prevBatches.map((batch) =>
            batch.id === id ? { ...batch, price: newPrice } : batch
        )
        );
    };
  return {
    coachAvailabilityBatches,
    updatePrice,
    updateDate,
    updateStartTime,
    updateEndTime,
    addCoachAvailabilityBatch,
    removeCoachAvailabilityBatch,
    setBatches: setCoachAvailabilityBatches,
  };
};

export default useCoachAvailabilityBatches;
