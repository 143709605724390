import { IOutsideSettingsDailyInput } from "../../types/api";

export const getOutsideSettingsDailyQueryFn = (date: string) => {
   
    const dateString = date.slice(0, 10);
    const query = `
        getOutsideSettingsDaily(date: $date) {
        ... on OutsideSettingsDaily {
            opening_hours
            closing_hours
            date
            maintance {
                end_time
                frequency
                start_time
            }
        }
        ... on Error {
            code
            message
        }
    }
    `;
    const inputs = `$date: String!`;
    const variables = { date: dateString };
    return {
        query,
        inputs,
        variables,
    };
}

export const createOutsideSettingsDailyQueryFn = (outsideSettingsDaily: IOutsideSettingsDailyInput) => {
    const query = `
        createOutsideSettingsDaily(outsideSettingsDaily: $outsideSettingsDaily) {
        ... on OutsideSettingsDaily {
            outside_id
            opening_hours
            closing_hours
            date
            maintance {
                end_time
                frequency
                start_time
            }
        }
        ... on Error {
            code
            message
        }
    }
    `;
    const inputs = `$outsideSettingsDaily: OutsideSettingsDailyInput!`;
    const variables = { outsideSettingsDaily };
    return {
        query,
        inputs,
        variables,
    };
}
