import "./MyProfilePopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IPermission, IUser } from "../../../types/api";
import { toast } from "react-toastify";
import { CognitoUser } from "../../../types/cognito";
import { getPermissionByPermissionId } from "../../../api/permissions/permissions";
import { getUserByMemberId } from "../../../api/users/users";
import greenCheck from "../../../assets/images/green-check.svg";
import cross from "../../../assets/images/cross.svg";
import {
  capitalizeWords,
  getWeekdaySwedishName,
} from "../../../shared/utility";
import { initiatePasswordReset } from "../../../api/auth/auth";
import { useNavigate } from "react-router-dom";

interface IMyProfilePopupProps {
  showPopup: boolean;
  onClose: () => void;
  user: CognitoUser | null;
  onSignOut: () => void;
}

function MyProfilePopup(props: IMyProfilePopupProps) {
  const user = props.user;
  const navigate = useNavigate();
  const [permissionData, setPermissionData] = useState<IPermission>();
  const [userData, setUserData] = useState<IUser>();
  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchMemberTypeData = async () => {
      try {
        const premission = await getPermissionByPermissionId(
          user.attributes["custom:role"]
        );
        setPermissionData(premission);
      } catch (error) {
        toast.error("Error fetching permission data");
      }
    };
    const fetchUserData = async () => {
      try {
        const userData = await getUserByMemberId(
          user.attributes["custom:member_id"]
        );
        setUserData(userData);
      } catch (error) {
        toast.error("Error fetching user data");
      }
    };
    fetchUserData();
    fetchMemberTypeData();
  }, [user]);

  const initiatePasswordChange = async () => {
    if (!user?.username) {
      return;
    }
    try {
      const response = await initiatePasswordReset(user.username);
      if (response) {
        props.onClose();
        props.onSignOut();
        navigate("/password-change", { state: { email: user.username } });
      }
    } catch (error: any) {
      toast.error("Error changing password" + error);
    }
  };
  const handleClose = () => {
    props.onClose();
  };

  if (!user || !permissionData || !userData) return null;
  const firstName = capitalizeWords(userData.first_name);
  const lastName = capitalizeWords(userData.last_name);

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          {user.attributes.name} ({user.attributes["custom:member_id"]})
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-group-label">
            <span className="text-s secondary-text">Kontouppgifter</span>
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Förnamn</span>
                <span className="text-m">{firstName}</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Efternamn</span>
                <span className="text-m">{lastName}</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">E-postadress</span>
                <div className="general-popup-gap-10">
                  <span className="text-m">{userData.email}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Lösenord</span>
                <a
                  href="#"
                  className="text-s"
                  onClick={() => initiatePasswordChange()}
                >
                  Ändra
                </a>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group-label">
            <span className="text-s secondary-text">Medlemskap</span>
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Medlemsnummer</span>
                <span className="text-m">
                  {user.attributes["custom:member_id"]}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Medlemskap</span>
                <span className="text-m">{userData?.member_type}</span>
              </div>

              <div className="general-popup-flex-between">
                <span className="text-m">Ranking</span>
                <span className="text-m">
                  {permissionData.ranking !== null ? (
                    userData.ranking !== -1 && (
                      <span>{userData.ranking / 2} </span>
                    )
                  ) : (
                    <img src={cross} alt="cross" />
                  )}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">No show</span>
                <span className="text-m">
                  <span>{userData.noshow_count}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group-label">
            <span className="text-s secondary-text">Kontakt</span>
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <div className="text-m"> E-post kansli:</div>
                <a href="mailto:kansli@kltk.se" className="text-s">
                  kansli@kltk.se
                </a>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Telefon kansli</span>
                <a href="tel:08-4591515" className="text-s">
                  08-459 15 15
                </a>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Telefon reception</span>
                <a href="tel:08-4591500" className="text-s">
                  08-459 15 00
                </a>
              </div>

              <div className="general-popup-flex-between">
                <span className="text-m">Länk till hemsidan</span>
                <a
                  className="text-s"
                  target="_blank"
                  href="https://www.kltk.se"
                >
                  kltk.se
                </a>
              </div>
            </div>
          </div>
          <div className="general-popup-flex-end text-m">
            <a onClick={props.onSignOut}>Logga ut</a>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default MyProfilePopup;
