import {
  IActivity,
  IAdmin,
  IBooking,
  ICoPlayerBooking,
  ICoach,
  ICourt,
  ICourtType,
  IDailyComment,
  IDisplayUserResponse,
  IFacility,
  IGraphError,
  IGuestResponse,
  IOutsideSettingsDaily,
  IPermission,
  IPrices,
  IQueueItem,
  IRehabBooking,
  IRehabSummary,
  ISummary,
  IUser,
} from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  getUserByMemberIdQueryFn,
  listUsersQueryFn,
} from "../users/userQueries";
import {
  getSlotsByMemberQueryFn,
  getSlotsQueryFn,
} from "../summaries/summaryQueries";
import { listPricesQueryFn } from "../prices/pricesQueries";
import {
  getActiveBookingsAsCoplayerQueryFn,
  getActiveBookingsByMemberQueryFn,
  getBookingsByMemberQueryFn,
} from "../bookings/bookingsQueries";
import { listCourtsQueryFn } from "../courts/courtsQueries";
import { getLatestCoPlayersQueryFn } from "../latestCoPlayers/latestCoPlayersQueries";
import {
  getPermissionByMemberTypeQueryFn,
  listPermissionsQueryFn,
} from "../permissions/permissionsQueries";
import { listCoachesQueryFn } from "../coaches/coachesQueries";
import { getFacilityOpeningTimesQueryFn } from "../facility/facilityOpeningTimesQueries";
import { listAdminsQueryFn } from "../admin/adminQueries";
import { getOutsideSettingsDailyQueryFn } from "../outsideSettings/outsideSettingsQueries";
import { listGuestsQueryFn } from "../guests/guestQueries";
import { getActiveQueueSpotsByMemberQueryFn } from "../queues/queueQueries";
import {
  getActiveActivitiesByMemberQueryFn,
  listActivitiesQueryFn,
  listUppcommingActivitiesQueryFn,
} from "../activities/activityQueries";
import {
  getActiveRehabBookingsByMemberQueryFn,
  getRehabSlotsByAdminQueryFn,
  getRehabSlotsQueryFn,
} from "../rehab/rehabQueries";
import { getDailyCommentQueryFn } from "../dailyComment/dailyCommentQueries";
import { listQueueUsersQueryFn } from "../queueUser/queueUserQueries";

type UserResponse = IDisplayUserResponse | IGraphError;
type QueueUserResponse = IDisplayUserResponse | IGraphError;
type PricesResponse = IPrices[] | IGraphError;
type ActiveBookingsResponse = IBooking[] | IGraphError;
type CourtsResponse = ICourt[] | IGraphError;
type SingleUserResponse = IUser | IGraphError;
type LatestCoPlayersResponse = IUser[] | IGraphError;
type MemberTypeResponse = IPermission | IGraphError;
type PermissionsResponse = IPermission[] | IGraphError;
type CoachesResponse = ICoach[] | IGraphError;
type FacilityResponse = IFacility | IGraphError;
type AdminsResponse = IAdmin[] | IGraphError;
type OutsideSettingsDailyResponse = IOutsideSettingsDaily | IGraphError;
type GuestsResponse = IGuestResponse | IGraphError;
type SlotsResponse = ISummary[] | IGraphError;
type QueueSpotsResponse = IQueueItem[] | IGraphError;
type ActivitiesResponse = IActivity[] | IGraphError;
type RehabSlotsResponse = IRehabSummary | IGraphError;
type ActiveActivitiesResponse = IActivity[] | IGraphError;
type BookingsByMemberResponse = IBooking[] | IGraphError;
type ActiveRehabBookingsByMemberResponse = IRehabBooking[] | IGraphError;
type ActiveBookingsCoPlayerResponse = ICoPlayerBooking[] | IGraphError;
type DailyCommentResponse = IDailyComment | IGraphError;

interface FetchDataResult {
  users?: UserResponse;
  queueUsers?: QueueUserResponse;
  slotsByMember?: SlotsResponse;
  prices?: PricesResponse;
  activeBookings?: ActiveBookingsResponse;
  courts?: CourtsResponse;
  userData?: SingleUserResponse;
  latestCoPlayers?: LatestCoPlayersResponse;
  memberType?: MemberTypeResponse;
  permissions?: PermissionsResponse;
  coaches?: CoachesResponse;
  facility?: FacilityResponse;
  admins?: AdminsResponse;
  outsideSettingsDaily?: OutsideSettingsDailyResponse;
  guests?: GuestsResponse;
  slots?: SlotsResponse;
  queueSpots?: QueueSpotsResponse;
  upcomingActivities?: ActivitiesResponse;
  activities?: ActivitiesResponse;
  rehabSlots?: RehabSlotsResponse;
  activeActivities?: ActiveActivitiesResponse;
  bookingsByMember?: BookingsByMemberResponse;
  activeRehabBookings?: ActiveRehabBookingsByMemberResponse;
  activeBookingsCoPlayer?: ActiveBookingsCoPlayerResponse;
  dailyComment?: DailyCommentResponse;
}

interface CombinedQueryParams {
  date?: string;
  limit?: number;
  start_key?: string;
  member_id?: string;
  requestor: "admin" | "user";
  active?: boolean;
  court_type?: ICourtType;
  permission_id?: string;
  fetchUsers?: boolean;
  fetchQueueUsers?: boolean;
  fetchSlotsByMember?: boolean;
  fetchPrices?: boolean;
  fetchActiveBookingsByMemberId?: boolean;
  fetchCourts?: boolean;
  fetchUserData?: boolean;
  fetchLatestCoPlayers?: boolean;
  fetchMemberType?: boolean;
  fetchPermissions?: boolean;
  fetchCoaches?: boolean;
  fetchFacility?: boolean;
  fetchAdmins?: boolean;
  fetchOutsideSettingsDaily?: boolean;
  fetchGuests?: boolean;
  fetchSlots?: boolean;
  fetchQueueSpots?: boolean;
  fetchUpcomingActivities?: boolean;
  fetchActivities?: boolean;
  fetchRehabSlots?: boolean;
  fetchActiveActivities?: boolean;
  fetchBookingsByMember?: boolean;
  fetchActiveRehabBookingsByMember?: boolean;
  fetchActiveBookingsCoPlayer?: boolean;
  fetchRehabSlotsAdmin?: boolean;
  fetchDailyComment?: boolean;
}

export const combinedQuery = async ({
  date,
  member_id,
  permission_id,
  limit,
  start_key,
  court_type,
  active,
  requestor,
  fetchFacility = false,
  fetchUsers = false,
  fetchQueueUsers = false,
  fetchSlotsByMember = false,
  fetchPrices = false,
  fetchActiveBookingsByMemberId = false,
  fetchCourts = false,
  fetchUserData = false,
  fetchLatestCoPlayers = false,
  fetchMemberType = false,
  fetchPermissions = false,
  fetchCoaches = false,
  fetchAdmins = false,
  fetchOutsideSettingsDaily = false,
  fetchGuests = false,
  fetchSlots = false,
  fetchQueueSpots = false,
  fetchUpcomingActivities = false,
  fetchActivities = false,
  fetchRehabSlots = false,
  fetchActiveActivities = false,
  fetchBookingsByMember = false,
  fetchActiveRehabBookingsByMember = false,
  fetchActiveBookingsCoPlayer = false,
  fetchRehabSlotsAdmin = false,
  fetchDailyComment = false,
}: CombinedQueryParams): Promise<FetchDataResult> => {
  let queryParts = [];
  let queryVariables = {};
  let variableDefinitions = [];

  if (fetchActivities) {
    const { query } = listActivitiesQueryFn();
    queryParts.push(query);
  }
  if (fetchBookingsByMember && member_id) {
    const { query, inputs, variables } = getBookingsByMemberQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchActiveRehabBookingsByMember && member_id && active !== undefined) {
    const { query, inputs, variables } = getActiveRehabBookingsByMemberQueryFn(
      member_id,
      active
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchActiveActivities && member_id && active !== undefined) {
    const { query, inputs, variables } = getActiveActivitiesByMemberQueryFn(
      member_id,
      active
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchRehabSlots && date && member_id) {
    const { query, inputs, variables } = getRehabSlotsQueryFn(date, member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchDailyComment && date) {
    const { query, inputs, variables } = getDailyCommentQueryFn(date);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchRehabSlotsAdmin && date) {
    const { query, inputs, variables } = getRehabSlotsByAdminQueryFn(date);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchUpcomingActivities) {
    const { query } = listUppcommingActivitiesQueryFn();
    queryParts.push(query);
  }
  if (fetchQueueSpots && member_id) {
    const { query, inputs, variables } =
      getActiveQueueSpotsByMemberQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchFacility) {
    const { query } = getFacilityOpeningTimesQueryFn();
    queryParts.push(query);
  }
  if (fetchSlots && date && court_type) {
    const { query, inputs, variables } = getSlotsQueryFn(date, court_type);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchGuests && limit && start_key) {
    const { query, inputs, variables } = listGuestsQueryFn(limit, start_key);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchOutsideSettingsDaily && date) {
    const { query, inputs, variables } = getOutsideSettingsDailyQueryFn(date);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchAdmins) {
    const { query } = listAdminsQueryFn();
    queryParts.push(query);
  }
  if (fetchPermissions) {
    const { query } = listPermissionsQueryFn();
    queryParts.push(query);
  }
  if (fetchCoaches) {
    const { query } = listCoachesQueryFn(requestor);
    queryParts.push(query);
  }
  if (fetchSlotsByMember && date && member_id && court_type) {
    const { query, inputs, variables } = getSlotsByMemberQueryFn(
      date,
      member_id,
      court_type
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchActiveBookingsCoPlayer && member_id) {
    const { query, inputs, variables } =
      getActiveBookingsAsCoplayerQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchMemberType && permission_id) {
    const { query, inputs, variables } =
      getPermissionByMemberTypeQueryFn(permission_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }

  if (fetchLatestCoPlayers && member_id) {
    const { query, inputs, variables } = getLatestCoPlayersQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchCourts) {
    const { query } = listCourtsQueryFn();
    queryParts.push(query);
  }
  if (fetchActiveBookingsByMemberId && member_id) {
    const { query, inputs, variables } =
      getActiveBookingsByMemberQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchUsers && limit && start_key) {
    const { query, inputs, variables } = listUsersQueryFn(
      requestor,
      limit,
      start_key,
      permission_id
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchQueueUsers && limit && start_key) {
    const { query, inputs, variables } = listQueueUsersQueryFn(
      limit,
      start_key
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchPrices) {
    const { query } = listPricesQueryFn();
    queryParts.push(query);
  }

  if (fetchUserData && member_id) {
    const { query, inputs, variables } = getUserByMemberIdQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  let variablesString = variableDefinitions.join(", ");
  let wrappedVariables =
    variableDefinitions.length > 0 ? `(${variablesString})` : "";
  let combinedQuery = `query CombinedQuery${wrappedVariables} {
    ${queryParts.join("\n")}
  }`;

  try {
    await Auth.currentSession();
    const getAllResponse = (await API.graphql({
      query: combinedQuery,
      variables: queryVariables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const result: FetchDataResult = {};
    if (fetchOutsideSettingsDaily) {
      const outsideSettingsDailyResponse =
        getAllResponse.data.getOutsideSettingsDaily;
      if ("message" in outsideSettingsDailyResponse) {
        toast.error(outsideSettingsDailyResponse.message);
        result.outsideSettingsDaily =
          outsideSettingsDailyResponse as IGraphError;
      } else {
        result.outsideSettingsDaily =
          outsideSettingsDailyResponse as IOutsideSettingsDaily;
      }
    }
    if (fetchActiveActivities && member_id && active !== undefined) {
      const activeActivitiesResponse =
        getAllResponse.data.getActiveActivitiesByMember;
      if ("message" in activeActivitiesResponse) {
        toast.error(activeActivitiesResponse.message);
        result.activeActivities = activeActivitiesResponse as IGraphError;
      } else {
        result.activeActivities = activeActivitiesResponse as IActivity[];
      }
    }
    if (fetchActiveRehabBookingsByMember) {
      const activeRehabBookingsResponse =
        getAllResponse.data.getActiveRehabBookingsByMember;
      if ("message" in activeRehabBookingsResponse) {
        toast.error(activeRehabBookingsResponse.message);
        result.activeRehabBookings = activeRehabBookingsResponse as IGraphError;
      } else {
        result.activeRehabBookings =
          activeRehabBookingsResponse as IRehabBooking[];
      }
    }
    if (fetchActivities) {
      const activitiesResponse = getAllResponse.data.listActivities;
      if ("message" in activitiesResponse) {
        toast.error(activitiesResponse.message);
        result.activities = activitiesResponse as IGraphError;
      } else {
        result.activities = activitiesResponse as IActivity[];
      }
    }
    if (fetchRehabSlots) {
      const rehabSlotsResponse = getAllResponse.data.getRehabSlots;
      if ("message" in rehabSlotsResponse) {
        toast.error(rehabSlotsResponse.message);
        result.rehabSlots = rehabSlotsResponse as IGraphError;
      } else {
        result.rehabSlots = rehabSlotsResponse as IRehabSummary;
      }
    }
    if (fetchRehabSlotsAdmin) {
      const rehabSlotsResponseAdmin = getAllResponse.data.getRehabSlotsAdmin;
      if ("message" in rehabSlotsResponseAdmin) {
        toast.error(rehabSlotsResponseAdmin.message);
        result.rehabSlots = rehabSlotsResponseAdmin as IGraphError;
      } else {
        result.rehabSlots = rehabSlotsResponseAdmin as IRehabSummary;
      }
    }
    if (fetchActiveBookingsCoPlayer) {
      const activeBookingsCoPlayerResponse =
        getAllResponse.data.getActiveBookingsAsCoPlayer;
      if ("message" in activeBookingsCoPlayerResponse) {
        toast.error(activeBookingsCoPlayerResponse.message);
        result.activeBookingsCoPlayer =
          activeBookingsCoPlayerResponse as IGraphError;
      } else {
        result.activeBookingsCoPlayer =
          activeBookingsCoPlayerResponse as ICoPlayerBooking[];
      }
    }
    if (fetchBookingsByMember) {
      const bookingsByMemberResponse = getAllResponse.data.getBookingsByMember;
      if ("message" in bookingsByMemberResponse) {
        toast.error(bookingsByMemberResponse.message);
        result.bookingsByMember = bookingsByMemberResponse as IGraphError;
      } else {
        result.bookingsByMember = bookingsByMemberResponse as IBooking[];
      }
    }
    if (fetchUpcomingActivities) {
      const activitiesResponse = getAllResponse.data.listUpcomingActivities;
      if ("message" in activitiesResponse) {
        toast.error(activitiesResponse.message);
        result.upcomingActivities = activitiesResponse as IGraphError;
      } else {
        result.upcomingActivities = activitiesResponse as IActivity[];
      }
    }
    if (fetchDailyComment) {
      const dailyCommentResponse = getAllResponse.data.getDailyComment;
      if ("message" in dailyCommentResponse) {
        toast.error(dailyCommentResponse.message);
        result.dailyComment = dailyCommentResponse as IGraphError;
      } else {
        result.dailyComment = dailyCommentResponse as IDailyComment;
      }
    }
    if (fetchQueueSpots) {
      const queueSpotsResponse =
        getAllResponse.data.getActiveQueueSpotsByMember;
      if ("message" in queueSpotsResponse) {
        toast.error(queueSpotsResponse.message);
        result.queueSpots = queueSpotsResponse as IGraphError;
      } else {
        result.queueSpots = queueSpotsResponse as IQueueItem[];
      }
    }
    if (fetchGuests) {
      const guestsResponse = getAllResponse.data.listGuests;
      if ("message" in guestsResponse) {
        toast.error(guestsResponse.message);
        result.guests = guestsResponse as IGraphError;
      } else {
        result.guests = guestsResponse as IGuestResponse;
      }
    }
    if (fetchSlots) {
      const slotsResponse = getAllResponse.data.getSlots;
      if ("message" in slotsResponse) {
        toast.error(slotsResponse.message);
        result.slots = slotsResponse as IGraphError;
      } else {
        result.slots = slotsResponse as ISummary[];
      }
    }
    if (fetchAdmins) {
      const adminsResponse = getAllResponse.data.listAdmins;
      if ("message" in adminsResponse) {
        toast.error(adminsResponse.message);
        result.admins = adminsResponse as IGraphError;
      } else {
        result.admins = adminsResponse as IAdmin[];
      }
    }
    if (fetchFacility) {
      const facilityResponse = getAllResponse.data.getFacility;
      if ("message" in facilityResponse) {
        toast.error(facilityResponse.message);
        result.facility = facilityResponse as IGraphError;
      } else {
        result.facility = facilityResponse as IFacility;
      }
    }
    if (fetchSlotsByMember) {
      const indoorResponse = getAllResponse.data.getSlotsByMember;
      if ("message" in indoorResponse) {
        toast.error(indoorResponse.message);
        result.slotsByMember = indoorResponse as IGraphError;
      } else {
        result.slotsByMember = indoorResponse as ISummary[];
      }
    }
    if (fetchCoaches) {
      const coachesResponse = getAllResponse.data.listCoaches;
      if ("message" in coachesResponse) {
        toast.error(coachesResponse.message);
        result.coaches = coachesResponse as IGraphError;
      } else {
        result.coaches = coachesResponse as ICoach[];
      }
    }
    if (fetchPermissions) {
      const permissionsResponse = getAllResponse.data.listPermissions;
      if ("message" in permissionsResponse) {
        toast.error(permissionsResponse.message);
        result.permissions = permissionsResponse as IGraphError;
      } else {
        result.permissions = permissionsResponse as IPermission[];
      }
    }
    if (fetchMemberType && permission_id) {
      const memberTypeResponse = getAllResponse.data.getPermissionByMemberType;
      if ("message" in memberTypeResponse) {
        toast.error(memberTypeResponse.message);
        result.memberType = memberTypeResponse as IGraphError;
      } else {
        result.memberType = memberTypeResponse as IPermission;
      }
    }
    if (fetchLatestCoPlayers) {
      const latestCoPlayersResponse = getAllResponse.data.getLatestCoPlayers;
      if ("message" in latestCoPlayersResponse) {
        toast.error(latestCoPlayersResponse.message);
        result.latestCoPlayers = latestCoPlayersResponse as IGraphError;
      } else {
        result.latestCoPlayers = latestCoPlayersResponse as IUser[];
      }
    }
    if (fetchUsers) {
      const userResponse = getAllResponse.data.listUsers;
      if ("message" in userResponse) {
        toast.error(userResponse.message);
        result.users = userResponse as IGraphError;
      } else {
        result.users = userResponse as IDisplayUserResponse;
      }
    }
    if (fetchQueueUsers) {
      const queueUserResponse = getAllResponse.data.listQueueUsers;
      if ("message" in queueUserResponse) {
        toast.error(queueUserResponse.message);
        result.queueUsers = queueUserResponse as IGraphError;
      } else {
        result.queueUsers = queueUserResponse as IDisplayUserResponse;
      }
    }
    if (fetchPrices) {
      const pricesResponse = getAllResponse.data.listPrices;
      if ("message" in pricesResponse) {
        toast.error(pricesResponse.message);
        result.prices = pricesResponse as IGraphError;
      } else {
        result.prices = pricesResponse as IPrices[];
      }
    }
    if (fetchActiveBookingsByMemberId) {
      const activeBookingsResponse =
        getAllResponse.data.getActiveBookingsByMember;
      if ("message" in activeBookingsResponse) {
        toast.error(activeBookingsResponse.message);
        result.activeBookings = activeBookingsResponse as IGraphError;
      } else {
        result.activeBookings = activeBookingsResponse as IBooking[];
      }
    }
    if (fetchCourts) {
      const courtsResponse = getAllResponse.data.listCourts;
      if ("message" in courtsResponse) {
        toast.error(courtsResponse.message);
        result.courts = courtsResponse as IGraphError;
      } else {
        result.courts = courtsResponse as ICourt[];
      }
    }
    if (fetchUserData) {
      const userDataResponse = getAllResponse.data.getUserByID;
      if ("message" in userDataResponse) {
        toast.error(userDataResponse.message);
        result.userData = userDataResponse as IGraphError;
      } else {
        result.userData = userDataResponse as IUser;
      }
    }

    return result;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
