import "./CreatePermissionPopup.scss";
import { useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICourt, IPermissionInput, IPrices } from "../../../types/api";
import { toast } from "react-toastify";

import TimeInputBatch from "../../../hoc/TimeBatch/TimeBatch";
import { v4 as uuidv4 } from "uuid";
import useTimeBatches from "../../../shared/hooks/useTimeBatches";
import { getSwedishAdjectiveColor } from "../../../shared/utility";
import { createPermission } from "../../../api/permissions/permissions";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";

interface ICreatePermissionPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  courts: ICourt[];
  prices: IPrices[];
}

interface SelectedCourts {
  [key: string]: boolean;
}

function CreatePermissionPopup(props: ICreatePermissionPopupProps) {
  const handleClose = () => {
    setPermissionData(initialPermissionData);
    setFeatures({
      gym: false,
      paymentCard: false,
      ranking: false,
      title: false,
      searchable: false,
    });
    setClassOption("all");
    setSelectedCourts({});
    setInsideIntervals(initialInsideBatches);
    setOutsideIntervals(initialOutsideBatches);
    insideExceptionIntervals.forEach((batch) =>
      removeInsideExceptionBatch(batch.id)
    );
    outsideExceptionIntervals.forEach((batch) =>
      removeOutsideExceptionBatch(batch.id)
    );
    setShowConfirmationPopup(false);
    props.onClose();
  };
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const initialPermissionData = {
    name: "",
    dayBeforeTime: "17:00:00",
    maxBookings: 0,
    discount: 0,
    classes: [],
    permission_id: "",
  };
  const [classOption, setClassOption] = useState("all");
  const [selectedCourts, setSelectedCourts] = useState<SelectedCourts>({});
  const maxBookingsOptions = [100, ...Array.from({ length: 11 }, (_, i) => i)];
  const discountOptions = [0, 25, 50, 75, 100];
  const [permissionData, setPermissionData] = useState(initialPermissionData);
 
  const initialInsideBatches = [
    {
      id: uuidv4(),
      startTime: "07:00:00",
      endTime: "23:00:00",
      checkedDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      defaultChecked: true,
      isException: false,
    },
  ];

  const initialOutsideBatches = [
    {
      id: uuidv4(),
      startTime: "07:00:00",
      endTime: "23:00:00",
      checkedDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      defaultChecked: true,
      isException: false,
    },
  ];

  const {
    timeBatches: insideIntervals,
    addTimeBatch: addNewInsideBatch,
    removeTimeBatch: removeInsideBatch,
    updateTimeBatch: updateInsideBatch,
    setBatches: setInsideIntervals,
    updateStartTime: updateInsideStartTime,
    updateEndTime: updateInsideEndTime,
    updateCheckedDays: updateInsideCheckedDays,
  } = useTimeBatches(initialInsideBatches);

  const {
    timeBatches: outsideIntervals,
    addTimeBatch: addNewOutsideBatch,
    removeTimeBatch: removeOutsideBatch,
    setBatches: setOutsideIntervals,
    updateTimeBatch: updateOutsideBatch,
    updateStartTime: updateOutsideStartTime,
    updateEndTime: updateOutsideEndTime,
    updateCheckedDays: updateOutsideCheckedDays,
  } = useTimeBatches(initialOutsideBatches);

  const {
    timeBatches: insideExceptionIntervals,
    addTimeBatch: addNewInsideExceptionBatch,
    removeTimeBatch: removeInsideExceptionBatch,
    updateTimeBatch: updateInsideExceptionBatch,
    updateStartTime: updateInsideExceptionStartTime,
    updateEndTime: updateInsideExceptionEndTime,
    updateCheckedDays: updateInsideExceptionCheckedDays,
    updateIsException: updateInsideIsException,
    updateExceptionStartDate: updateInsideExceptionStartDate,
    updateExceptionEndDate: updateInsideExceptionEndDate,
  } = useTimeBatches([]);

  const {
    timeBatches: outsideExceptionIntervals,
    addTimeBatch: addNewOutsideExceptionBatch,
    removeTimeBatch: removeOutsideExceptionBatch,
    updateTimeBatch: updateOutsideExceptionBatch,
    updateStartTime: updateOutsideExceptionStartTime,
    updateEndTime: updateOutsideExceptionEndTime,
    updateCheckedDays: updateOutsideExceptionCheckedDays,
    updateIsException: updateOutsideIsException,
    updateExceptionStartDate: updateOutsideExceptionStartDate,
    updateExceptionEndDate: updateOutsideExceptionEndDate,
  } = useTimeBatches([]);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setPermissionData({
      ...permissionData,
      [name]: value,
    });
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeValue = e.target.value;
    setPermissionData({
      ...permissionData,
      dayBeforeTime: timeValue,
    });

  };

  const handleTimeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }
    setPermissionData({
      ...permissionData,
      dayBeforeTime: time,
    });
  };


  const [features, setFeatures] = useState({
    gym: false,
    paymentCard: false,
    ranking: false,
    title: false,
    searchable: false,
  });

  function handleCheckboxChange(event: any) {
    const { name, checked } = event.target;
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [name]: checked,
    }));
  }

  const handleCreatePermission = async () => {
    try {
      const indoorAccessList = weekdays
        .map((weekday) => {
          const intervalsForDay = insideIntervals.filter(
            (interval) => interval.checkedDays[weekday]
          );
          return intervalsForDay.map((interval) => ({
            weekday: weekday,
            start_time: interval.startTime,
            end_time: interval.endTime,
          }));
        })
        .flat(); 

      const outdoorAccessList = weekdays
        .map((weekday) => {
          const intervalsForDay = outsideIntervals.filter(
            (interval) => interval.checkedDays[weekday]
          );
          return intervalsForDay.map((interval) => ({
            weekday: weekday,
            start_time: interval.startTime,
            end_time: interval.endTime,
          }));
        })
        .flat();

      let indoorExceptionAccessList: any = [];
      let outdoorExceptionAccessList: any = [];
      const allCourtsAccess = props.courts.map((court) => court.court_id);
      const permission: IPermissionInput = {
        permission_id: permissionData.permission_id,
        member_type: permissionData.name,
        extra_booking_time: permissionData.dayBeforeTime,
        max_amount_of_bookings: Number(permissionData.maxBookings),
        percent_discount_outside_access: permissionData.discount,
        gym_access: features.gym,
        payment_card: features.paymentCard,
        ranking: features.ranking,
        title: features.title,
        hidden: features.searchable,
        lesson_access:
          classOption === "all" ? allCourtsAccess : Object.keys(selectedCourts),
        indoor_access: indoorAccessList,
        outdoor_access: outdoorAccessList,
        exception_indoor_access: indoorExceptionAccessList,
        exception_outdoor_access: outdoorExceptionAccessList,
      };

      const response = await createPermission(permission);
      if (!response) {
        throw new Error("Error creating permission");
      }
      handleClose();
      props.onConfirm();
    } catch (error) {
      console.error("Error creating permission:", error);
      toast.error(`Error creating permission: ${error}`);
      return false;
    }
  };

  const toggleCourtSelection = (courtId: string) => {
    setSelectedCourts((prev) => ({
      ...prev,
      [courtId]: !prev[courtId],
    }));
  };

  if (props.showPopup === false) return null;
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={() => setShowConfirmationPopup(true)}
      leftTopElement={<span className="text-m">Lägg till medlemskap</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreatePermission();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="name" className="text-s">
                Namn på medlemskap
              </label>
              <input
                type="text"
                className="text-m"
                name="name"
                value={permissionData.name}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="id" className="text-s">
                Medlemskaps id
              </label>
              <input
                type="text"
                className="text-m"
                id="id"
                name="permission_id"
                value={permissionData.permission_id}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid inomhus
              </label>
              {insideIntervals.map((batch, index) => (
                <TimeInputBatch
                  id={batch.id}
                  key={batch.id}
                  startTime={batch.startTime || "07:00"}
                  endTime={batch.endTime || "23:00"}
                  checkedDays={batch.checkedDays}
                  defaultChecked={batch.defaultChecked}
                  isLast={index === insideIntervals.length - 1}
                  isFirst={index === 0}
                  isException={false}
                  onAdd={() => addNewInsideBatch(true)}
                  onRemove={() => removeInsideBatch(batch.id)}
                  onStartTimeChange={(newStartTime) =>
                    updateInsideStartTime(batch.id, newStartTime)
                  }
                  onEndTimeChange={(newEndTime) =>
                    updateInsideEndTime(batch.id, newEndTime)
                  }
                  onDayChange={(dayString) => {
                    updateInsideCheckedDays(batch.id, dayString);
                  }}
                />
              ))}
              {insideExceptionIntervals.length === 0 ? (
                <div className="add-time-batch-button-wrapper">
                  <a
                    className="add-time-batch-button"
                    onClick={() => addNewInsideExceptionBatch(true)}
                  >
                    <span className="text-s">Lägg till undantag</span>
                  </a>
                </div>
              ) : (
                <div className="time-batch-looper">
                  <span className="text-s">
                    Undantag (kommer skriva över grundinställningar)
                  </span>
                  {insideExceptionIntervals.map((batch, index) => (
                    <TimeInputBatch
                      id={batch.id}
                      key={batch.id}
                      startTime={batch.startTime}
                      endTime={batch.endTime}
                      checkedDays={batch.checkedDays}
                      defaultChecked={batch.defaultChecked}
                      isLast={index === insideExceptionIntervals.length - 1}
                      isFirst={false}
                      isException={true}
                      exceptionStartDate={batch.exceptionStartDate}
                      exceptionEndDate={batch.exceptionEndDate}
                      onAdd={() =>
                        addNewInsideExceptionBatch(
                          true,
                          true,
                          new Date(),
                          new Date()
                        )
                      }
                      onRemove={() => removeInsideExceptionBatch(batch.id)}
                      onStartTimeChange={(newStartTime) =>
                        updateInsideExceptionStartTime(batch.id, newStartTime)
                      }
                      onEndTimeChange={(newEndTime) =>
                        updateInsideExceptionEndTime(batch.id, newEndTime)
                      }
                      onExceptionEndDateChange={(newEndDate) =>
                        updateInsideExceptionEndDate(batch.id, newEndDate)
                      }
                      onExceptionStartDateChange={(newStartDate) =>
                        updateInsideExceptionStartDate(batch.id, newStartDate)
                      }
                      onDayChange={(dayString) => {
                        updateInsideExceptionCheckedDays(batch.id, dayString);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid utomhus
              </label>
              {outsideIntervals.map((batch, index) => (
                <TimeInputBatch
                  id={batch.id}
                  key={batch.id}
                  startTime={batch.startTime || "07:00:00"}
                  endTime={batch.endTime || "23:00:00"}
                  checkedDays={batch.checkedDays}
                  defaultChecked={batch.defaultChecked}
                  isLast={index === outsideIntervals.length - 1}
                  isFirst={index === 0}
                  isException={batch.isException}
                  onAdd={() => addNewOutsideBatch(true)}
                  onRemove={() => removeOutsideBatch(batch.id)}
                  onStartTimeChange={(newStartTime) =>
                    updateOutsideStartTime(batch.id, newStartTime)
                  }
                  onEndTimeChange={(newEndTime) =>
                    updateOutsideEndTime(batch.id, newEndTime)
                  }
                  onDayChange={(dayString) => {
                    updateOutsideCheckedDays(batch.id, dayString);
                  }}
                />
              ))}
              {outsideExceptionIntervals.length === 0 ? (
                <div className="add-time-batch-button-wrapper">
                  <a
                    className="add-time-batch-button"
                    onClick={() => addNewOutsideExceptionBatch(false)}
                  >
                    <span className="text-s">Lägg till undantag</span>
                  </a>
                </div>
              ) : (
                <div className="time-batch-looper">
                  <span className="text-s">
                    Undantag (kommer skriva över grundinställningar)
                  </span>
                  {outsideExceptionIntervals.map((batch, index) => {
                    return (
                      <TimeInputBatch
                        id={batch.id}
                        key={batch.id}
                        startTime={batch.startTime || "07:00:00"}
                        endTime={batch.endTime || "23:00:00"}
                        checkedDays={batch.checkedDays}
                        defaultChecked={batch.defaultChecked}
                        isLast={index === outsideExceptionIntervals.length - 1}
                        isFirst={false}
                        isException={true}
                        exceptionStartDate={batch.exceptionStartDate}
                        exceptionEndDate={batch.exceptionEndDate}
                        onAdd={() =>
                          addNewOutsideExceptionBatch(
                            false,
                            true,
                            new Date(),
                            new Date()
                          )
                        }
                        onRemove={() => removeOutsideExceptionBatch(batch.id)}
                        onStartTimeChange={(newStartTime) =>
                          updateOutsideExceptionStartTime(
                            batch.id,
                            newStartTime
                          )
                        }
                        onEndTimeChange={(newEndTime) =>
                          updateOutsideExceptionEndTime(batch.id, newEndTime)
                        }
                        onExceptionEndDateChange={(newEndDate) =>
                          updateOutsideExceptionEndDate(batch.id, newEndDate)
                        }
                        onExceptionStartDateChange={(newStartDate) =>
                          updateOutsideExceptionStartDate(
                            batch.id,
                            newStartDate
                          )
                        }
                        onDayChange={(dayString) => {
                          updateOutsideExceptionCheckedDays(
                            batch.id,
                            dayString
                          );
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="dayBeforeTime" className="text-s">
                Extra bokning
              </label>
              <div className="general-popup-flex-left">
                <input
                  className="time-batch-input text-m"
                  type="time"
                  name="dayBeforeTime"
                  value={permissionData.dayBeforeTime}
                  onChange={(e) => handleTimeChange(e)}
                  onBlur={(e) => handleTimeBlur(e)}
                />
                <span className="text-m"> Dagen före aktiv bokning</span>
              </div>
            </div>
            <div className="general-popup-form-field">
              <span className="text-s">Övrigt</span>

              <div className="general-popup-flex-between">
                <span className="text-m"> Gym </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="gym"
                    checked={features.gym}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Betalkort </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="paymentCard"
                    checked={features.paymentCard}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Ranking </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="ranking"
                    checked={features.ranking}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Titel </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="title"
                    checked={features.title}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Sökbar för medlemmar </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="searchable"
                    checked={features.searchable}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Lektioner
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="classes"
                  className="time-batch-select long text-m"
                  value={classOption}
                  name="classes"
                  onChange={(e) => setClassOption(e.target.value)}
                >
                  <option value="all">Alla banor</option>
                  <option value="specific">Specifika banor</option>
                </select>
              </div>

              {classOption === "specific" &&
                props.courts.map((court, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">{court.name}</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={selectedCourts[court.court_id] || false}
                        onChange={() => toggleCourtSelection(court.court_id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="general-popup-form-field">
              <span className="text-s">Rabatt som medspelare</span>
              <div className="general-popup-flex-between">
                <label htmlFor="discount" className="text-m">
                  Tider utanför spelrätten
                </label>
                <div className="discount-price-wrapper">
                  {props.prices.map((price) => {
                    const priceName = getSwedishAdjectiveColor(price.color);
                    const discountOption = discountOptions.find(
                      (option) => option === Number(permissionData.discount)
                    );
                    let dividor = discountOption ? discountOption : 0;

                    const priceValue = Math.floor(
                      price.price - (price.price * dividor) / 100
                    );

                    return (
                      <span className="text-m">
                        {priceName + ": " + priceValue + " kr"}
                      </span>
                    );
                  })}

                  <select
                    id="discount"
                    className="time-batch-select text-m"
                    value={permissionData.discount}
                    name="discount"
                    onChange={(e) => handleInputChange(e)}
                  >
                    {discountOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} %
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="maxBookings" className="text-s">
                Max antal bokningar i veckan
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="maxBookings"
                  className="time-batch-select long text-m"
                  value={permissionData.maxBookings}
                  name="maxBookings"
                  onChange={(e) => handleInputChange(e)}
                >
                  {maxBookingsOptions.map((option) => {
                    const infinite = option === 100;
                    return infinite ? (
                      <option key={option} value={option}>
                        Obegränsat
                      </option>
                    ) : (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Skapa medlemskap
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={() => {
          handleClose();
        }}
        questionText="Är du säker på att du vill avbryta?"
        confirmText="Ja"
        denyText="Nej"
      />
    </Popup>
  );
}

export default CreatePermissionPopup;
