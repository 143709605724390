import { useState, Fragment, useEffect } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IRehabSummary, IRehabSummaryItem, IUser } from "../../../types/api";
import "./HandleRehabTimesPopup.scss";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";
import greenCheck from "../../../assets/images/green-check.svg";
import { CognitoUser } from "../../../types/cognito";
import { toast } from "react-toastify";
import { formatDate } from "../../../shared/dateUtils";
import { getUserByMemberId } from "../../../api/users/users";

interface IHandleRehabTimesPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: () => void;
  rehabSummary?: IRehabSummary;
  date: Date;
  user: CognitoUser | null;
}

function HandleRehabTimesPopup(props: IHandleRehabTimesPopupProps) {
  const { rehabSummary, date, showPopup, onClose, user } = props;
  const [isReady, setIsReady] = useState(false);
  const { weekday, dateAndMonth } = formatDateToSwedish(date);
  const [selectedSlot, setSelectedSlot] = useState<IRehabSummaryItem | null>(
    null
  );
  const [usersData, setUsersData] = useState<IUser[]>([]);

  const handleClose = () => {
    setSelectedSlot(null);
    setIsReady(false);
    onClose();
  };

  const getEndTime = (startTime: string) => {
    const hours = parseInt(startTime.slice(0, 2));
    const minutes = parseInt(startTime.slice(3, 5));

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    date.setMinutes(date.getMinutes() + 15);

    const newHours = date.getHours().toString().padStart(2, "0");
    const newMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  };

  const handleFetchBookerData = async (memberIds: string[]) => {
    const promises = memberIds.map((memberId) => getUserByMemberId(memberId));
    const response = await Promise.all(promises);
    setUsersData(response);
  };
  useEffect(() => {
    if (props.showPopup === true) {
      let memberIds: string[] = [];
      rehabSummary?.slots.forEach((slot) => {
        if (slot.booking !== null) {
          memberIds.push(slot.booking.member_id);
        }
      });
      handleFetchBookerData(memberIds);
    }
  }, [props.showPopup]);
  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">Bokade tider</span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-top"></div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {weekday}, {dateAndMonth}
                  </span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="rehab-slots-wrapper">
            <span className="text-m">Tider</span>
            {rehabSummary &&
              rehabSummary.slots.map((slot, index) => {
                const selectedClass =
                  selectedSlot?.start_time === slot.start_time
                    ? "selected"
                    : "";

                if (slot.booking !== null) {
                  const user = usersData.find(
                    (user) => user.member_id === slot.booking.member_id
                  );
                  return (
                    <div
                      key={slot.start_time}
                      className={`rehab-slot-wrapper text-m my-booking`}
                    >
                      <div className="rehab-slot-time">
                        <span>
                          {slot.start_time.slice(0, 5)}
                          {" - " + getEndTime(slot.start_time).slice(0, 5)}
                        </span>
                        <span>
                          {capitalizeWords(user?.first_name || "") +
                            " " +
                            capitalizeWords(user?.last_name || "") +
                            " (" +
                            slot.booking.member_id +
                            ")"}
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={slot.start_time}
                      className={`rehab-slot-wrapper pointer text-m ${selectedClass}`}
                    >
                      <div className="rehab-slot-time">
                        <span>
                          {slot.start_time.slice(0, 5)}
                          {" - " + getEndTime(slot.start_time).slice(0, 5)}
                        </span>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default HandleRehabTimesPopup;
