import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { IActivityInput } from "../../types/api";
import { nukeSlotQueryFn } from "./nukeQuerries";

export const nukeSlot = async (
  input: IActivityInput,
  datetime_to_remove: string
) => {
  const { query, inputs, variables } = nukeSlotQueryFn(
    input,
    datetime_to_remove
  );
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const getGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getGuestResponse.data.nukeSlot;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
