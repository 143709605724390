import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  deleteGuestQueryFn,
  exportGuestDataQueryFn,
  getGuestByMemberIdQueryFn,
  listGuestsQueryFn,
  serachGuestsQueryFn,
  updateGuestQueryFn,
} from "./guestQueries";
import { ICreateGuest, IGuest } from "../../types/api";

export const searchGuests = async (searchString: string) => {
  const { query, variables, inputs } = serachGuestsQueryFn(searchString);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getGuestResponse.data.searchGuest;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const listGuests = async (limit: number, start_key: string) => {
  const { query, inputs, variables } = listGuestsQueryFn(limit, start_key);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getGuestResponse.data.listGuests;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getGuestByMemberId = async (member_id: string) => {
  const { query, variables, inputs } = getGuestByMemberIdQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getGuestResponse.data.getGuestByID;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createGuest = async (guestToCreate: ICreateGuest) => {
  const query = `mutation myMutation($input: GuestInput!) {
    createGuest(input: $input) {
      ... on Guest {
        email
        first_name
        last_name
        member_id
        phone_number
        times_played
        family_member
      }
      ... on Error {
        code
        message
      }
    }
  }`;

  try {
    await Auth.currentSession();
    const createGuestResponse = (await API.graphql({
      query: query,
      variables: { input: guestToCreate },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = createGuestResponse.data.createGuest;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const exportGuestData = async () => {
  const { query } = exportGuestDataQueryFn();
  const myQuery = `query MyQuery {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getGuestResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getGuestResponse.data.exportGuestData;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteGuest = async (member_id: string) => {
  const { query, inputs, variables } = deleteGuestQueryFn(member_id);
  const myQuery = `mutation MyMutation(${inputs}) {
      ${query}
    }`;
  try {
    await Auth.currentSession();
    const deleteGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = deleteGuestResponse.data.deleteGuest;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateGuest = async (
  member_id: string,
  input_data: Partial<IGuest>
) => {
  const { query, inputs, variables } = updateGuestQueryFn(
    member_id,
    input_data
  );
  const myQuery = `mutation MyMutation(${inputs}) {
      ${query}
    }`;
  try {
    await Auth.currentSession();
    const updateGuestResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateGuestResponse.data.updateGuest;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
