import { ICreateAdminSpec } from "../../types/api";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { deleteAdminQueryFn, editAdminQueryFn } from "./adminQueries";

export const createAdmin = async (adminToCreate: ICreateAdminSpec) => {
  const query = `mutation MyMutation($input: AdminInput!) {
            createAdmin(input: $input) {
            ... on Admin {
                member_id
                email
                first_name
                last_name
                }
            ... on Error {
                message
                code
            }
        }
    }
  `;

  try {
    const getCreateResponse = (await API.graphql({
      query: query,
      variables: { input: adminToCreate },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createAdmin;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const editAdmin = async (
  member_id: string,
  adminToEdit: ICreateAdminSpec,
  old_email: string
) => {
  const { query, inputs, variables } = editAdminQueryFn(member_id, adminToEdit, old_email);
  const myQuery = `mutation (${inputs}) {
        ${query}
        }`;
  try {
    const getEditResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getEditResponse.data.updateAdmin;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteAdmin = async (member_id: string) => {
  const { query, variables, inputs } = deleteAdminQueryFn(member_id);
  const myQuery = `mutation (${inputs}) {
        ${query}
        }`;
  try {
    const getDeleteResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getDeleteResponse.data.deleteAdmin;

    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
