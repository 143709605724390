export const getSlotHistoryQueryFn = (court_id: string, date: string) => {
  const query = `
            getSlotHistory( court_id: $court_id, date: $date) {
                ... on SlotHistory {
                datetime_start
                court_id
                history {
                    created_at
                    created_by
                    event
                }
                slot_comment
                }
                ... on Error {
                code
                message
                }
            }
        `;
  const inputs = `$court_id: String!, $date: String!`;
  const variables = { court_id: court_id, date: date };
  return {
    query,
    inputs,
    variables,
  };
};
