import React, { useEffect, useState } from "react";
import {
  ICourt,
  ICourtType,
  IOutsideSettingsDaily,
  IPrices,
} from "../../types/api";
import EditCourtPopup from "../../containers/Courts/EditCourtPopup/EditCourtPopup";
import CreateCourtPopup from "../../containers/Courts/CreateCourtPopup/CreateCourtPopup";
import "./AdminHandleCourts.scss";
import EditPriceValuePopup from "../../containers/Courts/EditPriceValuePopup/EditPriceValuePopup";
import { getWeekdayEngName, getWeekdayFromDate } from "../../shared/utility";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "react-toastify";
import { formatToIsoDate } from "../../shared/dateUtils";

interface AdminHandleCourtsProps {}

function AdminHandleCourts(props: AdminHandleCourtsProps) {
  const [selectedDay, setSelectedDay] = useState("");
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const [currentCourtType, setCurrentCourtType] =
    useState<ICourtType>("INDOOR");
  const [prices, setPrices] = useState<IPrices[]>();
  const [outsideSettingsDaily, setOutsideSettingsDaily] =
    useState<IOutsideSettingsDaily>();
  const [activeCourtForEditChoices, setActiveCourtForEditChoices] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentEditCourt, setCurrentEditCourt] = useState<ICourt>();
  const [showEditPriceValuePopup, setShowEditPriceValuePopup] =
    useState<boolean>(false);
  const [showEditCourtPopup, setShowEditCourtPopup] = useState<boolean>(false);
  const [showCreateCourtPopup, setShowCreateCourtPopup] =
    useState<boolean>(false);

  const fetchChosenItems = async ({
    fetchPrices = false,
    fetchCourts = false,
    fetchOutsideSettingsDaily = false,
  }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchPrices: fetchPrices,
        fetchCourts: fetchCourts,
        fetchOutsideSettingsDaily: fetchOutsideSettingsDaily,
        date: formatToIsoDate(filterDate),
      });
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }
      if (fetchPrices && response.prices && !("message" in response.prices)) {
        setPrices(response.prices);
      }
      if (
        fetchOutsideSettingsDaily &&
        response.outsideSettingsDaily &&
        !("message" in response.outsideSettingsDaily)
      ) {
        setOutsideSettingsDaily(response.outsideSettingsDaily);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchCourts: true,
      fetchPrices: true,
      fetchOutsideSettingsDaily: true,
    });
    const weekdayNum = getWeekdayFromDate(filterDate.toString());
    const weekday = getWeekdayEngName(weekdayNum);
    setSelectedDay(weekday);
    const closeEditChoices = () => setActiveCourtForEditChoices(null);
    document.addEventListener("click", closeEditChoices);
    return () => document.removeEventListener("click", closeEditChoices);
  }, []);

  useEffect(() => {}, [currentCourtType]);

  useEffect(() => {
    fetchChosenItems({
      fetchOutsideSettingsDaily: true,
    });
  }, [filterDate]);

  const openCreateCourt = () => {
    setShowCreateCourtPopup(true);
  };
  const openEditChoice = (courtId: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setActiveCourtForEditChoices((prev) => (prev === courtId ? null : courtId));
  };

  const openEditCourtPopup = (court: ICourt, selectedDay?: string) => () => {
    setCurrentEditCourt(court);
    setShowEditCourtPopup(true);
  };

  const openEditPriceValue = () => {
    setShowEditPriceValuePopup(true);
  };
  const handleDayChange = (date: Date) => {
    const weekdayNum = getWeekdayFromDate(date.toString());
    const weekday = getWeekdayEngName(weekdayNum);
    setFilterDate(date);
    setSelectedDay(weekday);
  };

  const generateHoursWithinInterval = (
    startTime: string,
    endTime: string,
    price: string
  ) => {
    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinutes = parseInt(startTime.split(":")[1], 10);
    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinutes = parseInt(endTime.split(":")[1], 10);

    const hoursWithPrice = [];

    let currentTime = startHour * 60 + startMinutes;

    const endTimeInMinutes = endHour * 60 + endMinutes;

    if (startMinutes !== 0 && startMinutes !== 30) {
      currentTime = currentTime + (60 - startMinutes);
    }

    while (currentTime + 60 <= endTimeInMinutes) {
      const currentHour = Math.floor(currentTime / 60);
      const currentMinute = currentTime % 60;

      if (currentMinute === 0 || currentMinute === 30) {
        const timeSlot = `${currentHour
          .toString()
          .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;
        hoursWithPrice.push({
          time: timeSlot,
          price: price,
          class: currentMinute === 0 ? "full-hour" : "half-hour",
        });
      }

      currentTime += 60; // Move to the next hour or half-hour
    }

    return hoursWithPrice;
  };

  const toggleCourtType = (courtType: ICourtType) => {
    setCurrentCourtType(courtType);
  };

  if (!courts || !prices) return <Spinner />;

  return (
    <div className="admin-handle-courts-layout">
      <h1 className="text-l primary-text">Banor</h1>
      <div className="handle-courts-actions-wrapper">
        <div className="courts-weekday-selector-wrapper">
          <div className="courts-in-out-toggle-wrapper">
            <button
              className={`toggle-button ${
                currentCourtType === "INDOOR" ? "active" : ""
              }`}
              value="INDOOR"
              onClick={() => toggleCourtType("INDOOR")}
            >
              <span className="text-m">Inne </span>
            </button>
            <button
              className={`toggle-button ${
                currentCourtType === "OUTDOOR" ? "active" : ""
              }`}
              value="OUTDOOR"
              onClick={() => toggleCourtType("OUTDOOR")}
            >
              <span className="text-m">Ute </span>
            </button>
          </div>
          <WeekDaySelector
            value={filterDate}
            onChange={handleDayChange}
            includeWeekdays
            textM
          />
        </div>
        <div className="courts-add-wrapper">
          <div className="courts-opening-settings-wrapper text-m">
            {/* {outsideSettingsDaily && (
              <div className="courts-opening-items-wrapper">
                <button> Uppdatera öppettider </button>
                <div className="courts-opening-items-wrapper">
                  {outsideSettingsDaily.opening_hours} -{" "}
                  {outsideSettingsDaily.closing_hours}
                </div>
              </div>
            )} */}
          </div>
          <div className="courts-default-prices">
            {prices.map((price, index) => {
              return (
                <div key={index} className="courts-price-wrapper">
                  <div
                    className={`text-m courts-price-color ${price.color}`}
                  ></div>
                  <div className="text-m text-regular">{price.price} (kr)</div>
                </div>
              );
            })}
          </div>
          <div className="courts-buttons-wrapper">
            <button className="courts-add-button" onClick={openCreateCourt}>
              <span className="text-m">Lägg till bana</span>
            </button>
            <button className="courts-add-button" onClick={openEditPriceValue}>
              <span className="text-m">Redigera priser</span>
            </button>
          </div>
        </div>
      </div>
      <div className="courts-table-overflow-wrapper">
        <table className="courts-table">
          <thead>
            <tr>
              {courts.map((court, index) => {
                if (court.court_type !== currentCourtType) return null;
                return (
                  <th key={index} onClick={openEditCourtPopup(court)}>
                    <div className="court-name-header">
                      <span className="text-m secondary-text">
                        {court.name + " - " + court.court_id}
                      </span>
                      <div className="edit-component">
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>

                        <div
                          className={`edit-popup-choice ${
                            activeCourtForEditChoices === court.court_id
                              ? "active"
                              : ""
                          }`}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <button
                            className="edit-popup-choice-item"
                            onClick={openEditCourtPopup(court, "availability")}
                          >
                            Redigera tillgänglighet
                          </button>
                          <button
                            className="edit-popup-choice-item"
                            onClick={openEditCourtPopup(court, selectedDay)}
                          >
                            Redigera priser
                          </button>
                        </div>
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Array.from(
              {
                // Determine the maximum number of slots in any court's availability for the selected day
                length: Math.max(
                  ...courts.map(
                    (court) =>
                      court.availability
                        .find((avail) => avail.weekday === selectedDay)
                        ?.interval.reduce((acc, interval) => {
                          // Add the count of slots in each interval to the accumulator
                          const slots = generateHoursWithinInterval(
                            interval.start_time,
                            interval.end_time,
                            interval.price
                          );

                          return acc + slots.length;
                        }, 0) || 0
                  )
                ),
              },
              (_, slotIndex) => (
                <tr key={slotIndex}>
                  {courts.map((court, courtIndex) => {
                    const availability = court.availability.find(
                      (avail) => avail.weekday === selectedDay
                    );
                    if (court.court_type !== currentCourtType) {
                      return null;
                    }

                    if (
                      court.court_type !== currentCourtType ||
                      !availability
                    ) {
                      return (
                        <td key={`empty-${courtIndex}-${slotIndex}`}>Stängt</td>
                      );
                    }

                    // Find the corresponding slot for this time across all courts
                    const timeSlots = availability.interval.flatMap(
                      (interval) =>
                        generateHoursWithinInterval(
                          interval.start_time,
                          interval.end_time,
                          interval.price
                        )
                    );
                    const slot = timeSlots[slotIndex];

                    if (slot) {
                      return (
                        <td
                          key={`slot-${courtIndex}-${slotIndex}`}
                          className={`court-intervals-wrapper`}
                        >
                          <div className={`court-time-wrapper ${slot.class}`}>
                            <div className="text-m">{slot.time}</div>
                            <div
                              className={`price-wrapper text-m ${slot.price}`}
                            ></div>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={`empty-slot-${courtIndex}-${slotIndex}`}>
                          &nbsp; {/* Non-breaking space for alignment */}
                        </td>
                      );
                    }
                  })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <EditCourtPopup
        showPopup={showEditCourtPopup}
        onClose={() => {
          setShowEditCourtPopup(false);
          setCurrentEditCourt(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({
            fetchCourts: true,
          });
        }}
        court={currentEditCourt}
        selectedDay={selectedDay}
        prices={prices}
      />
      <EditPriceValuePopup
        showPopup={showEditPriceValuePopup}
        onClose={() => setShowEditPriceValuePopup(false)}
        onConfirm={() => fetchChosenItems({ fetchPrices: true })}
        prices={prices}
      />
      <CreateCourtPopup
        showPopup={showCreateCourtPopup}
        onClose={() => setShowCreateCourtPopup(false)}
        onConfirm={() => {
          fetchChosenItems({
            fetchCourts: true,
          });
        }}
        prices={prices}
      />
    </div>
  );
}

export default AdminHandleCourts;
