import "./CreateCourtPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  ICourt,
  ICourtToCreate,
  ICourtToEdit,
  IPrices,
} from "../../../types/api";
import { toast } from "react-toastify";
import TimeInputBatch from "../../../hoc/TimeBatch/TimeBatch";
import { v4 as uuidv4 } from "uuid";
import useTimeBatches from "../../../shared/hooks/useTimeBatches";
import { createCourt, editCourt } from "../../../api/courts/courts";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";

interface ICreateCourtPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  prices: IPrices[];
}

function CreateCourtPopup(props: ICreateCourtPopupProps) {
  const initialCourtData = {
    name: "",
    court_id: "",
    court_api: "",
    court_type: "INDOOR",
  };

  const [courtData, setCourtData] = useState(initialCourtData);
  const [showCancelConfirmationPopup, setShowCancelConfirmationPopup] =
    useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const initialBatch = {
    id: uuidv4(),
    startTime: "07:00:00",
    endTime: "23:00:00",
    checkedDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    defaultChecked: true,
    isException: false,
    priceChoice: "blue",
  };
  const {
    timeBatches: intervals,
    addTimeBatch: addNewBatch,
    removeTimeBatch: removeBatch,
    setBatches: setBatches,
    updateStartTime,
    updateEndTime,
    updateCheckedDays,
    updatePriceChoice,
  } = useTimeBatches([initialBatch]);

  const handleCreateCourt = async () => {
    try {
      const courtToCreate: ICourtToCreate = {
        name: courtData.name,
        court_id: courtData.court_id,
        court_api: courtData.court_api,
        court_type: courtData.court_type,
        bookable_for_lessons: [],
        maintanance: [],
        availability: weekdays
          .map((weekday, index) => {
            const dayIntervals = intervals
              .filter((batch) => batch.checkedDays[weekdays[index]])
              .map((batch) => ({
                start_time: batch.startTime,
                end_time: batch.endTime,
                price: batch.priceChoice || "blue",
              }));
            return {
              interval: dayIntervals,
              weekday: weekday,
            };
          })
          .filter((day) => day.interval.length > 0),
      };

      const createCourtResponse = await createCourt(courtToCreate);
      if (!createCourtResponse) throw new Error("Error creating court");

      props.onClose();
      props.onConfirm();
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCourtData({ ...courtData, [e.target.name]: e.target.value });
  };
  interface DayIntervals {
    [key: string]: {
      startTime: string;
      endTime: string;
    }[];
  }
  const doIntervalsOverlap = (court: ICourtToEdit) => {
    const dayWiseIntervals: DayIntervals = {};

    court.input_data.availability.forEach((availability) => {
      availability.interval.forEach((interval) => {
        availability.weekday.split(",").forEach((day) => {
          if (!dayWiseIntervals[day]) {
            dayWiseIntervals[day] = [];
          }
          dayWiseIntervals[day].push({
            startTime: interval.start_time,
            endTime: interval.end_time,
          });
        });
      });
    });

    for (const day in dayWiseIntervals) {
      const intervals = dayWiseIntervals[day];
      const sortedIntervals = intervals.sort((a, b) =>
        a.startTime.localeCompare(b.startTime)
      );

      for (let i = 0; i < sortedIntervals.length - 1; i++) {
        if (sortedIntervals[i].endTime > sortedIntervals[i + 1].startTime) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={() => setShowCancelConfirmationPopup(true)}
      leftTopElement={<span className="text-m">Skapa bana</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="name" className="text-s">
                Namn på bana
              </label>
              <input
                type="text"
                id="courtName"
                className="text-m"
                name="name"
                placeholder="Namn på bana"
                value={courtData.name}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="courtId" className="text-s">
                ID på bana
              </label>
              <input
                type="text"
                id="courtName"
                className="text-m"
                name="court_id"
                placeholder="ID på bana"
                value={courtData.court_id}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="courtApi" className="text-s">
                API för bana
              </label>
              <input
                type="text"
                id="courtName"
                className="text-m"
                name="court_api"
                placeholder="API för bana"
                value={courtData.court_api}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="courtType" className="text-s">
                Typ av bana
              </label>
              <select
                className="text-m"
                name="court_type"
                id="courtType"
                value={courtData.court_type}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="INDOOR">Inomhus</option>
                <option value="OUTDOOR">Utomhus</option>
              </select>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid inomhus
              </label>
              {intervals.map((batch, index) => (
                <TimeInputBatch
                  key={batch.id}
                  id={batch.id}
                  startTime={batch.startTime || "07:00:00"}
                  endTime={batch.endTime || "23:00:00"}
                  checkedDays={batch.checkedDays}
                  defaultChecked={batch.defaultChecked}
                  isLast={index === intervals.length - 1}
                  isFirst={index === 0}
                  isException={false}
                  onAdd={() =>
                    addNewBatch(
                      true,
                      false,
                      new Date(),
                      new Date(),
                      props.prices,
                      "blue"
                    )
                  }
                  onRemove={() => removeBatch(batch.id)}
                  onStartTimeChange={(newStartTime) =>
                    updateStartTime(batch.id, newStartTime)
                  }
                  onEndTimeChange={(newEndTime) =>
                    updateEndTime(batch.id, newEndTime)
                  }
                  onDayChange={(dayString) => {
                    updateCheckedDays(batch.id, dayString);
                  }}
                  priceChoices={props.prices}
                  priceChoice={batch.priceChoice}
                  onPriceChoiceChange={(newPriceChoice) =>
                    updatePriceChoice(batch.id, newPriceChoice)
                  }
                />
              ))}
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Lägg till bana
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showCancelConfirmationPopup}
        onClose={() => setShowCancelConfirmationPopup(false)}
        onConfirm={props.onClose}
        questionText="Är du säker på att du vill avbryta?"
        confirmText="Ja"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={() => {
          handleCreateCourt();
        }}
        questionText="Är du säker på att du vill skapa denna bana?"
        confirmText="Ja"
        denyText="Nej"
      />
    </Popup>
  );
}

export default CreateCourtPopup;
