export const addToQueueQueryFn = (
  member_id: string,
  datetime_start: string,
  court_type:string
) => {
  const query = `
            AddToQueue(member_id: $member_id, datetime_start: $datetime_start, court_type: $court_type) {
            ... on User {
                account_type
                email_cc
                last_name
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$member_id: String!, $datetime_start: String!, $court_type: String!`;
  const variables = { member_id: member_id, datetime_start: datetime_start, court_type: court_type };
  return {
    query,
    inputs,
    variables,
  };
};

export const getActiveQueueSpotsByMemberQueryFn = (member_id: string) => {
  const query = `
            getActiveQueueSpotsByMember(member_id: $member_id) {
                ... on QueueItem {
                    PK
                    first_name
                    datetime_start
                    phone_number
                    placed_in_queue
                    court_type
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const removeFromQueueQueryFn = (
  datetime_start: string,
  member_id: string
) => {
  const query = `
            RemoveFromQueue(datetime_start: $datetime_start, member_id: $member_id) {
                ... on User {
                    first_name
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$datetime_start: String!, $member_id: String!`;
  const variables = { datetime_start: datetime_start, member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};
