import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { getSlotHistoryQueryFn } from "./slotHistoryQueries";

export const getSlotHistory = async (court_id: string, date: string) => {
  const { query, inputs, variables } = getSlotHistoryQueryFn(court_id, date);
  const myQuery = `
            query getSlotHistory(${inputs}) {
                 ${query}
            }
        `;
  try {
    const getSlotHistoryResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getSlotHistoryResponse.data.getSlotHistory;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};
