import { useCallback, useEffect, useRef, useState } from "react";
import { IUser } from "../../../types/api";
import MidPopup from "../../../hoc/MidPopup/MidPopup";
import { toast } from "react-toastify";
import "./ChangePasswordForUserPopup.scss";
import { changePassword } from "../../../api/changePassword/changePassword";
import { checkPassword } from "../../../shared/utility";
interface IChangePasswordForUserPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: () => void;
}
function ChangePasswordForUserPopup(props: IChangePasswordForUserPopupProps) {
  const { onClose, showPopup, onConfirm } = props;
  const [member_id, setMember_id] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleClose = () => {
    setMember_id("");
    setPassword("");
    onClose();
  };
  const handleChangePassword = async () => {
    const errors = checkPassword(password);
    if (member_id.length !== 4 || errors.length > 0) {
      toast.error(errors[0]);
      return;
    }
    try {
      const response = await changePassword(member_id, password);
      if (!response) {
        return;
      }
      toast.success("Lösenordet har ändrats");
      onConfirm();
      onClose();
    } catch (error: any) {
      toast.error(error);
    }
  };
  return (
    <MidPopup
      onClose={handleClose}
      showPopup={showPopup}
      leftTopElement={<span className="text-m">Byt lösenord åt användare</span>}
      onConfirm={() => onConfirm()}
    >
      <div className="choose-users-popup-wrapper">
        <div className="user-input-btn-wrapper text-m">
          <input
            placeholder="medlems id"
            maxLength={4}
            value={member_id}
            onChange={(e) => setMember_id(e.target.value)}
          ></input>
          <input
            placeholder="nytt lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <button
          className="comment-button text-m"
          onClick={handleChangePassword}
        >
          Byt lösenord
        </button>
      </div>
    </MidPopup>
  );
}

export default ChangePasswordForUserPopup;
