import { ICreateUserSpec, IEditUser, IUser } from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  activateMembersipQueryFn,
  createQueueUserQueryFn,
  deactivateMembershipQueryFn,
  listQueueUsersQueryFn,
  serachQueueUsersQueryFn,
} from "./queueUserQueries";

export const listQueueUsers = async (limit: number, start_key: string) => {
  const { query, inputs, variables } = listQueueUsersQueryFn(limit, start_key);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.listQueueUsers;

    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const searchQueueUsers = async (searchString: string) => {
  const { query, variables, inputs } = serachQueueUsersQueryFn(searchString);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.searchQueueUser;

    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createQueueUser = async (
  userToCreate: ICreateUserSpec,
  set_id: boolean
) => {
  const { query, inputs, variables } = createQueueUserQueryFn(
    userToCreate,
    set_id
  );

  const myQuery = `
        mutation createQueueUser(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCreateResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createQueueUser;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const activateMembership = async (
  member_id: string,
  new_member_type: string
) => {
  const { query, inputs, variables } = activateMembersipQueryFn(
    member_id,
    new_member_type
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.activateMembership;

    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deactivateMembership = async (member_id: string) => {
  const { query, inputs, variables } = deactivateMembershipQueryFn(member_id);
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.deactivateMembership;

    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
