import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  ICoach,
  ICoachBooking,
  ICourt,
  IEventPrice,
  IGuest,
  IUser,
} from "../../../types/api";
import { getUserByMemberId } from "../../../api/users/users";
import { getGuestByMemberId } from "../../../api/guests/guests";
import { getCoachByID } from "../../../api/coaches/coaches";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";

interface ICoachShowBookedInfoPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: () => void;
  booking?: ICoachBooking;
  courts: ICourt[];
}

function CoachShowBookedInfoPopup(props: ICoachShowBookedInfoPopupProps) {
  const { booking, showPopup } = props;
  const [mainBookerData, setMainBookerData] = useState<IUser>();
  const [coPlayersData, setCoPlayersData] = useState<IUser[]>();
  const [guestsData, setGuestsData] = useState<IGuest[]>();
  const [coachData, setCoachData] = useState<ICoach>();
  const [checkedInBookingCompare, setCheckedInBookingCompare] =
    useState<ICoachBooking>();

  const handleClose = () => {
    props.onClose();
  };

  const handleFetchMainBookerData = async () => {
    if (booking === undefined) return;
    const mainBooker = booking.member_id;
    const response = await getUserByMemberId(mainBooker);
    setMainBookerData(response);
  };
  const handleFetchGuestsData = async () => {
    if (booking === undefined) return;
    const guestList: IGuest[] = [];
    const guests = booking.guests;
    const response = await Promise.all(
      guests.map(async (guest) => {
        if (guest.member_id !== "guest") {
          return await getGuestByMemberId(guest.member_id);
        }
      })
    );
    response.forEach((res) => {
      if (res) {
        guestList.push(res);
      }
    });
    setGuestsData(guestList);
  };

  const handleFetchCoPlayersData = async () => {
    if (booking === undefined) return;
    const coPlayers = booking.co_players;
    const response = await Promise.all(
      coPlayers.map(async (coPlayer) => {
        return await getUserByMemberId(coPlayer.member_id);
      })
    );
    setCoPlayersData(response);
  };

  const handleFetchCoachData = async () => {
    if (booking === undefined) return;
    const coach = booking.coach_id[0];
    const response = await getCoachByID(coach);

    setCoachData(response);
  };

  useEffect(() => {
    if (booking !== undefined) {
      handleFetchMainBookerData();
      handleFetchCoPlayersData();
      handleFetchGuestsData();
      if (booking.coach_id.length > 0) {
        handleFetchCoachData();
      }
    }
  }, [showPopup]);

  const startDate = new Date(booking?.datetime_start || "");
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(
    new Date(startDate)
  );

  function accumulatePrices(finalPrices: IEventPrice[]): string {
    const total = finalPrices.reduce((sum, item) => sum + item.price, 0);
    return total.toString();
  }
  const dateString = `${weekday} ${dateAndMonth}`;
  const courtName = props.courts.find(
    (c) => c.court_id === booking?.court_id
  )?.name;
  let timeCourtString = "";

  if (startDate) {
    const startTime = new Date(startDate);
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add one hour

    const formatTime = (date: Date) => {
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    timeCourtString = `${formatTime(startTime)} - ${formatTime(
      endTime
    )} ${courtName}`;
  }

  const userString = `${
    capitalizeWords(mainBookerData?.first_name || "Loading") +
    " " +
    capitalizeWords(mainBookerData?.last_name || "Loading") +
    " (" +
    mainBookerData?.member_id +
    ")"
  }`;

  const bookingToLoop = checkedInBookingCompare || (booking as ICoachBooking);
  if (bookingToLoop === undefined) {
    return null;
  }
  const coachPrize =
    bookingToLoop.coach_id.length > 0
      ? bookingToLoop.final_price.find((event) =>
          event.event.includes("coach_price")
        )?.price
      : 0;
      
     
  return (
    <Popup
      onClose={handleClose}
      showPopup={props.showPopup}
      leftTopElement={<span className="text-m">Information bokning </span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{dateString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text ">Tid + Bana</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{timeCourtString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Huvudbokare</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span> {userString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m primary-text">Medspelare</span>
                <span className="text-m primary-text text-svg-wrapper">
                  <div className="chosen-players">
                    {coPlayersData &&
                      coPlayersData.map((player, index) => {
                        const { first_name, last_name } = player;
                        const playerString = `${capitalizeWords(
                          first_name
                        )} ${capitalizeWords(last_name)} (${player.member_id})`;
                        const priceForPlayer = bookingToLoop.final_price.find(
                          (event) => {
                            return event.event.includes(player.member_id);
                          }
                        );
                        const priceString = priceForPlayer?.price || "0";

                        return (
                          <div key={index} className="chosen-player">
                            {playerString}
                          </div>
                        );
                      })}
                  </div>
                </span>
              </div>
              {bookingToLoop.guests.length > 0 && (
                <div className="general-popup-flex-between align-top">
                  <span className="text-m primary-text">Gäster</span>
                  <span className="text-m primary-text text-svg-wrapper">
                    <div className="chosen-players">
                      {bookingToLoop.guests.map((guest, index) => {
                        const priceForPlayer = bookingToLoop.final_price.find(
                          (event) => {
                            return event.event.includes("guest");
                          }
                        );
                        const priceString = priceForPlayer?.price || "0";

                        let guestToShow = guestsData?.find((g) =>
                          g.member_id !== "guest"
                            ? g.member_id === guest.member_id
                            : false
                        );

                        return (
                          <div key={index} className="chosen-player">
                            {guestToShow
                              ? capitalizeWords(guestToShow.first_name) +
                                " " +
                                capitalizeWords(guestToShow.last_name)
                              : `Gäst ${index + 1}`}
                            {bookingToLoop.guests[index].status ===
                            "CHECKED_IN" ? (
                              <>
                                <span className="price-for-member">
                                  {priceString} kr
                                </span>
                                <span className="checked-in">Incheckad</span>
                              </>
                            ) : (
                              <>
                                <span className="price-for-member">
                                  {priceString} kr
                                </span>
                                <span className="">
                                  <div> inte incheckad</div>
                                </span>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </span>
                </div>
              )}
              {booking !== undefined && booking.coach_id.length > 0 && (
                <div className="general-popup-flex-between align-top">
                  <span className="text-m primary-text">Tränare</span>
                  <span className="text-m primary-text">
                    {bookingToLoop.coach_id.map((coach) => {
                      const coachString =
                        capitalizeWords(
                          coachData?.first_name + " " + coachData?.last_name
                        ) +
                        " (" +
                        coach +
                        ")";
                      return (
                        <div>
                          {coachString}
                           {/* {coachPrize} kr */}
                        </div>
                      );
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CoachShowBookedInfoPopup;
