import { ICourtType } from "../../types/api";

export const getSlotsByMemberQueryFn = (
  date: string,
  member_id: string,
  court_type: ICourtType
) => {
  const dateString = date.slice(0, 10);
  const query = `
        getSlotsByMember(date: $date, member_id: $member_id, court_type: $court_type) {
        ... on Summary {
                  court_id
                  court_type
                  date
                  slots {
                    available_for_member_type
                    coach_available 
                    end_time
                    start_time
                    queue
                    price
                    activity {
                      coach_id
                      color
                      court_id
                      comment
                      datetime_start
                      datetime_end
                      hidden
                      members_with_access
                      name
                      price
                      participants {
                        member_id
                        status
                      }
                      participants_limit
                      ranking_max
                      ranking_min
                    }
                    booking {
                      booking_id
                      status
                      member_name
                      court_id
                      slot_index
                      datetime_start
                      coach_id
                      created_at
                      created_by
                      booking_type
                      final_price {
                        event
                        price
                      }
                      member_id
                      member_status
                      guests {
                        member_id
                        status
                      }
                      co_players {
                        member_id
                        status
                      }
                      comment
                    }
                  }
          }
        ... on Error {
          code
          message
        }
      }
    `;
  const inputs = `$date: String!, $member_id: String!, $court_type: String!`;
  const variables = {
    date: dateString,
    member_id: member_id,
    court_type: court_type,
  };

  return {
    query,
    inputs,
    variables,
  };
};

export const getSlotByMemberQueryFn = (
  date: string,
  member_id: string,
  court_id: string,
  court_type: ICourtType
) => {
  const query = `
        getSlotByMember(date: $date, member_id: $member_id, court_id: $court_id, court_type: $court_type) {
        ... on SummaryItem {
                    available_for_member_type
                    coach_available 
                    end_time
                    start_time
                    queue
                    price
                    activity {
                      coach_id
                      color
                      court_id
                      comment
                      datetime_start
                      datetime_end
                      hidden
                      members_with_access
                      name
                      price
                      participants {
                        member_id
                        status
                      }
                      participants_limit
                      ranking_max
                      ranking_min
                    }
                    booking {
                      booking_id
                      status
                      member_name
                      court_id
                      slot_index
                      datetime_start
                      coach_id
                      created_at
                      created_by
                      booking_type
                      final_price {
                        event
                        price
                      }
                      member_id
                      member_status
                      guests {
                        member_id
                        status
                      }
                      co_players {
                        member_id
                        status
                      }
                      comment
                    }
                  
          }
        ... on Error {
          code
          message
        }
      }
    `;
  const inputs = `$date: String!, $member_id: String!, $court_id: String!, $court_type: String!`;
  const variables = {
    date: date,
    member_id: member_id,
    court_id: court_id,
    court_type: court_type,
  };

  return {
    query,
    inputs,
    variables,
  };
};

export const getSlotsQueryFn = (date: string, court_type: ICourtType) => {
  const dateString = date.slice(0, 10);
  const query = `
        getSlots(date: $date, court_type: $court_type) {
        ... on Summary {
                  court_id
                  court_type
                  date
                  slots {
                    available_for_member_type
                    coach_available 
                    end_time
                    start_time
                    queue
                    price
                    activity {
                      coach_id
                      color
                      court_id
                      comment
                      datetime_start
                      datetime_end
                      hidden
                      members_with_access
                      name
                      price
                      participants {
                        member_id
                        status
                      }
                      participants_limit
                      ranking_max
                      ranking_min
                    }
                    booking {
                      datetime_start
                      booking_id
                      status
                      coach_id
                      court_id
                      slot_index
                      member_name
                      created_at
                      created_by
                      booking_type
                      final_price {
                        event
                        price
                      }
                      member_id
                      member_status
                      guests {
                        member_id
                        status
                      }
                      co_players {
                        member_id
                        status
                      }
                      comment
                    }
                  }
          }
        ... on Error {
          code
          message
        }
      }
    `;
  const inputs = `$date: String!, $court_type: String!`;
  const variables = { date: dateString, court_type: court_type };

  return {
    query,
    inputs,
    variables,
  };
};
