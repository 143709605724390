import { ICreateUserSpec } from "../../types/api";

export const serachQueueUsersQueryFn = (search: string) => {
  let query = `
    searchQueueUser(search: $search) {
         ...on User {
          member_type
          email
          first_name
          last_name
          member_id
          phone_number
          noshow_count
          frozen
         }
          ... on Error {
            code
            message
          }
      }
  `;

  const inputs = `$search: String!`;
  const variables = { search: search };

  return {
    query,
    inputs,
    variables,
  };
};

export const listQueueUsersQueryFn = (limit: number, start_key: string) => {
  const query = `
    listQueueUsers(limit: $limit, start_key: $start_key) {
         ... on displayUsers {
          users {
            email
            first_name
            last_name
            member_type
            member_id
            phone_number
            noshow_count
            frozen
          } 
          next_start_key
        }
        ... on Error {
          code
          message
        }
      }
  `;

  const inputs = `$limit: Int!, $start_key: String`;
  const variables = {
    limit: limit,
    start_key: start_key,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const createQueueUserQueryFn = (
  input: ICreateUserSpec,
  set_id: boolean
) => {
  const query = `createQueueUser(input: $input, set_id: $set_id) {
            ...on User {
              member_id
            }
            ...on Error {
              message
            }
          }
    `;
  const variables = { input: input, set_id: set_id };
  const inputs = `$input: UserInput!, $set_id: Boolean!`;
  return {
    inputs,
    query,
    variables,
  };
};

export const activateMembersipQueryFn = (
  member_id: string,
  new_member_type: string
) => {
  const query = ` 
        activateMembership(member_id: $member_id, new_member_type: $new_member_type) {
          ... on User {
            account_type
            email_cc
          }
          ... on Error {
            code
            message
          }
        }
  `;
  const inputs = `$member_id: String!, $new_member_type: String!`;
  const variables = { member_id: member_id, new_member_type: new_member_type };
  return {
    query,
    inputs,
    variables,
  };
};


export const deactivateMembershipQueryFn = (  
  member_id: string,
) => {
  const query = ` 
        deactivateMembership(member_id: $member_id) {
          ... on User {
            account_type
            email_cc
          }
          ... on Error {
            code
            message
          }
        }
  `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
}