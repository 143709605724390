import React from "react";
import "./TimeBatch.scss";
import plus from "../../assets/images/plus.svg";
import remove from "../../assets/images/remove.svg";
import WeekDaySelector from "../DatePicker/Datepicker";
import { IPrices } from "../../types/api";
import { getSwedishColor } from "../../shared/utility";

const weekdays = [
  { name: "monday", number: 0, abbreviation: "M" },
  { name: "tuesday", number: 1, abbreviation: "T" },
  { name: "wednesday", number: 2, abbreviation: "O" },
  { name: "thursday", number: 3, abbreviation: "T" },
  { name: "friday", number: 4, abbreviation: "F" },
  { name: "saturday", number: 5, abbreviation: "L" },
  { name: "sunday", number: 6, abbreviation: "S" },
];

interface ITimeInputBatchProps {
  id: string;
  startTime: string;
  endTime: string;
  checkedDays: { [key: string]: boolean };
  defaultChecked: boolean;
  isLast: boolean;
  isFirst: boolean;
  onAdd: () => void;
  onRemove: () => void;
  onStartTimeChange: (value: string) => void;
  onEndTimeChange: (value: string) => void;
  onDayChange: (dayString: string) => void;
  isException: boolean;
  exceptionStartDate?: Date;
  exceptionEndDate?: Date;
  reoccurring?: boolean;
  onReoccurringChange?: (value: boolean) => void;
  onExceptionStartDateChange?: (value: Date) => void;
  onExceptionEndDateChange?: (value: Date) => void;
  priceChoices?: IPrices[];
  priceChoice?: string;
  onPriceChoiceChange?: (value: string) => void;
}

const TimeInputBatch: React.FC<ITimeInputBatchProps> = ({
  id,
  startTime,
  endTime,
  checkedDays,
  onStartTimeChange,
  onEndTimeChange,
  onDayChange,
  isException,
  exceptionStartDate,
  exceptionEndDate,
  reoccurring,
  onReoccurringChange,
  onExceptionStartDateChange,
  onExceptionEndDateChange,
  isLast,
  isFirst,
  onAdd,
  onRemove,
  priceChoices,
  priceChoice,
  onPriceChoiceChange,
}) => {
  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      onStartTimeChange(timeValue);
    } else {
      onEndTimeChange(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      onStartTimeChange(time);
    } else {
      onEndTimeChange(time);
    }
  };

  return (
    <>
      <div className="time-batch-wrapper">
        <div className="time-batch-core-wrapper">
          <input
            className="time-batch-input text-m"
            type="time"
            value={startTime}
            onBlur={(e) => handleTimeBlur("start", e)}
            onChange={(e) => handleTimeChange("start", e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          <input
            className="time-batch-input text-m"
            type="time"
            value={endTime}
            onBlur={(e) => handleTimeBlur("end", e)}
            onChange={(e) => handleTimeChange("end", e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />

          {weekdays.map((day) => (
            <div key={day.name} className="checkbox-container">
              <input
                className="time-batch-checkbox text-m"
                type="checkbox"
                checked={checkedDays[day.name]}
                onChange={() => onDayChange(day.name)}
              />
              <label
                htmlFor={`day-checkbox-${day.name}`}
                className="time-batch-check-label"
              >
                <span className="text-m">{day.abbreviation}</span>
              </label>
            </div>
          ))}
        </div>
        <div className="add-remove-wrapper">
          {isLast && (
            <img
              src={plus}
              className="pointer"
              onClick={onAdd}
              alt="Add new time batch"
            />
          )}
          {!isFirst && (
            <img
              src={remove}
              className="pointer"
              onClick={onRemove}
              alt="Remove this time batch"
            />
          )}
        </div>

        {isException &&
          exceptionEndDate &&
          exceptionStartDate &&
          onExceptionEndDateChange &&
          onExceptionStartDateChange &&
          onReoccurringChange && (
            <div className="exception-date-toggle-wrapper">
              <div className="exception-date-pickers-wrapper">
                <WeekDaySelector
                  onChange={(e) => onExceptionStartDateChange(e)}
                  value={exceptionStartDate}
                  includeWeekdays={false}
                  isSmall={true}
                />
                <WeekDaySelector
                  onChange={(e) => onExceptionEndDateChange(e)}
                  value={exceptionEndDate}
                  includeWeekdays={false}
                  isSmall={true}
                />
              </div>
            </div>
          )}
      </div>
      {priceChoice && onPriceChoiceChange && priceChoices && (
        <div className="time-batch-price-choice-wrapper">
          <span className="text-m">Pris för interval:</span>
          <div className={`price-wrapper ${priceChoice}`}></div>
          <select
            className="text-m"
            value={priceChoice}
            onChange={(e) => {
              const selectedPrice = priceChoices.find(
                (price) => price.color === e.target.value
              );
              if (selectedPrice) {
                onPriceChoiceChange(selectedPrice.color);
              }
            }}
          >
            {priceChoices.map((price, index) => {
              const color = getSwedishColor(price.color);
              return (
                <option key={index} value={price.color}>
                  {color}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </>
  );
};

export default TimeInputBatch;
