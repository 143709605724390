import { useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICoach } from "../../../types/api";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { capitalizeWords } from "../../../shared/utility";
import { deleteCoach } from "../../../api/coaches/coaches";
import { toast } from "react-toastify";

interface IEditCoachPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  coach?: ICoach;
}

function EditCoachPopup(props: IEditCoachPopupProps) {
  const { coach } = props;
  const [showHandleDeleteCoachPopup, setShowHandleDeleteCoachPopup] =
    useState(false);

  const handleClose = () => {
    props.onClose();
  };

  const handleEditCoach = async () => {};
  const handleDeleteCoach = async () => {
    if (coach === undefined) return;

    try {
      const response = await deleteCoach(coach.coach_id);
      if (response && "message" in response === false) {
        props.onConfirm();
        handleClose();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  if (!coach) return null;
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-m">
          Redigera tränare {capitalizeWords(coach.first_name)}{" "}
          {capitalizeWords(coach.last_name)} {" ("+ coach.coach_id + ")"}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleEditCoach();
          }}
        >
          <div className="general-popup-bottom-buttons-wrapper">
            <button
              className="deleteButton text-m"
              type="button"
              onClick={() => setShowHandleDeleteCoachPopup(true)}
            >
              Radera tränare permanent
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteCoachPopup}
        onClose={() => setShowHandleDeleteCoachPopup(false)}
        onConfirm={() => handleDeleteCoach()}
        questionText={`Är du säker på att du vill radera ${coach.first_name} (${coach.coach_id})?`}
        confirmText="Radera tränare permanent"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditCoachPopup;
