import "./RequestPasswordChangeCode.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseAuthLayout from "../../hoc/BaseAuthLayout/BaseAuthLayout";
import memberSvg from "../../assets/images/member.svg";
import { toast } from "react-toastify";
import { initiatePasswordReset } from "../../api/auth/auth";
interface RequestPasswordChangeCodeProps {}

const RequestPasswordChangeCode = (props: RequestPasswordChangeCodeProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleInitiatePasswordChange = async (e: any) => {
    e.preventDefault();

    try {
      const initiatePasswordResetResponse = await initiatePasswordReset(email);
      if (initiatePasswordResetResponse) {
        toast.success(
          "Kontrollera din e-post för att återställa ditt lösenord"
        );
        navigate("/password-change", { state: { email: email } });
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };
  return (
    <BaseAuthLayout header="Skriv in  din e-post för återställning">
      <div className="login-wrapper">
        <form className="login-form" onSubmit={handleInitiatePasswordChange}>
          <div className="line-divider marg-30"></div>
          <div className="general-auth-input-wrapper">
            <div className="general-label-input">
              <label htmlFor="username" className="input-label">
                <img alt="member-icon" src={memberSvg} />
                <span className="text-s secondary-text">
                  Användarnamn (E-post)
                </span>
              </label>
              <input
                type="string"
                placeholder=""
                id="username"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="line-divider marg-30"></div>
          <div className="login-bottom-wrapper">
            <div className="text-s">
              Tillbaka till <a onClick={navigateToLogin}> Login </a>
            </div>
            <button type="submit" className="wide text-m">
              Begär kod
            </button>
          </div>
        </form>
      </div>
    </BaseAuthLayout>
  );
};

export default RequestPasswordChangeCode;
