import React, { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICourt, ISlotHistory, ISummaryItem } from "../../../types/api";
import { getSlotHistory } from "../../../api/slotHistory/slotHistory";
import { formatDate, formatToIsoDate } from "../../../shared/dateUtils";
import { formatDateToSwedish } from "../../../shared/utility";
import "./SlotHistoryPopup.scss";
import { toast } from "react-toastify";

interface ISlotHistoryPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  slot?: ISummaryItem;
  court: ICourt;
  filterDate: Date;
}

function SlotHistoryPopup({
  showPopup,
  onClose,
  slot,
  court,
  filterDate,
}: ISlotHistoryPopupProps) {
  const [slotHistory, setSlotHistory] = useState<ISlotHistory>();
  const popupDateString = formatDate(filterDate, "yyyy-MM-dd");
  const fetchSlotHistory = async () => {
    const dateString =
      formatDate(filterDate, "yyyy-MM-dd") + "T" + slot?.start_time;

    try {
      const response = await getSlotHistory(court.court_id, dateString);
      setSlotHistory(response);
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    if (showPopup) {
      fetchSlotHistory();
    }
  }, [showPopup]);

  // Function to group events by the 'created_at' timestamp
  const groupEventsByCreatedAt = (historyArray: ISlotHistory["history"]) => {
    return historyArray.reduce((acc: Record<string, any[]>, current) => {
      const dateString = current.created_at.replace(/(:\d{2})$/, "");
      if (!acc[dateString]) {
        acc[dateString] = [];
      }
      acc[dateString].push(current);
      return acc;
    }, {});
  };

  const groupedHistory = slotHistory
    ? groupEventsByCreatedAt(slotHistory.history)
    : {};
  return (
    <Popup
      showPopup={showPopup}
      onClose={onClose}
      leftTopElement={
        <span className="text-m">
          Historik för tid: {slot?.start_time.slice(0, 5)} på bana {court?.name}
          {" - "}
          {popupDateString}
        </span>
      }
    >
      <div className="slot-history-wrapper text-m">
        {Object.keys(groupedHistory).length === 0 && (
          <div className="slot-history-item">Inga event har hänt</div>
        )}
        {Object.entries(groupedHistory).map(([createdAt, events], index) => {
          const { weekday, dateAndMonth, hour, seconds } = formatDateToSwedish(
            new Date(createdAt)
          );
          const hourString = hour.split(".").join(":");

          return (
            <div key={index} className="slot-history-item">
              <div className="slot-history-blob"> </div>
              <div>
                {weekday}, {dateAndMonth} - kl. {hourString}:{seconds}
              </div>
              <div className="event-split-wrapper">
                {events.map((event, eventIndex) => (
                  <div key={eventIndex} className="event-split-item">
                    {event.event}
                  </div>
                ))}
              </div>
              <div>Gjordes av: {events[0].created_by}</div>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}

export default SlotHistoryPopup;
