import "./EditAdminPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import { capitalizeWords } from "../../../shared/utility";
import { TitleEnum } from "../../../shared/enums";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { deleteAdmin, editAdmin } from "../../../api/admin/admin";
import { IAdmin } from "../../../types/api";
import { CognitoUser } from "../../../types/cognito";

interface IeditAdminPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  admin?: IAdmin;
  user: CognitoUser | null;
}
interface AdminData {
  first_name: string;
  last_name: string;
  email: string;
  [key: string]: string | boolean;
}

const initialAdminData: IAdmin = {
  member_id: "",
  first_name: "",
  last_name: "",
  email: "",
};

function EditAdminPopup(props: IeditAdminPopupProps) {
  const { admin, user, showPopup, onClose, onConfirm } = props;

  const [showHandleDeleteAdminPopup, setShowHandleDeleteAdminPopup] =
    useState(false);
  const [adminData, setAdminData] = useState<IAdmin>(initialAdminData);
  const [originalData, setOriginalData] = useState<IAdmin>(initialAdminData);

  useEffect(() => {
    if (admin) {
      setAdminData(admin);
      setOriginalData(admin);
    }
  }, [admin, showPopup]);

  useEffect(() => {
    const isDataChanged = () => {
      return Object.keys(originalData).some(
        (key) => adminData[key] !== originalData[key]
      );
    };

    setHasAdminDataChanged(isDataChanged());
  }, [adminData]);

  const handleDeleteAdmin = async (member_id: string) => {
    try {
      const deleteAdminResponse = await deleteAdmin(member_id);
      if (!deleteAdminResponse) return;

      toast.success(`Admin: ${member_id} borttagen`);
      onConfirm();
      handleClose();
    } catch (error: any) {
      const errorMessage =
        error.message || "An unexpected error occurred. Please try again.";
      toast.error(`Error deleting admin: ${errorMessage}`);
    }
  };
  const [hasAdminDataChanged, setHasAdminDataChanged] = useState(false);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const isInput = (element: EventTarget): element is HTMLInputElement =>
      element instanceof HTMLInputElement;

    const { name, value } = e.target;

    let inputValue: string | boolean = value;
    if (isInput(e.target) && e.target.type === "checkbox") {
      inputValue = e.target.checked;
    }
    switch (name) {
      case "first_name":
        break;
      case "last_name":
        break;
      case "email":
        inputValue = value.toLowerCase();
        break;
      default:
        break;
    }

    setAdminData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  //

  const handleClose = () => {
    setAdminData(initialAdminData);
    onClose();
  };
  const handleEditAdmin = async () => {
    try {
      const adminToEdit = {
        first_name: adminData.first_name,
        last_name: adminData.last_name,
        email: adminData.email,
      };
      const old_email = originalData.email;
      const member_id = adminData.member_id;

      const response = await editAdmin(member_id, adminToEdit, old_email);

      if (response && !("message" in response)) {
        toast.success(`Admin uppdaterad: ${adminToEdit.first_name}`);
        handleClose();
        onConfirm();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  if (admin === undefined) return null;

  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Redigera {admin.first_name + " " + admin.member_id}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleEditAdmin();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="firstName" className="text-m">
                Förnamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="firstName"
                name="first_name"
                required
                placeholder="Förnamn"
                value={capitalizeWords(adminData.first_name)}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="lastName" className="text-m">
                Efternamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="lastName"
                required
                name="last_name"
                placeholder="Efternamn"
                value={capitalizeWords(adminData.last_name)}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="email" className="text-m">
                E-postadress*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                name="email"
                id="email"
                required
                placeholder="Ange E-postadress"
                value={adminData.email}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper">
            {user !== null &&
              user?.attributes["custom:member_id"] !== admin?.member_id && (
                <button
                  className="deleteButton text-m"
                  type="button"
                  onClick={() => setShowHandleDeleteAdminPopup(true)}
                >
                  Radera admin permanent
                </button>
              )}

            <button
              type="submit"
              className="text-m"
              disabled={!hasAdminDataChanged}
            >
              Redigera admin
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteAdminPopup}
        onClose={() => setShowHandleDeleteAdminPopup(false)}
        onConfirm={() => handleDeleteAdmin(admin?.member_id || "")}
        questionText={`Är du säker på att radera ${admin?.member_id}?`}
        confirmText="Radera admin"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditAdminPopup;
