import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { IActivityInput } from "../../types/api";
import {
  adminCheckInActivityBookingQueryFn,
  adminReportActivityNoShowQueryFn,
  bookActivityQueryFn,
  cancelActivityQueryFn,
  cancelOneActivitySlotQueryFn,
  createActivityQueryFn,
  getActivityByNameAndTimeQueryFn,
  listActivitiesQueryFn,
  listUppcommingActivitiesQueryFn,
  removeOneCourtFromActivityQueryFn,
  unbookActivityQueryFn,
} from "./activityQueries";
import { StatusEnum } from "../../shared/enums";

export const createActivity = async (
  activityToCreate: IActivityInput,
  save_as_template: boolean
) => {
  const { query, inputs, variables } = createActivityQueryFn(
    activityToCreate,
    save_as_template
  );

  const myQuery = `
        mutation createActivity(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCreateResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createActivity;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const listUppcommingActivities = async () => {
  const { query } = listUppcommingActivitiesQueryFn();
  const myQuery = `
        query listUpcomingActivities {
            ${query}
        }
    `;
  try {
    const listActivitiesResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = listActivitiesResponse.data.listUpcomingActivities;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const listActivities = async () => {
  const { query } = listActivitiesQueryFn();
  const myQuery = `
            query listActivities {
                ${query}
            }
        `;
  try {
    const listActivitiesResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = listActivitiesResponse.data.listActivities;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const cancelActivity = async (activity: IActivityInput) => {
  const { query, inputs, variables } = cancelActivityQueryFn(activity);
  const myQuery = `
        mutation cancelActivity(${inputs}) {
            ${query}
        }
    `;
  try {
    const cancelActivityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = cancelActivityResponse.data.cancelActivity;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const bookActivity = async (
  member_id: string,
  court_id: string,
  datetime_start: string,
  requestor: "admin" | "user"
) => {
  const { query, inputs, variables } = bookActivityQueryFn(
    member_id,
    court_id,
    datetime_start,
    requestor
  );
  const myQuery = `
        mutation bookActivity(${inputs}) {
            ${query}
        }
    `;
  try {
    const bookActivityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = bookActivityResponse.data.bookActivity;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const unbookActivity = async (
  court_id: string,
  datetime_start: string,
  member_id: string,
  requestor: "admin" | "user"
) => {
  const { query, inputs, variables } = unbookActivityQueryFn(
    court_id,
    datetime_start,
    member_id,
    requestor
  );
  const myQuery = `
      mutation unbookActivity(${inputs}) {
          ${query}
      }
  `;
  try {
    const unbookActivityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = unbookActivityResponse.data.unbookActivity;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const adminCheckInActivityBooking = async (
  member_id: string,
  datetime_start: string,
  court_id: string,
  status: StatusEnum
) => {
  const { query, inputs, variables } = adminCheckInActivityBookingQueryFn(
    member_id,
    datetime_start,
    court_id,
    status
  );
  const myQuery = `
    mutation adminCheckInActivityBooking(${inputs}) {
      ${query}
    }
  `;
  try {
    const adminCheckInActivityBookingResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response =
      adminCheckInActivityBookingResponse.data.AdminCheckInActivityBooking;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const AdminReportActivityNoshow = async (
  member_id: string,
  datetime_start: string,
  court_id: string
) => {
  const { query, inputs, variables } = adminReportActivityNoShowQueryFn(
    member_id,
    datetime_start,
    court_id
  );
  const myQuery = `
    mutation adminReportActivityNoShow(${inputs}) {
      ${query}
    }
  `;
  try {
    const adminReportActivityNoShowResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response =
      adminReportActivityNoShowResponse.data.AdminReportActivityNoshow;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const cancelOneActivitySlot = async (
  input: IActivityInput,
  datetime_to_remove: string
) => {
  const { query, inputs, variables } = cancelOneActivitySlotQueryFn(
    input,
    datetime_to_remove
  );
  const myQuery = `
    mutation cancelOneActivitySlot(${inputs}) {
      ${query}
    }
  `;
  try {
    const cancelOneActivitySlotResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = cancelOneActivitySlotResponse.data.cancelOneActivitySlot;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const getActivityByNameAndTime = async (
  name: string,
  start_time: string
) => {
  const { query, inputs, variables } = getActivityByNameAndTimeQueryFn(
    name,
    start_time
  );
  const myQuery = `
    query getActivityByNameAndTime(${inputs}) {
      ${query}
    }
  `;
  try {
    const getActivityByNameAndTimeResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getActivityByNameAndTimeResponse.data.getActivity;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const removeOneCourtFromActivity = async (
  activity: IActivityInput,
  court_id: string
) => {
  const { query, inputs, variables } = removeOneCourtFromActivityQueryFn(
    activity,
    court_id
  );
  const myQuery = `
    mutation removeOneCourtFromActivity(${inputs}) {
      ${query}
    }
  `;
  try {
    const removeOneCourtFromActivityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response =
      removeOneCourtFromActivityResponse.data.removeOneCourtFromActivity;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};
