import { AnimatePresence, motion, Variants } from "framer-motion";
import React, { useEffect, useRef } from "react";
import "./Popup.scss";
import "./GeneralPopup.scss";

interface IPopupProps {
  children: React.ReactNode;
  showPopup: boolean;
  onClose: () => void;
  className?: string;
  width?: string;
  leftTopElement?: React.ReactNode;
  style?: React.CSSProperties;
  loading?: boolean;
}

function Popup(props: IPopupProps) {
  useEffect(() => {
    if (props.showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.showPopup]);

  const popupClasses = ["popup"];
  if (props.className) {
    popupClasses.push(props.className);
  }

  const popupVariants: Variants = {
    closed: {
      x: 600,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  const popupWrapperVariants: Variants = {
    closed: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 0.3,
      },
    },
    open: {
      backgroundColor: "rgba(3, 2, 41, 0.4)",
      transition: {
        duration: 0.3,
      },
    },
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClose();
  };
  useEffect(() => {
    const handleOnKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };
    if (props.showPopup) {
      document.addEventListener("keydown", handleOnKeyDown);
    } else {
      document.removeEventListener("keydown", handleOnKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, [props.showPopup, props.onClose]);

  return (
    <AnimatePresence>
      {props.showPopup ? (
        <motion.div
          className="popup-wrapper"
          variants={popupWrapperVariants}
          initial="closed"
          animate={props.showPopup ? "open" : "closed"}
          exit="closed"
          onClick={handleBackdropClick}
        >
          <motion.div
            className={popupClasses.join(" ")}
            variants={popupVariants}
            initial="closed"
            animate={props.showPopup ? "open" : "closed"}
            exit="closed"
            style={{ ...props.style, width: props.width }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="top">
              {props.leftTopElement || null}
              <div className="close-button-wrapper">
                {props.loading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <button
                    className="close-button waves-effect"
                    onClick={props.onClose}
                  >
                    <i className="fa-regular fa-xmark" />
                  </button>
                )}
              </div>
            </div>
            {props.children}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default Popup;
