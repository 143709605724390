import { ICourt, ICourtToCreate, ICourtToEdit } from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { createCourtQueryFn, deleteCourtQueryFn, editCourtQueryFn, listCourtsQueryFn } from "./courtsQueries";


export const listCourts = async (): Promise<ICourt[]> => {
  const {query} = listCourtsQueryFn();
  const myQuery = `
    query MyQuery {
      ${query}
    }
  `;
  try {
    await Auth.currentSession();

    const listCourtsResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = listCourtsResponse.data.listCourts;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createCourt = async (courtToCreate: ICourtToCreate) => {
  const {query, inputs, variables} = createCourtQueryFn(courtToCreate);

  const myQuery = `mutation myMutation(${inputs}) {
    ${query}
  }`;

  try {
    const getCreateResponse = (await API.graphql({
      variables: variables,
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createCourt;
    if ("message" in response) {
      console.error("Error creating court:", response.message);
      toast.error(`Error creating court: ${response.message}`);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
}

export const editCourt = async (courtToEdit:ICourtToEdit) => {
  const {query, inputs, variables} = editCourtQueryFn(courtToEdit);

  const myQuery = `mutation myMutation(${inputs}) {
    ${query}
  }`;
  
  try {
    const getEditResponse = (await API.graphql({
      variables: variables,
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getEditResponse.data.updateCourt;
 
    if ("message" in response) {
      
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteCourt = async (courtId: string) => {
  const {query, variables, inputs} = deleteCourtQueryFn(courtId);
  const myQuery = `
    mutation myMutation(${inputs}) {
      ${query}
    }
  `;
  try {
    const getDeleteResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getDeleteResponse.data.deleteCourt;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
}