import React from "react";
import { toast } from "react-toastify";
import "./CoachAvailabilityBatch.scss";
import plus from "../../assets/images/plus.svg";
import remove from "../../assets/images/remove.svg";
import WeekDaySelector from "../DatePicker/Datepicker";
import { on } from "events";
import { IPrices } from "../../types/api";
import { getSwedishColor } from "../../shared/utility";

interface ICoachAvailabilityBatchProps {
  startTime: string;
  endTime: string;
  price: number;
  date: Date;
  onDateChange: (date: Date) => void;
  onStartTimeChange: (value: string) => void;
  onEndTimeChange: (value: string) => void;
  onAdd: () => void;
  onRemove: () => void;
  isFirst: boolean;
}

function CoachAvailabilityBatch(props: ICoachAvailabilityBatchProps) {
  const {
    startTime,
    endTime,
    date,
    onStartTimeChange,
    onEndTimeChange,
    onAdd,
    onRemove,
    onDateChange,
  } = props;

  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      onStartTimeChange(timeValue);
    } else {
      onEndTimeChange(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      onStartTimeChange(time);
    } else {
      onEndTimeChange(time);
    }
  };

  return (
    <>
      <div className="availability-batch-wrapper">
        <div className="availability-batch-core-wrapper">
          <WeekDaySelector
            textM
            value={date}
            onChange={onDateChange}
            includeWeekdays={false}
          />
          <input
            className="availability-batch-input text-m"
            type="time"
            value={startTime}
            onBlur={(e) => handleTimeBlur("start", e)}
            onChange={(e) => handleTimeChange("start", e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          <input
            className="availability-batch-input  text-m"
            type="time"
            value={endTime}
            onBlur={(e) => handleTimeBlur("end", e)}
            onChange={(e) => handleTimeChange("end", e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
        </div>

        <div className="add-remove-wrapper">
          <img
            src={plus}
            className="pointer"
            onClick={onAdd}
            alt="Add new time batch"
          />
          {!props.isFirst && (
            <img
              src={remove}
              className="pointer"
              onClick={onRemove}
              alt="Remove this time batch"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CoachAvailabilityBatch;
