import { useEffect } from "react";


const useBeforeUnload = (
  message: string,
  shouldWarn: boolean,
  onUnload: () => void,
) => {
  useEffect(() => {
    onUnload();
    const handleBeforeUnload = (event: Event) => {
      if (shouldWarn) {
         
        event.preventDefault();
       
      }
    };

    const handleUserStay = () => {
      if (shouldWarn) {
        cleanup();
      }
    };

    const cleanup = () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("focus", handleUserStay);
    };

    if (shouldWarn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("focus", handleUserStay);
    }

    return cleanup;
  }, [message, shouldWarn, onUnload]);
};

export default useBeforeUnload;
