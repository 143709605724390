import { IDailyComment } from "../../types/api";

export const getDailyCommentQueryFn = (date: string) => {
  const query = `
        getDailyComment(date: $date) {
            ... on DailyComment {
                __typename
                date
                comment
            }
            ... on Error {
                code
                message
            }
       }
    `;
  const inputs = `$date: String!`;
  const variables = { date: date };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateDailyCommentQueryFn = (date: string, comment: string) => {
  const query = `
            updateDailyComment(input: {date: $date, comment: $comment}) {
                ... on DailyComment {
                    __typename
                    date
                    comment
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$date: String!, $comment: String!`;
  const variables = { date: date, comment: comment };
  return {
    query,
    inputs,
    variables,
  };
};

export const createDailyCommentQueryFn = (date: string, comment: string) => {
  const query = `
            createDailyComment(input: {date: $date, comment: $comment}) {
                ... on DailyComment {
                    __typename
                    date
                    comment
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$date: String!, $comment: String!`;
  const variables = { date: date, comment: comment };
  return {
    query,
    inputs,
    variables,
  };
};
