import {
  IPermissionInput,
  IPermissionToEdit,
} from "../../types/api";

export const listPermissionsQueryFn = () => {
  const query = `
    listPermissions {
            ... on Permission {
              permission_id
              gym_access
              member_type
              ranking
              title
              payment_card
              hidden
              max_amount_of_bookings
              percent_discount_outside_access
              extra_booking_time
              lesson_access
              indoor_access {
                  weekday
                  start_time
                  end_time
              }
              outdoor_access {
                  weekday
                  start_time
                  end_time
              }
        }
      ... on Error {
        message
        code
      }
    }
    `;
  return {
    query,
  };
};

export const getPermissionByMemberTypeQueryFn = (permissionId: string) => {
  const query = `getPermissionByMemberType(permission_id: $permission_id) {
        ... on Permission {
            gym_access
            member_type
            ranking
            title
            payment_card
            hidden
            max_amount_of_bookings
            percent_discount_outside_access
            extra_booking_time
            lesson_access
            indoor_access {
                weekday
                start_time
                end_time
            }
            outdoor_access {
                weekday
                start_time
                end_time
            }
      }
      ... on Error {
        message
        code
      }
    }`;
  const inputs = `$permission_id: String!`;
  const variables = { permission_id: permissionId };
  return {
    query,
    inputs,
    variables,
  };
};

export const createPermissionQueryFn = (
  permissionToCreate: IPermissionInput
) => {
  const query = `createPermission(input: $input) {
        ... on Permission {
            member_type
      }
      ... on Error {
        message
        code
      }
      }`;
  const inputs = `$input: PermissionInput!`;
  const variables = { input: permissionToCreate };
  return {
    query,
    inputs,
    variables,
  };
};

export const editPermissionQueryFn = (
  permissionId: string,
  permissionToEdit: Partial<IPermissionToEdit>
) => {
  const query = `updatePermission(input_data: $input_data, permission_id: $permission_id) {
        ... on Permission {
            member_type
      }
      ... on Error {
        message
        code
      }
      }`;
  const inputs = `$input_data: UpdatePermissionInput!, $permission_id: String!`;
  const variables = { input_data: permissionToEdit, permission_id: permissionId };
  return {
    query,
    inputs,
    variables,
  };
};

export const deletePermissionQueryFn = (permissionId: string) => {
  const query = `deletePermission(permission_id: $permission_id) {
        ... on Permission {
            member_type
      }
      ... on Error {
        message
        code
      }
      }`;
  const inputs = `$permission_id: String!`;
  const variables = { permission_id: permissionId };
  return {
    query,
    inputs,
    variables,
  };
};
