import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import BaseAuthLayout from "../../hoc/BaseAuthLayout/BaseAuthLayout";
import "./Login.scss";
import memberSvg from "../../assets/images/member.svg";
import passLock from "../../assets/images/pass-lock.svg";
import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";
import queryString from "query-string";

interface LoginProps {
  authenticate: () => void;
  isLoading: boolean;
}

function Login(props: LoginProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const urlUsername = queryParams.username as string;
  const urlTempPassword = queryParams.tempPassword as string;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const signIn = async (e: any) => {
    e.preventDefault();

    try {
      toast.loading("Loggar in...");
      const usernameAllSmall = username.toLowerCase();
      const user = await Auth.signIn(usernameAllSmall, password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        toast.dismiss();
        navigate(`/force-change-password`, { state: { email: user.username } });
      } else {
        props.authenticate();
        if (!props.isLoading) {
          toast.dismiss();
          navigate("/");
        }
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  useState(() => {
    if (urlUsername && urlTempPassword) {
      setUsername(urlUsername);
      setPassword(urlTempPassword);
    }
  });

  function resetPassword() {
    navigate("/reset-password");
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <BaseAuthLayout header="Logga in">
      <div className="login-wrapper">
        <form className="login-form" onSubmit={signIn}>
          <div className="line-divider marg-30"></div>
          <div className="general-auth-input-wrapper">
            <div className="general-label-input">
              <label htmlFor="username" className="input-label">
                <img alt="member-icon" src={memberSvg} />
                <span className="text-s secondary-text">
                  Användarnamn (E-post)
                </span>
              </label>
              <input
                type="string"
                placeholder=""
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="general-label-input">
              <label htmlFor="password" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">Lösenord*</span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder=""
                  autoComplete="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  alt="toggle password visibility"
                  src={passwordVisible ? eyeSlash : eye}
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>
          </div>
          <div className="line-divider marg-30"></div>
          <div className="login-bottom-wrapper">
            <div className="text-s">
              Har du glömt ditt lösenord?
              <a onClick={resetPassword}> Återställ lösenord</a>
            </div>
            <button type="submit" className="wide text-m">
              Logga In
            </button>
          </div>
        </form>
      </div>
    </BaseAuthLayout>
  );
}

export default Login;
