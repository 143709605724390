import "./EditActivityPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  IActivity,
  IActivityInput,
  ICoach,
  ICourt,
  IPermission,
} from "../../../types/api";
import { toast } from "react-toastify";
import { TActivityColor } from "../../../types/internal";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { cancelActivity } from "../../../api/activities/activities";
import { formatToIsoDate } from "../../../shared/dateUtils";

interface IEditActivityPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  activity?: IActivity;
  courts: ICourt[];
  permissions: IPermission[];
  coachesList: ICoach[];
}

function EditActivityPopup(props: IEditActivityPopupProps) {
  const [cancelActivityLoading, setCancelActivityLoading] = useState(false);
  const [
    showHandleConfirmCancelActivityPopup,
    setShowHandleConfirmCancelActivityPopup,
  ] = useState(false);

  const { courts, permissions } = props;
  const [selectedCourts, setSelectedCourts] = useState<{
    [key: string]: boolean;
  }>({});
  const selectedCourtsLength = Object.values(selectedCourts).filter(
    (value) => value
  ).length;
  const [selectedCoaches, setSelectedCoaches] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedPermissions, setSelectedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const selectedPermissionsLength = Object.values(selectedPermissions).filter(
    (value) => value
  ).length;

  const rankingList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const [ranking_min, setRanking_min] = useState(0);
  const [ranking_max, setRanking_max] = useState(0);

  const [selectedRankings, setSelectedRankings] = useState<{
    [key: number]: boolean;
  }>({});
  const [isVisisble, setIsVisible] = useState(true);
  const [rankingOption, setRankingOption] = useState("all");
  const [courtOption, setCourtOption] = useState("all");
  const [coachOption, setCoachOption] = useState("Nej");
  const [permissionOption, setPermissionOption] = useState("all");
  const [activityName, setActivityName] = useState("");
  const [participants, setParticipants] = useState(0);
  const [createdAtString, setCreatedAtString] = useState("");
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [price, setPrice] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [memberTypes, setMemberTypes] = useState([]);
  const [comment, setComment] = useState("");
  const [historyComments, setHistoryComments] = useState<string[]>([]);
  const [activityColor, setActivityColor] =
    useState<TActivityColor>("activity-color-1");

  useEffect(() => {
    if (props.activity) {
      setActivityName(props.activity.name);
      setParticipants(props.activity.participants_limit);
      setStartTime(props.activity.datetime_start.slice(11, 19));
      setEndTime(props.activity.datetime_end.slice(11, 19));
      setStartDate(new Date(props.activity.datetime_start));
      setEndDate(new Date(props.activity.datetime_end));
      const commentHistory = props.activity.comment.split("\n").slice(1);
      setHistoryComments(commentHistory);
      const comment = props.activity.comment.split("\n")[0];
      setComment(comment);
      setPrice(props.activity.price);
      setHidden(props.activity.hidden);
      setActivityColor(props.activity.color as TActivityColor);
      setSelectedCourts(
        props.activity.court_id.reduce((acc, courtId) => {
          acc[courtId] = true;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setSelectedPermissions(
        props.activity.members_with_access.reduce((acc, permissionId) => {
          acc[permissionId] = true;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setRanking_min(props.activity.ranking_min);
      setRanking_max(props.activity.ranking_max);
      if (
        props.activity.created_at !== undefined &&
        props.activity.created_at !== null
      ) {
        const created_at = props.activity.created_at;
        setCreatedAtString(
          created_at.slice(0, 10) + " " + created_at.slice(11, 19)
        );
      } else {
        setCreatedAtString("-");
      }
    }
  }, [props.activity]);
  useEffect(() => {
    if (startDate < new Date()) {
      setStartDate(new Date());
    }
    if (endDate < new Date()) {
      setEndDate(new Date());
    }
  }, [startDate, endDate]);

  // const handleTimeChange = (
  //   timeType: "start" | "end",
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const timeValue = e.target.value;
  //   if (timeType === "start") {
  //     setStartTime(timeValue);
  //   } else {
  //     setEndTime(timeValue);
  //   }
  // };

  // const handleTimeBlur = (
  //   timeType: "start" | "end",
  //   e: React.FocusEvent<HTMLInputElement>
  // ) => {
  //   let time = e.target.value;
  //   let parts = time.split(":");

  //   if (parts.length === 2) {
  //     time += ":00";
  //   } else if (parts.length === 1 && time.includes(":")) {
  //     time += "00:00";
  //   } else if (parts.length === 1) {
  //     time = time.padStart(2, "0") + ":00:00";
  //   }

  //   if (timeType === "start") {
  //     setStartTime(time);
  //   } else {
  //     setEndTime(time);
  //   }
  // };

  // const handleInputChange = (e: any) => {
  //   const { name, value } = e.target;
  //   switch (name) {
  //     case "activityName":
  //       setActivityName(value);
  //       break;
  //     case "memberTypes":
  //       setMemberTypes(value);
  //       break;
  //     case "minRanking":
  //       setMinRanking(value);
  //       break;
  //     case "maxRanking":
  //       setMaxRanking(value);
  //       break;
  //     case "visibility":
  //       setIsVisible(!isVisisble);
  //       break;
  //     case "participants":
  //       setParticipants(value);
  //       break;
  //     case "comment":
  //       setComment(value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleCancelActivity = async () => {
    if (!props.activity) {
      return;
    }

    const activityToCancel: IActivityInput = {
      name: props.activity.name,
      datetime_start: props.activity.datetime_start,
      datetime_end: props.activity.datetime_end,
      participants: props.activity.participants,
      participants_limit: props.activity.participants_limit,
      ranking_min: props.activity.ranking_min,
      ranking_max: props.activity.ranking_max,
      color: props.activity.color,
      comment: props.activity.comment,
      hidden: props.activity.hidden,
      court_id: props.activity.court_id,
      coach_id: props.activity.coach_id,
      members_with_access: props.activity.members_with_access,
      price: props.activity.price,
    };

    try {
      setCancelActivityLoading(true);
      const response = await cancelActivity(activityToCancel);
      if (response && !("message" in response)) {
        toast.success("Aktiviteten har avbokats");
        props.onConfirm();
        props.onClose();
      }
      setCancelActivityLoading(false);
    } catch (error: any) {
      toast.error(error);
      setCancelActivityLoading(false);
    }
  };

  // const toggleCourtSelection = (courtId: string) => {
  //   setSelectedCourts((prev) => ({
  //     ...prev,
  //     [courtId]: !prev[courtId],
  //   }));
  // };

  // const toggleCoachSelection = (coachId: string) => {
  //   setSelectedCoaches((prev) => ({
  //     ...prev,
  //     [coachId]: !prev[coachId],
  //   }));
  // };

  // const togglePermissionSelection = (permissionId: string) => {
  //   setSelectedPermissions((prev) => ({
  //     ...prev,
  //     [permissionId]: !prev[permissionId],
  //   }));
  // };
  // const handleColorChange = (color: TActivityColor) => {
  //   setActivityColor(color);
  // };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-m">Redigera aktivitet</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Namn på aktivitet</span>
                <span className={`text-s activity-name `}>
                  {activityName.split("%-%")[0]} ({hidden ? "Dold" : "Synlig"})
                  <div className={`activity-name-blob ${activityColor}`}></div>
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Bana</span>
                <span className="text-s">
                  {courtOption === "all" &&
                  selectedCourtsLength === courts.length
                    ? "Alla banor"
                    : courts
                        .filter((court) => {
                          return selectedCourts[court.court_id];
                        })
                        .map((court, index) => {
                          if (index === 0) {
                            return court.name;
                          }
                          return ", " + court.name;
                        })}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Datum</span>
                <span className="text-s">
                  {formatToIsoDate(startDate).slice(8, 10)} /{" "}
                  {formatToIsoDate(startDate).slice(5, 7)} -{" "}
                  {formatToIsoDate(endDate).slice(8, 10)} /{" "}
                  {formatToIsoDate(endDate).slice(5, 7)}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Tid</span>
                <span className="text-s">
                  {startTime.slice(0, 5)} - {endTime.slice(0, 5)}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Medlemstyper</span>
                <span className="text-s">
                  {permissionOption === "all" &&
                  permissions.length === selectedPermissionsLength
                    ? "Alla medlemstyper"
                    : selectedPermissionsLength === 0
                    ? "Inga medlemstyper"
                    : permissions
                        .filter((permission) => {
                          return selectedPermissions[permission.permission_id];
                        })
                        .map((permission, index) => {
                          if (index === 0) {
                            return permission.member_type;
                          }
                          return ", " + permission.member_type;
                        })}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Ranking</span>
                <span className="text-s">
                  {ranking_min === -1
                    ? "Inga ranking specifikationer"
                    : "Min: " + ranking_min + " Max: " + ranking_max}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Antal platser</span>
                <span className="text-s"> {participants}</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Pris</span>
                <span className="text-s"> {price} kr</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Kommentar</span>
                <span className="text-s"> {comment}</span>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m no-wrap">Historiska händelser</span>
                <span className="text-s general-popup-flex-between flex-down">
                  {historyComments.map((comment, index) => (
                    <span key={index}>{comment}</span>
                  ))}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Skapad:</span>
                <span className="text-s"> {createdAtString}</span>
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper">
            <button
              className="deleteButton text-m"
              type="button"
              onClick={() => setShowHandleConfirmCancelActivityPopup(true)}
            >
              Avbryt aktivitet
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleConfirmCancelActivityPopup}
        onClose={() => setShowHandleConfirmCancelActivityPopup(false)}
        onConfirm={() => handleCancelActivity()}
        questionText={`Är du säker på att du vill avbryta aktiviteten?`}
        confirmText="Avbryt aktivitet"
        denyText="Nej"
        loading={cancelActivityLoading}
      />
    </Popup>
  );
}

export default EditActivityPopup;
