import { useEffect, useState } from "react";
import "./AdminHandleAdmins.scss";
import { IAdmin, IGraphError, IPermission, IUser } from "../../types/api";

import CreateUserPopup from "../../containers/Users/CreateUserPopup/CreateUserPopup";
import { listPermissions } from "../../api/permissions/permissions";
import EditUserPopup from "../../containers/Users/EditUserPopup/EditUserPopup";
import { capFirstAndRemoveDash, capitalizeWords } from "../../shared/utility";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import CreateAdminPopup from "../../containers/Admin/CreateAdminPopup/CreateAdminPopup";
import EditAdminPopup from "../../containers/Admin/EditAdminPopup/EditAdminPopup";
import { CognitoUser } from "../../types/cognito";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

interface IAdminHandleAdminsProps {}

function AdminHandleAdmins(props: IAdminHandleAdminsProps) {
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [searchedAdmins, setSearchedAdmins] = useState<IAdmin[]>([]);
  const [currentEditAdmin, setCurrentEditAdmin] = useState<IAdmin>();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [showEditAdminPopup, setShowEditAdminPopup] = useState<boolean>(false);
  const [showCreateAdminPopup, setShowCreateAdminPopup] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  const fetchChosenItems = async ({ fetchAdmins = false }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchAdmins: fetchAdmins,
      });
      if (fetchAdmins && response.admins && !("message" in response.admins)) {
        setAdmins(response.admins);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchAdmins: true,
    });
  }, []);

  const openEditAdmin = (admin: IAdmin) => {
    setCurrentEditAdmin(admin);
    setShowEditAdminPopup(true);
  };

  const openCreateAdmin = () => {
    setShowCreateAdminPopup(true);
  };

  const handleSearchAdmins = (e: any) => {
    setSearchString(e.target.value);
    const matchingAdmins = admins.filter((admin) => {
      return (
        admin.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        admin.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        admin.member_id.toString().includes(e.target.value)
      );
    });
    setSearchedAdmins(matchingAdmins);
  };
  const adminsToDisplay = searchString.length > 0 ? searchedAdmins : admins;

  return (
    <div className="admin-handle-admins-layout">
      <h1 className="text-l primary-text">Admins</h1>
      <div className="handle-admins-actions-wrapper">
        <div className="admins-search-wrapper">
          <input
            className="admins-search-input text-m"
            value={searchString}
            onChange={handleSearchAdmins}
            placeholder="Sök på namn & medlemsnummer"
          ></input>
        </div>
        <div className="admins-add-wrapper">
          <button
            className="admins-add-button text-m"
            onClick={() => openCreateAdmin()}
          >
            Lägg till admin
          </button>
        </div>
      </div>
      <table className="admins-table">
        <thead>
          <tr>
            <th className="text-m secondary-text">Medlemsnummer</th>
            <th className="text-m secondary-text">Förnamn</th>
            <th className="text-m secondary-text">Efternamn</th>
            <th className="text-m secondary-text">E-postadress</th>
          </tr>
        </thead>
        <tbody>
          {adminsToDisplay.map((admin, index) => {
            const firstName = capitalizeWords(admin.first_name);

            const lastName = capitalizeWords(admin.last_name);
            return (
              <tr key={index}>
                <td
                  className="text-m primary-text"
                  onClick={() => openEditAdmin(admin)}
                >
                  {admin.member_id}
                </td>

                <td
                  className="text-m primary-text"
                  onClick={() => openEditAdmin(admin)}
                >
                  {firstName}
                </td>
                <td
                  className="text-m primary-text"
                  onClick={() => openEditAdmin(admin)}
                >
                  {lastName}
                </td>
                <td
                  className="text-m primary-text"
                  onClick={() => openEditAdmin(admin)}
                >
                  {admin.email}
                  <div className="edit-component">
                    <div className="edit-dot"></div>
                    <div className="edit-dot"></div>
                    <div className="edit-dot"></div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <EditAdminPopup
        showPopup={showEditAdminPopup}
        onClose={() => setShowEditAdminPopup(false)}
        admin={currentEditAdmin}
        onConfirm={() => {
          fetchChosenItems({ fetchAdmins: true });
        }}
        user={user}
      />
      <CreateAdminPopup
        showPopup={showCreateAdminPopup}
        onClose={() => setShowCreateAdminPopup(false)}
        onConfirm={() => {
          fetchChosenItems({ fetchAdmins: true });
        }}
      />
    </div>
  );
}

export default AdminHandleAdmins;
