import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  createDailyCommentQueryFn,
  getDailyCommentQueryFn,
  updateDailyCommentQueryFn,
} from "./dailyCommentQueries";

export const createDailyComment = async (date: string, comment: string) => {
  const { query, inputs, variables } = createDailyCommentQueryFn(date, comment);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const createDailyCommentResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = createDailyCommentResponse.data.createDailyComment;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateDailyComment = async (date: string, comment: string) => {
  const { query, inputs, variables } = updateDailyCommentQueryFn(date, comment);
  const myQuery = `mutation MyMutation(${inputs}) {
                ${query}
            }`;
  try {
    await Auth.currentSession();
    const updateDailyCommentResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateDailyCommentResponse.data.updateDailyComment;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getDailyComment = async (date: string) => {
  const { query, inputs, variables } = getDailyCommentQueryFn(date);
  const myQuery = `query MyQuery(${inputs}) {
        ${query}
    }`;
  try {
    const getDailyCommentResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getDailyCommentResponse.data.getDailyComment;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};
