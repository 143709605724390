export const listPricesQueryFn = () => {
  const query = `
          listPrices {
            ... on Price {
                __typename
                color
                price
            }
            ... on Error {
                code
                message
            } 
          }
        `;
  return {
    query,
  };
};
