import { useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IPermission } from "../../../types/api";
import { toast } from "react-toastify";
import { createUser } from "../../../api/users/users";
import "./CreateCoachPopup.scss";
import { createCoach } from "../../../api/coaches/coaches";
import profile from "../../../assets/images/profile.svg";
interface ICreateCoachPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function CreateCoachPopup(props: ICreateCoachPopupProps) {
  const initialCoachData = {
    first_name: "",
    last_name: "",
    email: "",
    coach_price: 0,
    description: "",
  };

  const [coachData, setCoachData] = useState(initialCoachData);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let inputValue: string | boolean | number = value;

    switch (name) {
      case "coach_price":
        if (value.trim() === "") {
          inputValue = 0;
        } else if (value.length === 0) {
          inputValue = 0;
        } else {
          const numValue = Number(value);
          inputValue = Math.max(0, Math.min(9999, numValue));
        }
        break;
      default:
        break;
    }
    setCoachData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };
  const handleCreateCoach = async () => {
    try {
      const createCoachResponse = await createCoach(coachData);
      if (!createCoachResponse) return;

      toast.success(`Coach: ${coachData.first_name} skapad`);

      props.onClose();
      props.onConfirm();
    } catch (error: any) {
      console.error("Error creating coach:", error);

      const errorMessage =
        error.message || "An unexpected error occurred. Please try again.";
      toast.error(`Error creating coach: ${errorMessage}`);
    }
  };
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-m">Lägg till ny tränare</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateCoach();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="firstName" className="text-m">
                Förnamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="firstName"
                required
                name="first_name"
                placeholder="Förnamn"
                value={coachData.first_name}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="lastName" className="text-m">
                Efternamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="lastName"
                required
                name="last_name"
                placeholder="Efternamn"
                value={coachData.last_name}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="email" className="text-m">
                E-postadress*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="email"
                required
                name="email"
                placeholder="Ange E-postadress"
                value={coachData.email}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="coachPrice" className="text-m">
                Tränarens kostnad*
              </label>
              <div className="price-input-wrapper">
                <input
                  className="on-transparent text-m"
                  type="number"
                  required
                  name="coach_price"
                  id="coachPrice"
                  min="0"
                  max="9999"
                  placeholder="Ange pris"
                  value={coachData.coach_price.toString()}
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="kr-wrapper text-m"> kr</div>
              </div>
            </div>
            {/* <div className="general-popup-form-field">
              <label htmlFor="profilePicture" className="text-m">
                Ladda upp bild
              </label>
              <div className="profle-input-placeholder-wrapper">
                <input
                  className="on-transparent text-m"
                  type="file"
                  name="profile_picture"
                  id="profilePicture"
                  placeholder={profile}
                  onChange={(e) => handleInputChange(e)}
                />
                <img
                  className="placeholder-image"
                  src={profile}
                  alt="test"
                ></img>
              </div>
            </div> */}
            <div className="general-popup-form-field">
              <label htmlFor="description" className="text-m">
                Beskrivning
              </label>
              <input
                className="on-transparent text-m"
                id="description"
                name="description"
                placeholder="Ange beskrivning"
                value={coachData.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Lägg till tränare
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateCoachPopup;
