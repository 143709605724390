import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  getVisualArtByCourtQueryFn,
  listVisualArtBookingsQueryFn,
  updateVisualArtBookingQueryFn,
} from "./visualArtQueries";

export const getVisualArtByCourt = async (court_id: string, date: string) => {
  const { query, inputs, variables } = getVisualArtByCourtQueryFn(
    court_id,
    date
  );
  const myQuery = `query MyQuery(${inputs}) {
        ${query}
    }`;

  try {
    const getVisualArtResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getVisualArtResponse.data.getVisualArtByCourt;
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateVisualArtBooking = async (
  active: boolean,
  court_id: string,
  info: string,
  player_one_first_team: string,
  player_one_second_team: string,
  player_two_first_team: string,
  player_two_second_team: string
) => {
  const { query, inputs, variables } = updateVisualArtBookingQueryFn(
    active,
    court_id,
    info,
    player_one_first_team,
    player_one_second_team,
    player_two_first_team,
    player_two_second_team
  );

  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;

  try {
    const updateVisualArtResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateVisualArtResponse.data.updateVisualArtBooking;

    if (response !== null && "message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const listVisualArtBookings = async () => {
  const { query } = listVisualArtBookingsQueryFn();
  const myQuery = `query MyQuery {
        ${query}
    }`;

  try {
    const listVisualArtResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = listVisualArtResponse.data.listVisualArtBookings;

    if (response !== null && "message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
