import "./PasswordChange.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseAuthLayout from "../../hoc/BaseAuthLayout/BaseAuthLayout";
import passLock from "../../assets/images/pass-lock.svg";
import memberSvg from "../../assets/images/member.svg";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";
import { IPasswordChangeData } from "../../types/api";
import { confirmPasswordReset } from "../../api/auth/auth";
import { checkPassword } from "../../shared/utility";
interface PasswordChangeProps {
  authenticate: () => void;
  isLoading: boolean;
}

const PasswordChange = (props: PasswordChangeProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordString, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] =
    useState(false);

  const handleChangePassword = async (e: any) => {
    e.preventDefault();

    if (newPassword !== confirmNewPasswordString) {
      toast.error("Nya lösenord matchar inte!");
      return;
    }
    const check = checkPassword(newPassword);
    if (check.length > 0) {
      toast.error(check[0].toString());
      return;
    }
    const requestData: IPasswordChangeData = {
      email: email,
      confirmation_code: code,
      new_password: newPassword,
    };
    try {
      const newPasswordResponse = await confirmPasswordReset(requestData);
      if (newPasswordResponse) {
        toast.success(newPasswordResponse);
        props.authenticate();
        navigate("/login");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <BaseAuthLayout header="Välj ditt lösenord">
      <div className="login-wrapper">
        <form className="login-form" onSubmit={handleChangePassword}>
          <div className="line-divider marg-30"></div>
          <div className="general-auth-input-wrapper">
            <div className="general-label-input">
              <label htmlFor="username" className="input-label">
                <img alt="member-icon" src={memberSvg} />
                <span className="text-s secondary-text">
                  Användarnamn (E-post)
                </span>
              </label>
              <input
                type="string"
                placeholder=""
                id="username"
                value={email}
                disabled
              />
            </div>
            <div className="general-label-input">
              <label htmlFor="code" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">Kod</span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type="text"
                  placeholder=""
                  id="code"
                  value={code}
                  required
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
            <div className="general-label-input">
              <label htmlFor="newPassword" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">Nytt Lösenord*</span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  placeholder=""
                  id="newPassword"
                  minLength={8}
                  value={newPassword}
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img
                  src={newPasswordVisible ? eyeSlash : eye}
                  alt="toggle visibility"
                  className="password-toggle-icon"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                />
              </div>
            </div>
            <div className="general-label-input">
              <label htmlFor="confirmNewPassword" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">
                  Bekräfta lösenord*
                </span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={confirmNewPasswordVisible ? "text" : "password"}
                  placeholder=""
                  minLength={8}
                  required
                  id="confirmNewPassword"
                  value={confirmNewPasswordString}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <img
                  src={confirmNewPasswordVisible ? eyeSlash : eye}
                  alt="toggle visibility"
                  className="password-toggle-icon"
                  onClick={() =>
                    setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
                  }
                />
              </div>
            </div>
          </div>
          <div className="line-divider marg-30"></div>
          <div className="login-bottom-wrapper">
            <div className="text-s"></div>
            <button type="submit" className="wide text-m">
              Byt lösenord och logga in
            </button>
          </div>
        </form>
      </div>
    </BaseAuthLayout>
  );
};

export default PasswordChange;
