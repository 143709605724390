import { IRehabBooking, IRehabBookingInput } from "../../types/api";


export const getRehabSlotsByAdminQueryFn = (date: string) => {
  const dateString = date.slice(0,10);
  const query = `
        getRehabSlotsAdmin(date: $date) {
            ... on RehabSummary {
                __typename
                slots {
                    booking {
                    datetime_end
                    datetime_start
                    flag
                    member_id
                    integration_id
                    }
                    updated_at
                    start_time
                    end_time
                    created_at
                    available_for_member_type
                }
                rehab_id
                date
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$date: String!`;
  const variables = { date: dateString };
  return {
    query,
    inputs,
    variables,
  };
}


export const getRehabSlotsQueryFn = (date: string, member_id: string) => {
    const dateString = date.slice(0,10);
  const query = `
        getRehabSlots(date: $date, member_id: $member_id) {
            ... on RehabSummary {
                __typename
                slots {
                    booking {
                    datetime_end
                    datetime_start
                    flag
                    member_id
                    integration_id
                    }
                    updated_at
                    start_time
                    end_time
                    created_at
                    available_for_member_type
                }
                rehab_id
                date
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$date: String!, $member_id: String!`;
  const variables = { date: dateString, member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const createRehabBookingQueryFn = (bookingToCreate: IRehabBookingInput) => {
  const query = `
        createRehabBooking(input: $input) {
            ... on RehabBooking {
                __typename
                datetime_end
                datetime_start
                flag
                member_id
                integration_id
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$input: RehabBookingInput!`;
  const variables = { input: bookingToCreate };
  return {
    query,
    inputs,
    variables,
  };
};

export const cancelRehabBookingQueryFn = (
  input: IRehabBooking
) => {
  const datetime_start = input.datetime_start;
  const datetime_end = input.datetime_end;
  const member_id = input.member_id;
  const flag = input.flag;

  const query = `
        cancelRehabBooking(input: $input) {
            ... on RehabBooking {
                __typename
                datetime_end
                datetime_start
                flag
                member_id
                integration_id
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$input: RehabBookingInput`;
  const variables = { input: {datetime_start, datetime_end, member_id, flag } };
  return {
    query,
    inputs,
    variables,
  };
};

export const getActiveRehabBookingsByMemberQueryFn = (
  member_id: string,
  active: boolean
) => {
  const query = `
            getActiveRehabBookingsByMember(member_id: $member_id, active: $active) {
                ... on RehabBooking {
                    __typename
                    datetime_end
                    datetime_start
                    flag
                    member_id
                    integration_id
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$member_id: String!, $active: Boolean!`;
  const variables = { member_id: member_id, active: active};
  return {
    query,
    inputs,
    variables,
  };
};
