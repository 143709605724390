import { useEffect, useState } from "react";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import "./CoachHandleTimes.scss";
import {
  IBooking,
  ICoach,
  ICoachAvailability,
  ICoachBooking,
  ICoachIntervalItem,
  ICourt,
  IntervalItem,
} from "../../types/api";
import { getCoachByID } from "../../api/coaches/coaches";
import { CognitoUser } from "../../types/cognito";
import { Auth } from "aws-amplify";
import CoachHandleAvailabilityPopup from "../../containers/Coaches/CoachHandleAvailabilityPopup/CoachHandleAvailabilityPopup";
import { combinedForCoachQuery } from "../../api/combinedQueries/combinedForCoachQuery";
import { capitalizeWords, formatDateToSwedish } from "../../shared/utility";
import WeekSelector from "../../hoc/DatePicker/Weekpicker";
import CoachShowBookedInfoPopup from "../../containers/Coaches/ CoachShowBookedInfoPopup/ CoachShowBookedInfoPopup";
import { formatToIsoDate } from "../../shared/dateUtils";

interface CoachHandleTimesProps {}

function CoachHandleTimes(props: CoachHandleTimesProps) {
  const [showBookedInfoPopup, setShowBookedInfoPopup] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [coachAvailability, setCoachAvailability] =
    useState<ICoachAvailability[]>();
  const [
    showCoachHandleAvailabilityPopup,
    setShowCoachHandleAvailabilityPopup,
  ] = useState(false);
  const [coachData, setCoachData] = useState<ICoach>();
  const [bookingToShow, setBookingToShow] = useState<ICoachBooking>();
  const [filterDate, setFilterDate] = useState(() => {
    const storedDate = localStorage.getItem("filterDate");
    return storedDate ? new Date(storedDate) : new Date();
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("filterDate", formatToIsoDate(filterDate));
  }, [filterDate]);

  const fetchChosenItems = async ({
    fetchCoachData = false,
    fetchCoachAvailabilityWeekly = false,
    fetchCourts = false,
  }) => {
    if (!user) return;

    try {
      const response = await combinedForCoachQuery({
        member_id: user.attributes["custom:member_id"],
        date: formatToIsoDate(filterDate),
        fetchCoachData,
        fetchCoachAvailabilityWeekly,
        fetchCourts,
      });
      if (response.courts && fetchCourts && !("message" in response.courts)) {
        setCourts(response.courts);
      }

      if (
        response.coachData &&
        fetchCoachData &&
        !("message" in response.coachData)
      ) {
        setCoachData(response.coachData);
      }
      if (
        response.coachAvailabilityWeekly &&
        fetchCoachAvailabilityWeekly &&
        !("message" in response.coachAvailabilityWeekly)
      ) {
        setCoachAvailability(response.coachAvailabilityWeekly);
      }
    } catch (error) {
      console.error("Error fetching coach data:", error);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchCoachData: true,
      fetchCoachAvailabilityWeekly: true,
      fetchCourts: true,
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
    }
  }, [filterDate]);

  const handleShowBookingInfoPopup = (booking: ICoachBooking) => {
    setBookingToShow(booking);
    setShowBookedInfoPopup(true);
  };
  const generateSlotsForInterval = (interval: ICoachIntervalItem) => {
    const start = interval.start_time;
    const end = interval.end_time;
    const slots = [];

    slots.push(`${start.slice(0, 5)} - ${end.slice(0, 5)}`);
    const booker = capitalizeWords(interval.booking?.member_name || "");

    if (interval.booking) {
      return (
        <tr>
          <td className="booked">
            {slots.map((slot, index) => (
              <div
                key={index}
                className="time-item-wrapper slot-booked pointer"
                onClick={() =>
                  handleShowBookingInfoPopup(interval.booking as ICoachBooking)
                }
              >
                <div className="time-content-wrapper">
                  <div>{slot}</div>
                  <div>{booker}</div>
                </div>
              </div>
            ))}
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <td>
          {slots.map((slot, index) => (
            <div key={index} className="time-item-wrapper">
              <div className="time-content-wrapper">
                <div>{slot}</div>
              </div>
            </div>
          ))}
        </td>
      </tr>
    );
  };

  if (!user || !coachData || !coachAvailability) return null;

  return (
    <div className="coach-times-page-container">
      <div className="coach-times-layout">
        <div className="coach-times-declarations">
          <h1 className="text-l primary-text">
            Tränare - {coachData.first_name}
          </h1>
          <div className="declarations-wrapper text-m">
            <span className="declaration"> Bokade (Blå)</span>
            <span className="declaration"> Tillgängliga (Vit)</span>
          </div>
        </div>

        <div className="coach-times-action-wrapper">
          <div className="coach-times-weekday-selector-wrapper">
            <WeekSelector
              value={filterDate}
              onChange={setFilterDate}
              includeWeekdays={false}
              textM
            />
            {filterDate.getDate() !== new Date().getDate() && (
              <a className="text-m" onClick={() => setFilterDate(new Date())}>
                Till idag
              </a>
            )}
          </div>
          <div className="coach-availability-action-price-wrapper">
            <button
              className="text-m"
              onClick={() => {
                fetchChosenItems({fetchCoachAvailabilityWeekly: true});
                setShowCoachHandleAvailabilityPopup(true)}}
            >
              Ändra tillgänglighet
            </button>
            <div className="coach-times-prices-wrapper text-m">
              Pris: {coachData.coach_price} (kr/h)
            </div>
          </div>
        </div>
        <div className="coach-table-overflow-wrapper">
          <table className="coach-table">
            <thead>
              <tr>
                {coachAvailability.map((day, index) => {
                  const date = new Date(day.date);
                  const { weekday } = formatDateToSwedish(date);

                  return (
                    <th key={index} className="text-s secondary-text">
                      {weekday} - {formatToIsoDate(date).slice(8, 10)}/
                      {formatToIsoDate(date).slice(5, 7)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {coachAvailability.map((day, index) => {
                  return (
                    <td key={index}>
                      {day.intervals.map(
                        (interval: ICoachIntervalItem, index) => {
                          return (
                            <table
                              className="nested-coach-times-table text-s secondary-text"
                              key={index}
                            >
                              <tbody>
                                {generateSlotsForInterval(interval)}
                              </tbody>
                            </table>
                          );
                        }
                      )}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CoachShowBookedInfoPopup
        showPopup={showBookedInfoPopup}
        onClose={() => setShowBookedInfoPopup(false)}
        onConfirm={() => {}}
        booking={bookingToShow}
        courts={courts}
      />
      <CoachHandleAvailabilityPopup
        showPopup={showCoachHandleAvailabilityPopup}
        onClose={() => setShowCoachHandleAvailabilityPopup(false)}
        onConfirm={() =>
          fetchChosenItems({ fetchCoachAvailabilityWeekly: true })
        }
        coach={coachData}
        coachAvailability={coachAvailability}
        filterDate={filterDate}
      />
    </div>
  );
}
export default CoachHandleTimes;
