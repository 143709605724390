import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  cancelRehabBookingQueryFn,
  createRehabBookingQueryFn,
  getActiveRehabBookingsByMemberQueryFn,
  getRehabSlotsByAdminQueryFn,
  getRehabSlotsQueryFn,
} from "./rehabQueries";
import { IRehabBooking, IRehabBookingInput } from "../../types/api";




export const getRehabSlotsByAdmin = async (date: string) => {
  const { query, inputs, variables } = getRehabSlotsByAdminQueryFn(date);
  const myQuery = `
         query getRehabSlotsByAdmin(${inputs}) {
              ${query}
         }
    `;
  try {
    const getRehabSlotsByAdminResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getRehabSlotsByAdminResponse.data.getRehabSlotsByAdmin;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
}


export const getRehabSlots = async (date: string, member_id: string) => {
  const { query, inputs, variables } = getRehabSlotsQueryFn(date, member_id);
  const myQuery = `
         query getRehabSlots(${inputs}) {
              ${query}
         }
    `;
  try {
    const getRehabSlotsResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getRehabSlotsResponse.data.getRehabSlots;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const createRehabBooking = async (bookingToCreate: IRehabBookingInput) => {
  const { query, inputs, variables } =
    createRehabBookingQueryFn(bookingToCreate);
  const myQuery = `
            mutation createRehabBooking(${inputs}) {
                ${query}
            }
        `;
  try {
    const getCreateRehabBookingResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateRehabBookingResponse.data.createRehabBooking;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const cancelRehabBooking = async (input: IRehabBooking) => {
  const { query, inputs, variables } = cancelRehabBookingQueryFn(input);
  const myQuery = `
        mutation cancelRehabBooking(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCancelRehabBookingResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCancelRehabBookingResponse.data.cancelRehabBooking;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const getActiveRehabBookingsByMember = async (
  member_id: string,
  active: boolean
) => {
  const { query, inputs, variables } = getActiveRehabBookingsByMemberQueryFn(
    member_id,
    active
  );
  const myQuery = `
      query getActiveRehabBookingsByMember(${inputs}) {
          ${query}
      }
  `;
  try {
    const getActiveRehabBookingsResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response =
      getActiveRehabBookingsResponse.data.getActiveRehabBookingsByMember;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};
