import { addDays, format, getUnixTime, startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import capitalizedSv from "../../../shared/locale/capitalizeSwedishLocale";
import "./WeekInput.scss";
import { toast } from "react-toastify";

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
  minDateDays?: number;
  placeholder?: string | null;
  width?: string;
  isEnvironmental?: boolean;
  unselectableDates?: string[];
  ignoreUnselectableDates?: boolean;
  showAllDates?: boolean;
  isCompleted?: boolean;
  showWeekNumbers?: boolean;
  short?: boolean;
  isSmall?: boolean;
};

function WeekInput(props: Props) {
  const { t } = useTranslation();
  function getMinDate() {
    if (props.showAllDates) {
      return new Date(0);
    }
    return addDays(
      startOfDay(new Date()),
      props.minDateDays === undefined ? 1 : props.minDateDays
    );
  }
  const inputDesktopClasses = ["week-input-desktop"];
  if (props.short) {
    inputDesktopClasses.push("short");
  }
  if (props.value) {
    inputDesktopClasses.push("has-value");
  }
  if (props.isCompleted) {
    inputDesktopClasses.push("is-completed");
  }
  if (props.isSmall) {
    inputDesktopClasses.push("text-m date-input-width-97");
  }
  function selectHandler(date: Date | null) {
    if (date === null) {
      props.onChange(null);
      return;
    }
    const minDate = getMinDate();
   if (getUnixTime(date) >= getUnixTime(minDate)) {
     const now = new Date();
     const selectedDate = new Date(
       date.getFullYear(),
       date.getMonth(),
       date.getDate(),
       now.getHours(),
       now.getMinutes(),
       now.getSeconds(),
       now.getMilliseconds()
     );

     const swedishDate = new Date(
       selectedDate.toLocaleString("sv-SE", { timeZone: "Europe/Stockholm" })
     );
     props.onChange(swedishDate);
   } else {
     props.onChange(null);
     toast.error(
       `${t("errorMessage.badDate")} ${format(minDate, "yyyy-MM-dd")}`
     );
   }
  }
  return (
    <div
      className="date-picker relative-picker"
      style={{
        width: props.width,
      }}
    >
      <div className="week-v">v.</div>
      <DatePicker
        className={inputDesktopClasses.join(" ")}
        selected={props.value}
        onChange={(date: Date) => selectHandler(date)}
        placeholderText={props.placeholder || undefined}
        dateFormat="I/R"
        minDate={getMinDate()}
        calendarClassName="date-input-calendar"
        wrapperClassName="date-input-wrapper-desktop"
        autoComplete="off"
        locale={capitalizedSv}
        calendarStartDay={1}
        filterDate={
          props.ignoreUnselectableDates ? undefined : undefined //(date) => !isUnselectableDate(date, props.unselectableDates)
        }
        showWeekPicker
        showWeekNumbers={props.showWeekNumbers}
      />

      {/* {props.isEnvironmental && (
        <Subtitle
          text={t('general.co2ScheduleText')}
          icon="seedling"
          iconColor="var(--text-color-alt)"
          iconStyle={{
            marginRight: '5px',
          }}
        />
      )} */}
    </div>
  );
}
export default WeekInput;
