import { IPrices } from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";

import { listPricesQueryFn } from "./pricesQueries";
export const listPrices = async (): Promise<IPrices[]> => {
  const { query } = listPricesQueryFn();
  const myQuery = `
        query MyQuery {
            ${query}
        }
    `;
  try {
    await Auth.currentSession();

    const listPricesResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = listPricesResponse.data.listPrices;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const editPrice = async (priceToEdit: IPrices): Promise<boolean> => {
  const priceToEditObject = {
    color: priceToEdit.color,
    price: priceToEdit.price,
  };
  const query = `
         mutation myMutation($input: [PriceInput]) {
            updatePrices(input: $input) {
               ... on Price {
                color
                price
                }
                ... on Error {
                code
                message
                }
              }
            }
        `;

  try {
    const getEditResponse = (await API.graphql({
      query: query,
      variables: { input: priceToEditObject },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getEditResponse.data.updatePrices;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
