import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { ICourtType } from "../../types/api";
import { getSlotByMemberQueryFn } from "./summaryQueries";

export const getSlotByMember = async (
  datetime_start: string,
  member_id: string,
  court_id: string,
  court_type: ICourtType
) => {
  const { query, inputs, variables } = getSlotByMemberQueryFn(
    datetime_start,
    member_id,
    court_id,
    court_type
  );
  const myQuery = `query MyQuery(${inputs}) {
        ${query}
    }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.getSlotByMember;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
