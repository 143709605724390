import { ICourtType } from "../../types/api";


export const getChartDataQueryFn = (
  dates: string[],
  courtType: ICourtType,
  lag: number
) => {
  const query = `
         getChartData(dates: $dates, court_type: $court_type, lag: $lag) {
            ... on ChartData {
            __typename
            datasets {
                data
                label
            }
            labels
            }
            ... on Error {
            code
            message
            }
        }
`;
  const inputs = `$dates: [String!]!,  $court_type: String!, $lag: Int!`;
  const variables = {
    dates: dates,
    court_type: courtType,
    lag: lag,
  };

  return {
    variables,
    inputs,
    query,
  };
};
