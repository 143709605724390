import { ICreateAdminSpec } from "../../types/api";

export const listAdminsQueryFn = () => {
  const query = `
      listAdmins {
        ... on Admin {
          member_id
          first_name
          last_name
          email
        }
        ... on Error {
          code
          message
        }
      }
  `;

  return {
    query,
  };
};

export const editAdminQueryFn = (member_id:string,adminToEdit: ICreateAdminSpec, old_email:string) => {
  const query = `
            updateAdmin(member_id: $member_id, old_email: $old_email input_data: $input_data) {
            ... on Admin {
                member_id
                first_name
                last_name 
                email
            }
            ... on Error {
                message
                code
            }
        }
   `;

  const inputs = `$member_id: String!, $old_email: String, $input_data: UpdateAdminInput`;
  const variables = { member_id:member_id, old_email: old_email, input_data: adminToEdit };
  return {
    inputs,
    variables,
    query,
  };
};

export const deleteAdminQueryFn = (member_id: string) => {
  const query = `
            deleteAdmin(member_id: $member_id) {
            ... on Admin {
                member_id
            }
            ... on Error {
                message
                code
            }
        }
        
    `;

  const inputs = `$member_id: String!`;
  const variables = { member_id };
  return {
    query,
    variables,
    inputs,
  };
};
