import "./AdminHandlePermissions.scss";
import { useEffect, useState } from "react";
import { ICourt, IPermission, IPrices } from "../../types/api";
import { listPermissions } from "../../api/permissions/permissions";
import EditPermissionPopup from "../../containers/Permissions/EditPermissionPopup/EditPermissionPopup";
import CreatePermissionPopup from "../../containers/Permissions/CreatePermissionPopup/CreatePermissionPopup";
import check from "../../assets/images/check.svg";
import cross from "../../assets/images/cross.svg";
import { listCourts } from "../../api/courts/courts";
import { capFirstAndRemoveDash } from "../../shared/utility";
import { listPrices } from "../../api/prices/prices";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "react-toastify";
interface IAdminHandleusersProps {}

function AdminHandlePermissions(props: IAdminHandleusersProps) {
  const [prices, setPrices] = useState<IPrices[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [currentEditPermission, setCurrentEditPermission] =
    useState<IPermission>();
  const [showEditPermissionPopup, setShowEditPermissionPopup] =
    useState<boolean>(false);
  const [showCreatePermissionPopup, setShowCreatePermissionPopup] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchChosenItems = async ({
    fetchCourts = false,
    fetchPrices = false,
    fetchPermissions = false,
  }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchCourts: fetchCourts,
        fetchPrices: fetchPrices,
        fetchPermissions: fetchPermissions,
      });

      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }
      if (fetchPrices && response.prices && !("message" in response.prices)) {
        setPrices(response.prices);
      }
      if (
        fetchPermissions &&
        response.permissions &&
        !("message" in response.permissions)
      ) {
        setPermissions(response.permissions);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchPrices: true,
      fetchCourts: true,
      fetchPermissions: true,
    });
  }, []);

  const openEditPermission = (permission: IPermission) => {
    setCurrentEditPermission(permission);
    setShowEditPermissionPopup(true);
  };

  const openCreatePermission = () => {
    setShowCreatePermissionPopup(true);
  };

  const handleInputChange = () => {};

  if (!permissions || loading) return <Spinner />;

  return (
    <div className="admin-handle-permissions-layout">
      <h1 className="text-l primary-text">Medlemskap</h1>
      <div className="handle-permissions-actions-wrapper">
        <div className="permissions-search-wrapper"></div>
        <div className="permissions-add-wrapper">
          <button
            className="permissions-add-button text-m"
            onClick={() => openCreatePermission()}
          >
            Skapa nytt medlemskap
          </button>
        </div>
      </div>
      <div className="permissions-table-overflow-wrapper">
        <table className="permissions-table">
          <thead>
            <tr>
              <th className="text-m secondary-text">Medlemskap</th>
              <th className="text-m secondary-text">Gym</th>
              <th className="text-m secondary-text">Betalkort</th>
              <th className="text-m secondary-text">Ranking</th>
              <th className="text-m secondary-text">Titel</th>
              <th className="text-m secondary-text">Sökbar för medlemmar</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission, index) => {
              const isJunior =
                permission.member_type === "Junior" ||
                permission.member_type === "Junior B";

              return (
                <tr key={index}>
                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {permission.permission_id} -{" "}
                    {capFirstAndRemoveDash(permission.member_type)}
                    {isJunior && <span> (Redigera inte)</span>}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {permission.gym_access ? (
                      <img src={check} alt="check"></img>
                    ) : (
                      <img src={cross} alt="cross"></img>
                    )}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {permission.payment_card ? (
                      <img src={check} alt="check"></img>
                    ) : (
                      <img src={cross} alt="cross"></img>
                    )}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {permission.ranking ? (
                      <img src={check} alt="check"></img>
                    ) : (
                      <img src={cross} alt="cross"></img>
                    )}
                  </td>
                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {permission.title ? (
                      <img src={check} alt="check"></img>
                    ) : (
                      <img src={cross} alt="cross"></img>
                    )}
                  </td>

                  <td
                    className="text-m primary-text"
                    onClick={() => openEditPermission(permission)}
                  >
                    {!permission.hidden ? (
                      <img src={check} alt="check"></img>
                    ) : (
                      <img src={cross} alt="cross"></img>
                    )}

                    <div className="edit-component">
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                      <div className="edit-dot"></div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <EditPermissionPopup
        showPopup={showEditPermissionPopup}
        onClose={() => {
          setCurrentEditPermission(undefined);
          setShowEditPermissionPopup(false);
        }}
        onConfirm={() => {
          {
            fetchChosenItems({ fetchPermissions: true });
          }
        }}
        permission={currentEditPermission}
        courts={courts}
        prices={prices}
      />
      <CreatePermissionPopup
        showPopup={showCreatePermissionPopup}
        onClose={() => {
          setShowCreatePermissionPopup(false);
        }}
        onConfirm={() => {
          {
            fetchChosenItems({ fetchPermissions: true });
          }
        }}
        courts={courts}
        prices={prices}
      />
    </div>
  );
}

export default AdminHandlePermissions;
