import { setDay } from "date-fns";
import { useTranslation } from "react-i18next";
import Button from "../../components/UI/Button/Button";
import "./Weekpicker.scss";
import WeekInput from "../../components/UI/WeekInput/WeekInput";

type Props = {
  value: Date | null;
  onChange: (value: Date) => void;
  includeWeekdays: boolean;
  textM?: boolean;
  isSmall?: boolean;
};
function WeekSelector(props: Props) {
  const { t } = useTranslation();
  const weekDay = props.value ? props.value.getDay() : null;
  const weekDays = [
    {
      name: "M",
      value: 1,
    },
    {
      name: "T",
      value: 2,
    },
    {
      name: "O",
      value: 3,
    },
    {
      name: "T",
      value: 4,
    },
    {
      name: "F",
      value: 5,
    },
    {
      name: "L",
      value: 6,
    },
    {
      name: "S",
      value: 0,
    },
  ];
  const adjustToSwedishTime = (date: Date): Date => {
    const now = new Date();
    const selectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
    return new Date(
      selectedDate.toLocaleString("sv-SE", { timeZone: "Europe/Stockholm" })
    );
  };
  const textM = props.textM ? "text-m" : "";
  return (
    <div className={`week-selector ${textM}`}>
      <WeekInput
        value={props.value}
        onChange={(value) => {
          if (value) props.onChange(value);
        }}
        width="135px"
        showAllDates
        ignoreUnselectableDates
        showWeekNumbers
        isSmall={props.isSmall}
      />
      <i className="fa-regular fa-calendar absolute-calendar"></i>
      {props.includeWeekdays ? (
        weekDays.map((day) => (
          <Button
            key={day.value}
            label={t(`${day.name}`)}
            color={weekDay === day.value ? "secondary" : "primary"}
            onClick={() => {
              const selectedDate = setDay(
                props.value || new Date(),
                day.value,
                { weekStartsOn: 1 }
              );
              const swedishDate = adjustToSwedishTime(selectedDate);
              props.onChange(swedishDate);
            }}
            short
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
export default WeekSelector;
