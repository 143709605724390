import { ICoachAvailabilityInput, ICoachInput } from "../../types/api";
import {
  createCoachQueryFn,
  deleteCoachQueryFn,
  getCoachAvailibilityWeeklyQueryFn,
  getCoachByIDQueryFn,
  listCoachesAvailabilityQueryFn,
  listCoachesQueryFn,
  makeCoachAvailableQueryFn,
} from "./coachesQueries";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";

export const createCoach = async (coachToCreate: ICoachInput) => {
  const { query, inputs, variables } = createCoachQueryFn(coachToCreate);

  const myQuery = `
        mutation createCoach(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCreateResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createCoach;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const listCoaches = async (requestor: "admin" |"user") => {
  const { query } = listCoachesQueryFn(requestor);
  const myQuery = `
        query listCoaches {
            ${query}
        }
    `;
  try {
    const listCoachesResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = listCoachesResponse.data.listCoaches;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getCoachByID = async (coachId: string) => {
  const { query, inputs, variables } = getCoachByIDQueryFn(coachId);
  const myQuery = `
        query myQuery(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCoachResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getCoachResponse.data.getCoachByID;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const makeCoachAvailable = async (
  availabilityToUpdate: ICoachAvailabilityInput
) => {
  const { query, inputs, variables } =
    makeCoachAvailableQueryFn(availabilityToUpdate);

  const myQuery = `
        mutation makeCoachAvailable(${inputs}) {
            ${query}
        }
    `;
  try {
    const getMakeAvailableResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getMakeAvailableResponse.data.makeCoachAvailable;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const getCoachAvailibilityWeekly = async (
  coachId: string,
  date: string
) => {
  const { query, inputs, variables } = getCoachAvailibilityWeeklyQueryFn(
    coachId,
    date
  );

  const myQuery = `
        query getCoachAvailibilityWeekly(${inputs}) {
            ${query}
        }
    `;
  try {
    const getWeeklyAvailabilityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response =
      getWeeklyAvailabilityResponse.data.getCoachAvailibilityWeekly;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
};

export const listCoachesAvailability = async (date: string) => {
  const { query, inputs, variables } = listCoachesAvailabilityQueryFn(date);
  const myQuery = `
        query listCoachesAvailability(${inputs}) {
            ${query}
        }
    `;
  try {
    const getWeeklyAvailabilityResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response =
      getWeeklyAvailabilityResponse.data.listCoachesAvailability;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return error;
  }
}

export const deleteCoach = async (coachId: string) => {
  const {query, inputs, variables} = deleteCoachQueryFn(coachId)
  const myQuery = `
    mutation deleteCoach(${inputs}) {
      ${query}
    }
  `
  try {
    const getDeleteResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getDeleteResponse.data.deleteCoach;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return response;
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
}
