import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IPrices } from "../../types/api";

interface TimeBatch {
  id: string;
  startTime: string;
  endTime: string;
  checkedDays: { [dayString: string]: boolean };
  defaultChecked: boolean;
  isException: boolean;
  exceptionStartDate?: Date;
  exceptionEndDate?: Date;
  priceChoices?: IPrices[];
  priceChoice?: string;
}

type InitialBatchType = TimeBatch[];

const useTimeBatches = (
  initialBatches: InitialBatchType
): {
  timeBatches: TimeBatch[];
  setBatches: (newBatches: InitialBatchType) => void;
  addTimeBatch: (
    defaultChecked: boolean,
    isException?: boolean,
    exceptionStartDate?: Date,
    exceptionEndDate?: Date,
    priceChoices?: IPrices[],
    priceChoice?: string
  ) => void;
  removeTimeBatch: (id: string) => void;
  updateStartTime: (id: string, newStartTime: string) => void;
  updateEndTime: (id: string, newEndTime: string) => void;
  updateCheckedDays: (id: string, dayString: string) => void;
  updateIsException: (id: string, newIsException: boolean) => void;
  updateExceptionStartDate: (id: string, newExceptionStartDate: Date) => void;
  updateExceptionEndDate: (id: string, newExceptionEndDate: Date) => void;
  updatePriceChoice: (id: string, newPriceChoice: string) => void;
  updateTimeBatch: (
    id: string,
    newStartTime?: string,
    newEndTime?: string,
    newCheckedDays?: { [dayString: string]: boolean },
    isException?: boolean,
    exceptionStartDate?: Date,
    exceptionEndDate?: Date
  ) => void;
} => {
  const [timeBatches, setTimeBatches] = useState<TimeBatch[]>(initialBatches);

  const setBatches = (newBatches: InitialBatchType): void => {
    setTimeBatches(
      newBatches.map((batch) => ({
        ...batch,
        id: batch.id || uuidv4(),
        startTime: batch.startTime || "07:00:00",
        endTime: batch.endTime || "23:00:00",
        checkedDays: batch.defaultChecked
          ? {
              monday: true,
              tuesday: true,
              wednesday: true,
              thursday: true,
              friday: true,
              saturday: true,
              sunday: true,
            }
          : {
              ...{
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
              },
              ...batch.checkedDays,
            },
        isException: batch.isException || false,
        exceptionStartDate: batch.exceptionStartDate || new Date(),
        exceptionEndDate: batch.exceptionEndDate || new Date(),
        priceChoice: batch.priceChoice,
      }))
    );
  };

  

  const defaultTimeBatch = (defaultChecked: boolean): TimeBatch =>  ({
    id: uuidv4(),
    startTime: "07:00:00",
    endTime: "23:00:00",
    checkedDays: defaultChecked
      ? {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        }
      : {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
    defaultChecked,
    isException: false,
  });

  const addTimeBatch = (
    defaultChecked: boolean,
    isException = false,
    exceptionStartDate = new Date(),
    exceptionEndDate = new Date(),
    priceChoices: IPrices[] = [],
    priceChoice: string = "blue"
  ) => {
    setTimeBatches((prevBatches) => [
      ...prevBatches,
      {
        ...defaultTimeBatch(defaultChecked),
        id: uuidv4(),
        isException,
        exceptionStartDate,
        exceptionEndDate,
        priceChoices,
        priceChoice,
      },
    ]);
  };

  const removeTimeBatch = (idToRemove: string) => {
    setTimeBatches((prevBatches) =>
      prevBatches.filter((batch) => batch.id !== idToRemove)
    );
  };

  const updateBatch = (id: string, updates: Partial<Omit<TimeBatch, "id">>) => {
    setTimeBatches((prevBatches) =>
      prevBatches.map((batch) =>
        batch.id === id ? { ...batch, ...updates } : batch
      )
    );
  };

  const updateStartTime = (id: string, newStartTime: string) => {
    updateBatch(id, { startTime: newStartTime });
  };

  const updateEndTime = (id: string, newEndTime: string) => {
    updateBatch(id, { endTime: newEndTime });
  };

  const updateCheckedDays = (id: string, dayString: string) => {
    setTimeBatches((prevBatches) =>
      prevBatches.map((batch) =>
        batch.id === id
          ? {
              ...batch,
              checkedDays: {
                ...batch.checkedDays,
                [dayString]: !batch.checkedDays[dayString],
              },
            }
          : batch
      )
    );
  };

  const updateIsException = (id: string, newIsException: boolean) => {
    updateBatch(id, { isException: newIsException });
  };

  const updateExceptionStartDate = (
    id: string,
    newExceptionStartDate: Date
  ) => {
    updateBatch(id, { exceptionStartDate: newExceptionStartDate });
  };

  const updateExceptionEndDate = (id: string, newExceptionEndDate: Date) => {
    updateBatch(id, { exceptionEndDate: newExceptionEndDate });
  };

  const updatePriceChoice = (id: string, newPriceChoice: string) => {
    updateBatch(id, { priceChoice: newPriceChoice });
  };

  const updateTimeBatch = (
    id: string,
    newStartTime?: string,
    newEndTime?: string,
    newCheckedDays?: { [dayString: string]: boolean },
    newIsException?: boolean,
    newExceptionStartDate?: Date,
    newExceptionEndDate?: Date
  ) => {
    const updates: Partial<TimeBatch> = {
      ...(newStartTime !== undefined && { startTime: newStartTime }),
      ...(newEndTime !== undefined && { endTime: newEndTime }),
      ...(newCheckedDays !== undefined && {
        checkedDays: { ...newCheckedDays },
      }),
      ...(newIsException !== undefined && { isException: newIsException }),
      ...(newExceptionStartDate !== undefined && {
        exceptionStartDate: newExceptionStartDate,
      }),
      ...(newExceptionEndDate !== undefined && {
        exceptionEndDate: newExceptionEndDate,
      }),
    };
    updateBatch(id, updates);
  };

  return {
    timeBatches,
    addTimeBatch,
    setBatches,
    removeTimeBatch,
    updateTimeBatch,
    updateStartTime,
    updateEndTime,
    updateCheckedDays,
    updateIsException,
    updateExceptionStartDate,
    updateExceptionEndDate,
    updatePriceChoice,
  };
};

export default useTimeBatches;
