import { addToQueueQueryFn, getActiveQueueSpotsByMemberQueryFn, removeFromQueueQueryFn } from "./queueQueries";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";

export const addToQueue = async (member_id: string, datetime_start: string, court_type:string) => {
  const { query, inputs, variables } = addToQueueQueryFn(
    member_id,
    datetime_start,
    court_type
  );
  const myQuery = `
        mutation addToQueue(${inputs}) {
            ${query}
        }
    `;
  try {
    const getAddToQueueResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getAddToQueueResponse.data.AddToQueue;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response
  } catch (error: any) {
    toast.error(error);
    return false;
  }
};


export const getActiveQueueSpotsByMember = async (member_id: string) => {
  const { query, inputs, variables } = getActiveQueueSpotsByMemberQueryFn(member_id);
  const myQuery = `
        query getActiveQueueSpotsByMember(${inputs}) {
            ${query}
        }
    `;
  try {
    const getActiveQueueSpotsByMemberResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getActiveQueueSpotsByMemberResponse.data.getActiveQueueSpotsByMember;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};

export const removeFromQueue = async (datetime_start: string, member_id: string) => {
  const { query, inputs, variables } = removeFromQueueQueryFn(
    datetime_start,
    member_id
  );
  const myQuery = `
        mutation RemoveFromQueue(${inputs}) {
            ${query}
        }
    `;
  try {
    const getRemoveFromQueueResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getRemoveFromQueueResponse.data.RemoveFromQueue;
    if ("message" in response) {
      toast.error(response.message);
      throw new Error(response.message);
    }
    return response
  } catch (error: any) {
    toast.error(error.errors[0].message);
    return false;
  }
};