import { format} from "date-fns";
import { sv } from "date-fns/locale";

export const formatDate = (date:Date, formatStr:string) => {
  return format(date, formatStr, { locale: sv });
};

export const formatToIsoDate = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { locale: sv });
};
