import { useEffect, useState } from "react";
import MidPopup from "../../hoc/MidPopup/MidPopup";
import { toast } from "react-toastify";
import {
  updateDailyComment,
  createDailyComment,
  getDailyComment,
} from "../../api/dailyComment/dailyComment"; // Import the create function
import { formatDate } from "../../shared/dateUtils";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import { addDays, subDays } from "date-fns";
import "./HandleDailyCommentPopup.scss";
import { formatDateToSwedish } from "../../shared/utility";

interface IHandleDailyCommentPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: () => void;
  comment: string;
}

function HandleDailyCommentPopup(props: IHandleDailyCommentPopupProps) {
  const { onClose, showPopup, onConfirm } = props;
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(subDays(new Date(), -1));
  const [comments, setComments] = useState<
    { date: string; comment: string; isNew: boolean }[]
  >([]); // Added isNew flag
  const [initialComments, setInitialComments] = useState<
    { date: string; comment: string; isNew: boolean }[]
  >([]);
  const [isTodayUpdated, setIsTodayUpdated] = useState<boolean>(false);

  // Reset popup when closed
  const handleClose = () => {
    setStartDate(new Date());
    setEndDate(subDays(new Date(), -1));
    setComments([]);
    onClose();
    if (isTodayUpdated) {
      onConfirm();
    }
  };

  const handleSetStartDate = (date: Date | null) => {
    if (date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        toast.error("Välj ett aktuellt datum");
        return;
      }
      setStartDate(date);

      if (endDate <= date) {
        setEndDate(addDays(date, 1));
      }
    }
  };

  const handleSetEndDate = (date: Date | null) => {
    if (date) {
      if (date <= startDate) {
        toast.error("Slutdatum måste vara minst en dag efter startdatum");
        setEndDate(addDays(startDate, 1));
      } else {
        setEndDate(date);
      }
    }
  };

  // Fetch comments for all dates between start and end date
  const handleFetchComments = async () => {
    try {
      const dates = [];
      let currentDate = new Date(startDate);
      const fetchedComments: {
        date: string;
        comment: string;
        isNew: boolean;
      }[] = [];

      while (currentDate <= endDate) {
        const formattedDate = formatDate(currentDate, "yyyy-MM-dd");
        dates.push(formattedDate);
        currentDate = addDays(currentDate, 1);
      }

      // Fetch comments for each date
      const promises = dates.map(async (date) => {
        const response = await getDailyComment(date);

        // Set isNew flag to true if no comment exists
        const isNew = response.comment === "No comment";
        fetchedComments.push({ date, comment: response.comment || "", isNew });
      });

      await Promise.all(promises);
      const commentsSorted = fetchedComments.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      setComments(commentsSorted);
      setInitialComments(JSON.parse(JSON.stringify(commentsSorted)));
      if (fetchedComments.length === 0) {
        toast.warning("Inga kommentarer hittades");
      }
    } catch (error: any) {
      toast.error("Något gick fel vid hämtning av kommentarer");
    }
  };

  const handleSaveComment = async (
    date: string,
    comment: string,
    isNew: boolean
  ) => {
    try {
      let response;
      if (isNew) {
        // Create new comment if the flag indicates it's new
        response = await createDailyComment(date, comment);
      } else {
        // Update existing comment
        response = await updateDailyComment(date, comment);
      }

      if (response && "message" in response) {
        toast.error(response.message);
      } else {
        // Update both comments and initialComments to remove the "isNew" flag
        setComments(
          comments.map((c) => {
            if (c.date === date) {
              return { ...c, isNew: false }; // Set isNew to false after successful creation
            }
            return c;
          })
        );
        setInitialComments(
          initialComments.map((c) => {
            if (c.date === date) {
              return { ...c, comment, isNew: false }; // Also update initialComments
            }
            return c;
          })
        );

        const today = formatDate(new Date(), "yyyy-MM-dd");
        if (date === today) {
          setIsTodayUpdated(true);
        }
        toast.success(`Kommentar för ${date} har uppdaterats`);
      }
    } catch (error: any) {
      toast.error(
        error.errors
          ? error.errors[0].message
          : "Ett fel uppstod vid uppdatering"
      );
    }
  };


  // Handle comment change in the list
  const handleCommentChange = (index: number, newComment: string) => {
    const updatedComments = [...comments];
    updatedComments[index].comment = newComment;
    setComments(updatedComments);
  };

  return (
    <MidPopup
      onClose={handleClose}
      showPopup={showPopup}
      leftTopElement={<span className="text-m">Hantera kommentarer</span>}
      onConfirm={() => {}}
      width="600px"
    >
      <div className="comment-popup-wrapper">
        <div className="comment-button-input-wrapper">
          <div className="comment-input-wrapper"></div>

          <div className="handle-comments-date-wrapper">
            <div className="handle-comments-date-picker">
              <span className="text-m">Från</span>
              <WeekDaySelector
                includeWeekdays={false}
                value={startDate}
                onChange={handleSetStartDate}
                isSmall
              />
            </div>
            <div className="handle-comments-date-picker">
              <span className="text-m">Till</span>
              <WeekDaySelector
                includeWeekdays={false}
                value={endDate}
                onChange={handleSetEndDate}
                isSmall
              />
            </div>
            <button
              className="comment-button text-m"
              onClick={handleFetchComments}
            >
              Hämta kommentarer
            </button>
          </div>

          {/* Display fetched comments */}
          {comments.length > 0 && (
            <div className="comments-list">
              {comments.map(({ date, comment, isNew }, index) => {
                const { weekday, dateAndMonth } = formatDateToSwedish(
                  new Date(date)
                );
                const value = comment === "No comment" ? "" : comment;
                return (
                  <div key={index} className="comment-item text-m">
                    <div>
                      <span>
                        {weekday}, {dateAndMonth}:
                      </span>
                    </div>
                    <div className="comment-item-input-btn-wrapper">
                      <input
                        type="text"
                        value={value}
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value)
                        }
                      />
                      <button
                        disabled={
                          comments[index].comment ===
                          initialComments[index].comment
                        }
                        onClick={() =>
                          handleSaveComment(
                            date,
                            comments[index].comment,
                            isNew
                          )
                        }
                      >
                        {isNew ? "Skapa" : "Uppdatera"}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </MidPopup>
  );
}

export default HandleDailyCommentPopup;
