import "./EditPriceValuePopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IPrices } from "../../../types/api";
import { toast } from "react-toastify";
import { editPrice } from "../../../api/prices/prices";
import { removeLeadingZeros } from "../../../shared/utility";

interface IEditPriceValuePopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  prices: IPrices[];
}

function EditPriceValuePopup(props: IEditPriceValuePopupProps) {
  const [priceValues, setPriceValues] = useState(() =>
    props.prices.map((price) => ({ ...price }))
  );
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const changesDetected = priceValues.some((price, index) => {
      return price.price !== props.prices[index].price;
    });
    setHasChanged(changesDetected);
  }, [priceValues, props.prices]);

  const handlePriceChange = (index: number, e: any) => {
    let value = e.target.value;
    if (e.target.value >= 9999) {
      value = 9999;
    }
    const newPrices = priceValues.map((price) => ({ ...price }));
    newPrices[index].price = Number(value);

    setPriceValues(newPrices);
  };

  const handleEditPriceValue = async (event: any) => {
    event.preventDefault();
    try {
      await Promise.all(priceValues.map((price) => editPrice(price)));
      toast.success("Priser uppdaterade");
      props.onClose();
      props.onConfirm();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

 
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-m">Redigera priser</span>}
    >
      <div className="general-popup-wrapper">
        <form className="general-popup-form" onSubmit={handleEditPriceValue}>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <span className="text-s"> Redigera priser</span>
              {priceValues.map((price, index) => {
                let color = price.color;
                color === "blue"
                  ? (color = "Blåa")
                  : color === "green"
                  ? (color = "Gröna")
                  : color === "red"
                  ? (color = "Röda")
                  : (color = "Gula");
                return (
                  <div key={index} className="general-popup-flex-between">
                    <label
                      htmlFor={`price_${index}`}
                      className="text-m color-price-wrapper"
                    >
                      <div className={`color-ring ${price.color}`}></div>
                      <div>{color} priser</div>
                    </label>
                    <div className="input-currency-wrapper">
                      <input
                        className="short"
                        type="number"
                        min="0"
                        max="9999"
                        value={price.price.toString()}
                        onChange={(e) => handlePriceChange(index, e)}
                      />
                      <div className="currency-wrapper">kr</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m" disabled={!hasChanged}>
              Spara uppgifter
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default EditPriceValuePopup;
