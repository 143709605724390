import { IconType20, IconType30 } from "../../../types/internal";
import Spinner from "../Spinner/Spinner";
import "./Button.scss";

interface ButtonProps {
  onClick?: () => void;
  color: "secondary" | "primary" | "ghost";
  label: string;
  className?: string;
  width?: string;
  isLoading?: boolean;
  icon?: IconType20 | IconType30;
  short?: boolean;
  disabled?: boolean;
  dontSubmit?: boolean;
}

function Button(props: ButtonProps) {
  const buttonClasses = ["button", "text-m", props.color];
  if (props.className) {
    buttonClasses.push(props.className);
  }
  if (props.isLoading) {
    buttonClasses.push("loading");
  }

  if (props.disabled) {
    buttonClasses.push("disabled");
  }
  if (!props.disabled) {
    buttonClasses.push("waves-effect");
  }

  let icon: JSX.Element | undefined;
  switch (props.icon) {
    case "plus-20":
      icon = <i className="fa fa-plus" />;
      break;
  }

  if (icon) {
    buttonClasses.push("icon-button");
  }

  return (
    <button
      className={buttonClasses.join(" ")}
      style={{ width: props.width, height: props.short ? 25 : 35 }}
      onClick={props.disabled ? undefined : props.onClick}
      type={props.dontSubmit ? "button" : "submit"}
      disabled={props.disabled}
    >
      {props.isLoading ? (
        <Spinner />
      ) : icon ? (
        <div className="icon-button-content">
          {icon}
          {props.label}
        </div>
      ) : (
        props.label
      )}
    </button>
  );
}

export default Button;
