export const getVisualArtByCourtQueryFn = (court_id: string, date: string) => {
  const query = `
        getVisualArtByCourt(court_id: $court_id, date: $date) {
                 ... on VisualArtBooking {
                    __typename
                    court_id
                    active
                    info
                    countdown
                    player_one_first_team
                    player_one_second_team
                    player_two_first_team
                    player_two_second_team
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$court_id: String!, $date: String!`;
  const variables = { court_id: court_id, date: date };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateVisualArtBookingQueryFn = (
  active: boolean,
  court_id: string,
  info: string,
  player_one_first_team: string,
  player_one_second_team: string,
  player_two_first_team: string,
  player_two_second_team: string
) => {
  const query = `
        updateVisualArtBooking(input: {active: $active, court_id: $court_id, info: $info, player_one_first_team: $player_one_first_team, player_one_second_team: $player_one_second_team, player_two_first_team: $player_two_first_team, player_two_second_team: $player_two_second_team}) {
                 ... on VisualArtBooking {
                    __typename
                    court_id
                    active
                    info
                    player_one_first_team
                    player_one_second_team
                    player_two_first_team
                    player_two_second_team
                }
                ... on Error {
                    code
                    message
                }
            }
        `;
  const inputs = `$active: Boolean!, $court_id: String!, $info: String!, $player_one_first_team: String!, $player_one_second_team: String!, $player_two_first_team: String!, $player_two_second_team: String!`;
  const variables = {
    active: active,
    court_id: court_id,
    info: info,
    player_one_first_team: player_one_first_team,
    player_one_second_team: player_one_second_team,
    player_two_first_team: player_two_first_team,
    player_two_second_team: player_two_second_team,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const listVisualArtBookingsQueryFn = () => {
  const query = `
        listVisualArtBookings {
        ... on VisualArtBooking {
            court_id
            active
            info
            player_one_first_team
            player_one_second_team
            player_two_first_team
            player_two_second_team
        }
        ... on Error {
            code
            message
        }
        }
    `;
  return {
    query,
  };
};
