import { IActivityInput } from "../../types/api";

export const nukeSlotQueryFn = (
  input: IActivityInput,
  datetime_to_remove: string
) => {
  const query = `
        nukeSlot(input: $input, datetime_to_remove: $datetime_to_remove) {
            ... on Activity {
                name
                datetime_start
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$input: ActivityInput!, $datetime_to_remove: String!`;
  const variables = { input, datetime_to_remove };
  return {
    query,
    inputs,
    variables,
  };
};
