import Popup from "../../hoc/Popup/Popup";
import "./LocalRecomendationsPopup.scss";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import deleteIcon from "../../assets/images/cross.svg";
import HandleConfirmationPopup from "../HandleConfirmation/HandleConfirmationPopup";
interface ILocalRecomendationsPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: (updatedRecommendations: string[]) => void;
  localRecommendations: string[] | [];
  localStorageKey: string;
}

function LocalRecomendationsPopup(props: ILocalRecomendationsPopupProps) {
  const {
    showPopup,
    onClose,
    onConfirm,
    localRecommendations,
    localStorageKey,
  } = props;
  const [recommendations, setRecommendations] =
    useState<string[]>(localRecommendations);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    // Sync the recommendations with props if they change externally
    setRecommendations(localRecommendations);
  }, [localRecommendations]);

  const addNewName = () => {
    const updatedNames = [...recommendations, ""];
    setRecommendations(updatedNames);
    localStorage.setItem(localStorageKey, JSON.stringify(updatedNames));
    onConfirm(updatedNames);
  };
  const clearRecommendations = () => {
    setRecommendations([]);
    localStorage.setItem(localStorageKey, JSON.stringify([]));
    toast.success("Alla namn har tagits bort");
    onConfirm([]);
  };
  const handleDeleteName = (index: number, name: string) => {
    const updatedNames = recommendations.filter((_, i) => i !== index);
    setRecommendations(updatedNames);
    localStorage.setItem(localStorageKey, JSON.stringify(updatedNames));
    toast.success(name + " har tagits bort");
    onConfirm(updatedNames);
  };

  const handleNameChange = (index: number, newName: string) => {
    const updatedNames = recommendations.map((name, i) =>
      i === index ? newName : name
    );
    setRecommendations(updatedNames);
    localStorage.setItem(localStorageKey, JSON.stringify(updatedNames));
    onConfirm(updatedNames);
  };
  return (
    <Popup
      showPopup={showPopup}
      onClose={onClose}
      leftTopElement={<span className="text-m">Sparade namn</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider flex-between">
                <div className="add-clear-wrapper">
                  <button onClick={addNewName} className="text-m">
                    Lägg till nytt namn
                  </button>
                  <button
                    onClick={() => setShowConfirmationPopup(true)}
                    className="text-m"
                    disabled={recommendations.length === 0}
                  >
                    Rensa alla namn
                  </button>
                </div>

                {recommendations.map((name, index) => (
                  <div key={index} className="general-popup-form-field-item">
                    <input
                      type="text"
                      className="general-popup-form-field-input text-m"
                      value={name}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                    />
                    <div className="update-delete-wrapper">
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={() => handleDeleteName(index, name)}
                        className="pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </form>
      </div>

      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={() => {
          setShowConfirmationPopup(false);
          clearRecommendations();
        }}
        questionText="Är du säker på att du vill ta bort alla sparade namn?"
        confirmText="Ja"
      />
    </Popup>
  );
}

export default LocalRecomendationsPopup;
