import "./EditPermissionPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  ICourt,
  IPermission,
  IPermissionToEdit,
  IPrices,
} from "../../../types/api";
import { toast } from "react-toastify";
import {
  deletePermission,
  editPermission,
} from "../../../api/permissions/permissions";
import TimeInputBatch from "../../../hoc/TimeBatch/TimeBatch";
import { v4 as uuidv4 } from "uuid";
import useTimeBatches from "../../../shared/hooks/useTimeBatches";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { getSwedishAdjectiveColor } from "../../../shared/utility";
interface IEditPermissionPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  permission?: IPermission;
  courts: ICourt[];
  prices: IPrices[];
}

function EditPermissionPopup(props: IEditPermissionPopupProps) {
  const [showHandleDeletePermissionPopup, setShowHandleDeletePermissionPopup] =
    useState(false);

  const initialInsideBatches = [
    {
      id: uuidv4(),
      startTime: "07:00:00",
      endTime: "23:00:00",
      checkedDays: { 0: true, 1: false },
      defaultChecked: true,
      isException: false,
    },
  ];

  const initialOutsideBatches = [
    {
      id: uuidv4(),
      startTime: "07:00:00",
      endTime: "23:00:00",
      checkedDays: { 0: true, 1: false },
      defaultChecked: true,
      isException: false,
    },
  ];

  const [dayBeforeTime, setDayBeforeTime] = useState<string>("17:00:00");

  // const [prices, setPrices] = useState<IPrices[]>();
  const [features, setFeatures] = useState({
    gym: false,
    paymentCard: false,
    ranking: false,
    title: false,
    hidden: false,
  });
  interface SelectedCourts {
    [key: string]: boolean;
  }
  const {
    timeBatches: insideIntervals,
    addTimeBatch: addNewInsideBatch,
    removeTimeBatch: removeInsideBatch,
    setBatches: setInsideIntervals,
    updateStartTime: updateInsideStartTime,
    updateEndTime: updateInsideEndTime,
    updateCheckedDays: updateInsideCheckedDays,
  } = useTimeBatches(initialInsideBatches);

  const {
    timeBatches: outsideIntervals,
    addTimeBatch: addNewOutsideBatch,
    removeTimeBatch: removeOutsideBatch,
    setBatches: setOutsideIntervals,
    updateStartTime: updateOutsideStartTime,
    updateEndTime: updateOutsideEndTime,
    updateCheckedDays: updateOutsideCheckedDays,
  } = useTimeBatches(initialOutsideBatches);

  const {
    timeBatches: insideExceptionIntervals,
    addTimeBatch: addNewInsideExceptionBatch,
    removeTimeBatch: removeInsideExceptionBatch,
    updateStartTime: updateInsideExceptionStartTime,
    updateEndTime: updateInsideExceptionEndTime,
    updateCheckedDays: updateInsideExceptionCheckedDays,
    updateExceptionStartDate: updateInsideExceptionStartDate,
    updateExceptionEndDate: updateInsideExceptionEndDate,
  } = useTimeBatches([]);

  const {
    timeBatches: outsideExceptionIntervals,
    addTimeBatch: addNewOutsideExceptionBatch,
    removeTimeBatch: removeOutsideExceptionBatch,
    updateStartTime: updateOutsideExceptionStartTime,
    updateEndTime: updateOutsideExceptionEndTime,
    updateCheckedDays: updateOutsideExceptionCheckedDays,
    updateExceptionStartDate: updateOutsideExceptionStartDate,
    updateExceptionEndDate: updateOutsideExceptionEndDate,
  } = useTimeBatches([]);

  const [classOption, setClassOption] = useState("all");
  const [selectedCourts, setSelectedCourts] = useState<SelectedCourts>({});

  const [maxBookings, setMaxBookings] = useState<number>(0);
  const maxBookingsOptions = [100, ...Array.from({ length: 11 }, (_, i) => i)];

  const [discount, setDiscount] = useState(0);
  const discountOptions = [0, 25, 50, 75, 100];

  const handleClose = () => {
    setFeatures({
      gym: false,
      paymentCard: false,
      ranking: false,
      title: false,
      hidden: false,
    });
    setClassOption("all");
    setSelectedCourts({});
    insideIntervals.forEach((batch) => removeInsideBatch(batch.id));
    outsideIntervals.forEach((batch) => removeOutsideBatch(batch.id));
    insideExceptionIntervals.forEach((batch) =>
      removeInsideExceptionBatch(batch.id)
    );
    outsideExceptionIntervals.forEach((batch) =>
      removeOutsideExceptionBatch(batch.id)
    );

    props.onClose();
  };

  const setupInsideIntervalsFromPermission = (permission: IPermission) => {
    if (!permission) return [];
    const batchMap = new Map();
    permission.indoor_access.forEach((availability) => {
      const key = `${availability.start_time}-${availability.end_time}`;
      if (batchMap.has(key)) {
        const existingBatch = batchMap.get(key);
        existingBatch.checkedDays[availability.weekday] = true;
      } else {
        batchMap.set(key, {
          id: uuidv4(),
          startTime: availability.start_time,
          endTime: availability.end_time,
          checkedDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            [availability.weekday]: true,
          },
          defaultChecked: false,
          isException: false,
        });
      }
    });

    return Array.from(batchMap.values());
  };

  const handleDeletePermission = async () => {
    if (props.permission === undefined) return;
    try {
      const deleteResponse = await deletePermission(
        props.permission.permission_id
      );
      if (!deleteResponse)
        throw new Error("Något gick fel vid radering av medlemskap");
      toast.success(`Medlemskap: ${props.permission.member_type} borttaget`);
      handleClose();
      props.onConfirm();
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };
  const setupOutsideIntervalsFromPermission = (permission: IPermission) => {
    if (!permission) return [];
    const batchMap = new Map();
    permission.outdoor_access.forEach((availability) => {
      const key = `${availability.start_time}-${availability.end_time}`;
      if (batchMap.has(key)) {
        const existingBatch = batchMap.get(key);
        existingBatch.checkedDays[availability.weekday] = true;
      } else {
        batchMap.set(key, {
          id: uuidv4(),
          startTime: availability.start_time,
          endTime: availability.end_time,
          checkedDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            [availability.weekday]: true,
          },
          defaultChecked: false,
          isException: false,
        });
      }
    });

    return Array.from(batchMap.values());
  };

  useEffect(() => {
    if (props.permission) {
      const insideBatches = setupInsideIntervalsFromPermission(
        props.permission
      );
      const outsideBatches = setupOutsideIntervalsFromPermission(
        props.permission
      );

      props.permission.lesson_access.forEach((courtId) => {
        setSelectedCourts((prev) => ({
          ...prev,
          [courtId]: true,
        }));
      });
      setInsideIntervals(insideBatches);
      setOutsideIntervals(outsideBatches);
    }
  }, [props.permission]);

  useEffect(() => {
    const selectedCourtsList: string[] = [];
    Object.keys(selectedCourts).forEach((courtId) => {
      if (selectedCourts[courtId]) selectedCourtsList.push(courtId);
    });
    if (selectedCourtsList.length === props.courts.length) {
      setClassOption("all");
    } else setClassOption("specific");
  }, [selectedCourts]);

  function handleCheckboxChange(event: any) {
    const { name, checked } = event.target;
    if (name === "searchable")
      setFeatures((prevFeatures) => ({
        ...prevFeatures,
        hidden: !checked,
      }));
    else
      setFeatures((prevFeatures) => ({
        ...prevFeatures,
        [name]: checked,
      }));
  }

  const [member_type, setMemberType] = useState<string>("");

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeValue = e.target.value;
    setDayBeforeTime(timeValue);
  };

  const handleTimeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }
    setDayBeforeTime(time);
  };

  useEffect(() => {
    if (!props.permission) return;

    const {
      gym_access = false,
      payment_card = false,
      ranking = false,
      title = false,
      hidden = false,
      max_amount_of_bookings = 0,
      extra_booking_time = "17:00:00",
      percent_discount_outside_access = "0",
      member_type = "",
    } = props.permission;

    setFeatures({
      gym: gym_access,
      paymentCard: payment_card,
      ranking: ranking,
      title: title,
      hidden: hidden,
    });
    setMemberType(member_type);
    setMaxBookings(max_amount_of_bookings);
    setDayBeforeTime(extra_booking_time);
    setDiscount(Number(percent_discount_outside_access));
  }, [props.permission]);

  const handleEditPermission = async () => {
    if (props.permission === undefined) return;
    const changes: Partial<IPermissionToEdit> = {};

    // Check for changes in features
    if (features.gym !== props.permission.gym_access)
      changes.gym_access = features.gym;
    if (features.paymentCard !== props.permission.payment_card)
      changes.payment_card = features.paymentCard;
    if (features.ranking !== props.permission.ranking)
      changes.ranking = features.ranking;
    if (features.title !== props.permission.title)
      changes.title = features.title;
    if (features.hidden !== props.permission.hidden)
      changes.hidden = features.hidden;

    // Check for changes in other fields
    if (maxBookings !== props.permission.max_amount_of_bookings)
      changes.max_amount_of_bookings = maxBookings;
    if (dayBeforeTime !== props.permission.extra_booking_time)
      changes.extra_booking_time = dayBeforeTime;
    if (discount !== props.permission.percent_discount_outside_access)
      changes.percent_discount_outside_access = discount;

    const selectedCourtIds = Object.keys(selectedCourts).filter(
      (courtId) => selectedCourts[courtId]
    );
    if (
      JSON.stringify(selectedCourtIds) !==
      JSON.stringify(props.permission.lesson_access)
    ) {
      changes.lesson_access = selectedCourtIds;
    }
    // Check for changes in indoor_access
    const indoorAccessChanges = insideIntervals.flatMap((batch) =>
      Object.keys(batch.checkedDays)
        .filter((day) => batch.checkedDays[day])
        .map((weekday) => ({
          weekday,
          start_time: batch.startTime,
          end_time: batch.endTime,
        }))
    );

    if (
      JSON.stringify(indoorAccessChanges) !==
      JSON.stringify(props.permission.indoor_access)
    ) {
      changes.indoor_access = indoorAccessChanges;
    }

    // Check for changes in outdoor_access
    const outdoorAccessChanges = outsideIntervals.flatMap((batch) =>
      Object.keys(batch.checkedDays)
        .filter((day) => batch.checkedDays[day])
        .map((weekday) => ({
          weekday,
          start_time: batch.startTime,
          end_time: batch.endTime,
        }))
    );

    if (
      JSON.stringify(outdoorAccessChanges) !==
      JSON.stringify(props.permission.outdoor_access)
    ) {
      changes.outdoor_access = outdoorAccessChanges;
    }
    if (Object.keys(changes).length > 0) {
      try {
        const editResponse = await editPermission(
          props.permission.permission_id,
          changes
        );
        if (!editResponse)
          throw new Error("Något gick fel vid uppdatering av medlemskap");
        toast.success(`Medlemskap: ${props.permission.member_type} uppdaterat`);
        handleClose();
        props.onConfirm();
      } catch (error: any) {
        toast.error(error.errors?.[0].message);
      }
    } else {
      toast.info("Det har inte gjorts några ändringar");
    }
  };

  const toggleCourtSelection = (courtId: string) => {
    setSelectedCourts((prev) => ({
      ...prev,
      [courtId]: !prev[courtId],
    }));
  };
  const handleSelectAllCourts = () => {
    const allCourtsSelected: { [key: string]: boolean } = props.courts.reduce(
      (acc, court) => {
        acc[court.court_id] = true;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
    setSelectedCourts(allCourtsSelected);
  };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={<span className="text-m">Redigera {member_type}</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleEditPermission();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid inomhus
              </label>
              {insideIntervals.length === 0 ? (
                <div className="add-time-batch-button-wrapper">
                  <a
                    className="add-time-batch-button"
                    onClick={() => addNewInsideBatch(true)}
                  >
                    <span className="text-s">Lägg till tillgänglighet</span>
                  </a>
                </div>
              ) : (
                insideIntervals.map((batch, index) => (
                  <TimeInputBatch
                    id={batch.id}
                    key={batch.id}
                    startTime={batch.startTime || "07:00:00"}
                    endTime={batch.endTime || "23:00:00"}
                    checkedDays={batch.checkedDays}
                    defaultChecked={false}
                    isLast={index === insideIntervals.length - 1}
                    isFirst={index === 0}
                    isException={false}
                    onAdd={() => addNewInsideBatch(false)}
                    onRemove={() => removeInsideBatch(batch.id)}
                    onStartTimeChange={(newStartTime) =>
                      updateInsideStartTime(batch.id, newStartTime)
                    }
                    onEndTimeChange={(newEndTime) =>
                      updateInsideEndTime(batch.id, newEndTime)
                    }
                    onDayChange={(dayString) => {
                      updateInsideCheckedDays(batch.id, dayString);
                    }}
                  />
                ))
              )}
              {insideExceptionIntervals.length === 0 ? null : (
                // <div className="add-time-batch-button-wrapper">
                //   <a
                //     className="add-time-batch-button"
                //     onClick={() => addNewInsideExceptionBatch(true)}
                //   >
                //     <span className="text-s">Lägg till undantag</span>
                //   </a>
                // </div>
                <div className="time-batch-looper">
                  <span className="text-s">
                    Undantag (kommer skriva över grundinställningar)
                  </span>
                  {insideExceptionIntervals.map((batch, index) => (
                    <TimeInputBatch
                      id={batch.id}
                      key={batch.id}
                      startTime={batch.startTime}
                      endTime={batch.endTime}
                      checkedDays={batch.checkedDays}
                      defaultChecked={false}
                      isLast={index === insideExceptionIntervals.length - 1}
                      isFirst={false}
                      isException={true}
                      exceptionStartDate={batch.exceptionStartDate}
                      exceptionEndDate={batch.exceptionEndDate}
                      onAdd={() =>
                        addNewInsideExceptionBatch(
                          true,
                          true,
                          new Date(),
                          new Date()
                        )
                      }
                      onRemove={() => removeInsideExceptionBatch(batch.id)}
                      onStartTimeChange={(newStartTime) =>
                        updateInsideExceptionStartTime(batch.id, newStartTime)
                      }
                      onEndTimeChange={(newEndTime) =>
                        updateInsideExceptionEndTime(batch.id, newEndTime)
                      }
                      onExceptionEndDateChange={(newEndDate) =>
                        updateInsideExceptionEndDate(batch.id, newEndDate)
                      }
                      onExceptionStartDateChange={(newStartDate) =>
                        updateInsideExceptionStartDate(batch.id, newStartDate)
                      }
                      onDayChange={(dayString) => {
                        updateInsideExceptionCheckedDays(batch.id, dayString);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid utomhus
              </label>
              {outsideIntervals.length === 0 ? (
                <div className="add-time-batch-button-wrapper">
                  <a
                    className="add-time-batch-button"
                    onClick={() => addNewOutsideBatch(true)}
                  >
                    <span className="text-s">Lägg till tillgänglighet</span>
                  </a>
                </div>
              ) : (
                outsideIntervals.map((batch, index) => (
                  <TimeInputBatch
                    id={batch.id}
                    key={batch.id}
                    startTime={batch.startTime || "07:00:00"}
                    endTime={batch.endTime || "23:00:00"}
                    checkedDays={batch.checkedDays}
                    defaultChecked={false}
                    isLast={index === outsideIntervals.length - 1}
                    isFirst={index === 0}
                    isException={batch.isException}
                    onAdd={() => addNewOutsideBatch(false)}
                    onRemove={() => removeOutsideBatch(batch.id)}
                    onStartTimeChange={(newStartTime) =>
                      updateOutsideStartTime(batch.id, newStartTime)
                    }
                    onEndTimeChange={(newEndTime) =>
                      updateOutsideEndTime(batch.id, newEndTime)
                    }
                    onDayChange={(dayString) => {
                      updateOutsideCheckedDays(batch.id, dayString);
                    }}
                  />
                ))
              )}
              {outsideExceptionIntervals.length === 0 ? null : (
                // <div className="add-time-batch-button-wrapper">
                //   <a
                //     className="add-time-batch-button"
                //     onClick={() => addNewOutsideExceptionBatch(false)}
                //   >
                //     <span className="text-s">Lägg till undantag</span>
                //   </a>
                // </div>
                <div className="time-batch-looper">
                  <span className="text-s">
                    Undantag (kommer skriva över grundinställningar)
                  </span>
                  {outsideExceptionIntervals.map((batch, index) => {
                    return (
                      <TimeInputBatch
                        id={batch.id}
                        key={batch.id}
                        startTime={batch.startTime || "07:00:00"}
                        endTime={batch.endTime || "23:00:00"}
                        checkedDays={batch.checkedDays}
                        defaultChecked={false}
                        isLast={index === outsideExceptionIntervals.length - 1}
                        isFirst={false}
                        isException={true}
                        exceptionStartDate={batch.exceptionStartDate}
                        exceptionEndDate={batch.exceptionEndDate}
                        onAdd={() =>
                          addNewOutsideExceptionBatch(
                            false,
                            true,
                            new Date(),
                            new Date()
                          )
                        }
                        onRemove={() => removeOutsideExceptionBatch(batch.id)}
                        onStartTimeChange={(newStartTime) =>
                          updateOutsideExceptionStartTime(
                            batch.id,
                            newStartTime
                          )
                        }
                        onEndTimeChange={(newEndTime) =>
                          updateOutsideExceptionEndTime(batch.id, newEndTime)
                        }
                        onExceptionEndDateChange={(newEndDate) =>
                          updateOutsideExceptionEndDate(batch.id, newEndDate)
                        }
                        onExceptionStartDateChange={(newStartDate) =>
                          updateOutsideExceptionStartDate(
                            batch.id,
                            newStartDate
                          )
                        }
                        onDayChange={(dayString) => {
                          updateOutsideExceptionCheckedDays(
                            batch.id,
                            dayString
                          );
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="dayBeforeTime" className="text-s">
                Extra bokning
              </label>
              <div className="general-popup-flex-left">
                <input
                  className="time-batch-input text-m"
                  type="time"
                  name="dayBeforeTime"
                  value={dayBeforeTime}
                  onChange={(e) => handleTimeChange(e)}
                  onBlur={(e) => handleTimeBlur(e)}
                />
                <span className="text-m"> Dagen före aktiv bokning</span>
              </div>
            </div>
            <div className="general-popup-form-field">
              <span className="text-s">Övrigt</span>

              <div className="general-popup-flex-between">
                <span className="text-m"> Gym </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="gym"
                    checked={features.gym}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Betalkort </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="paymentCard"
                    checked={features.paymentCard}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Ranking </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="ranking"
                    checked={features.ranking}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Titel </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="title"
                    checked={features.title}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m"> Sökbar för medlemmar </span>
                <label className="toggle text-m">
                  <input
                    className="text-m"
                    type="checkbox"
                    name="searchable"
                    checked={!features.hidden}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Lektioner
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="classes"
                  className="time-batch-select long text-m"
                  value={classOption}
                  onChange={(e) => {
                    setClassOption(e.target.value);
                    if (e.target.value === "all") {
                      handleSelectAllCourts();
                    }
                  }}
                >
                  <option value="all">Alla banor</option>
                  <option value="specific">Specifika banor</option>
                </select>
              </div>

              {classOption === "specific" &&
                props.courts.map((court, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">{court.name}</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={selectedCourts[court.court_id] || false}
                        onChange={() => toggleCourtSelection(court.court_id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="general-popup-form-field">
              <span className="text-s">Rabatt som medspelare</span>
              <div className="general-popup-flex-between">
                <label htmlFor="discount" className="text-m">
                  Tider utanför spelrätten
                </label>
                <div className="discount-price-wrapper">
                  {props.prices.map((price, index) => {
                    const priceName = getSwedishAdjectiveColor(price.color);
                    const discountOption = discountOptions.find(
                      (option) => option === Number(discount)
                    );
                    let dividor = discountOption ? discountOption : 0;

                    const priceValue = Math.floor(
                      price.price - (price.price * dividor) / 100
                    );

                    return (
                      <span className="text-m" key={index}>
                        {priceName + ": " + priceValue + " kr"}
                      </span>
                    );
                  })}
                  <select
                    id="discount"
                    className="time-batch-select text-m"
                    value={discount}
                    onChange={(e) => setDiscount(Number(e.target.value))}
                  >
                    {discountOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} %
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="maxBookings" className="text-s">
                Max antal bokningar i veckan
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="maxBookings"
                  className="time-batch-select long text-m"
                  value={maxBookings}
                  onChange={(e) => setMaxBookings(Number(e.target.value))}
                >
                  {maxBookingsOptions.map((option) => {
                    const infinite = option === 100;
                    return infinite ? (
                      <option key={option} value={option}>
                        Obegränsat
                      </option>
                    ) : (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper ">
            <button
              className="deleteButton"
              type="button"
              onClick={() => setShowHandleDeletePermissionPopup(true)}
            >
              Radera medlemskap permanent
            </button>
            <button type="submit" className="text-m">
              Uppdatera uppgifter
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeletePermissionPopup}
        onClose={() => setShowHandleDeletePermissionPopup(false)}
        onConfirm={() => handleDeletePermission()}
        questionText={`Är du säker på att du vill radera ${props.permission?.member_type}?`}
        confirmText="Radera medlemskap"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditPermissionPopup;
