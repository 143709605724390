import { getChartDataQueryFn } from "./chartQueries";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";

import { ICourtType } from "../../types/api";

export const getChartData = async (
  dates: string[],
  courtType: ICourtType,
  lag: number
) => {
  const { query, variables, inputs } = getChartDataQueryFn(
    dates,
    courtType,
    lag
  );

  const myQuery = `query myQuery(${inputs}) {
          ${query}
    }`;

  try {
    const getChartResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getChartResponse.data.getChartData;
    if ("message" in response) {
      toast.error(`${response.message}`);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(`${error.errors[0].message}`);
    return false;
  }
};
