import "./ForceChangePassword.scss";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import BaseAuthLayout from "../../hoc/BaseAuthLayout/BaseAuthLayout";
import passLock from "../../assets/images/pass-lock.svg";
import memberSvg from "../../assets/images/member.svg";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";
import { checkPassword } from "../../shared/utility";
interface forceChangePasswordProps {
  authenticate: () => void;
  isLoading: boolean;
}

const ForceChangePassword = (props: forceChangePasswordProps) => {
  const location = useLocation();
  const { email } = location.state;

  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] =
    useState(false);

  const handleChangePassword = async (e: any) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast.error("Nya lösenord matchar inte!");
      return;
    }
    const check = checkPassword(newPassword);

    if (check.length > 0) {
      toast.error(check[0].toString());
      return;
    }

    try {
      const user = await Auth.signIn(email, oldPassword);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
        );
        toast.success("Lösenordet har ändrats!");
        props.authenticate();
        navigate("/");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <BaseAuthLayout header="Välj ditt lösenord">
      <div className="login-wrapper">
        <form className="login-form" onSubmit={handleChangePassword}>
          <div className="line-divider marg-30"></div>
          <div className="general-auth-input-wrapper">
            <div className="general-label-input">
              <label htmlFor="username" className="input-label">
                <img alt="member-icon" src={memberSvg} />
                <span className="text-s secondary-text">
                  Användarnamn (Email)
                </span>
              </label>
              <input
                type="string"
                placeholder=""
                id="username"
                value={email}
                disabled
              />
            </div>
            <div className="general-label-input">
              <label htmlFor="oldPassword" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">Gammalt lösenord*</span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={oldPasswordVisible ? "text" : "password"}
                  placeholder=""
                  id="oldPassword"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <img
                  src={oldPasswordVisible ? eyeSlash : eye}
                  alt="toggle visibility"
                  className="password-toggle-icon"
                  onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                />
              </div>
            </div>
            <div className="general-label-input">
              <div className="general-label-input-flex">
                <span className="text-s secondary-text">
                  Lösenordet måste vara minst 8 karaktärer långt samt innehålla
                  minst ett tecken från varje kategori:
                </span>
                <span className="text-s secondary-text">(1) versaler</span>
                <span className="text-s secondary-text">(2) gemener</span>
                <span className="text-s secondary-text">(3) siffror</span>
                <span className="text-s secondary-text">
                  (4) icke alfanumeriska tecken
                </span>
              </div>
            </div>
            <div className="general-label-input">
              <label htmlFor="newPassword" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">Nytt Lösenord*</span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  placeholder=""
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img
                  src={newPasswordVisible ? eyeSlash : eye}
                  alt="toggle visibility"
                  className="password-toggle-icon"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                />
              </div>
            </div>
            <div className="general-label-input">
              <label htmlFor="confirmNewPassword" className="input-label">
                <img alt="password-lock" src={passLock} />
                <span className="text-s secondary-text">
                  Bekräfta lösenord*
                </span>
              </label>
              <div className="password-input-wrapper">
                <input
                  type={confirmNewPasswordVisible ? "text" : "password"}
                  placeholder=""
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <img
                  src={confirmNewPasswordVisible ? eyeSlash : eye}
                  alt="toggle visibility"
                  className="password-toggle-icon"
                  onClick={() =>
                    setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
                  }
                />
              </div>
            </div>
          </div>
          <div className="line-divider marg-30"></div>
          <div className="login-bottom-wrapper">
            <div className="text-s"></div>
            <button type="submit" className="wide text-m">
              Skapa konto och logga in
            </button>
          </div>
        </form>
      </div>
    </BaseAuthLayout>
  );
};

export default ForceChangePassword;
