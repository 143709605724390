export const getFacilityOpeningTimesQueryFn = () => {
  const query = `getFacility {
    ... on Facility {
      __typename
      default_hours {
        opening_hours
        closing_hours
        weekday
      }
      facilityID
      seasonal_hours {
        alias
        end_date
        start_date
        weekday_hours {
          closing_hours
          weekday
          opening_hours
        }
      }
      special_days {
        alias
        closing_hours
        date
        opening_hours
      }
    }
    ... on Error {
      code
      message
    }
  }`;

  return {
    query
  };
};
