import { Locale } from "date-fns";
import { sv } from "date-fns/locale";

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeLocale = (locale: Locale): Locale => {
  const capitalizedLocale = { ...locale };

  const originalMonth = locale.localize.month;
  const originalDay = locale.localize.day;

  capitalizedLocale.localize.month = (month, options) => {
    const monthName = originalMonth(month, options);
    return capitalizeFirstLetter(monthName);
  };

  capitalizedLocale.localize.day = (day, options) => {
    const dayName = originalDay(day, options);
    return capitalizeFirstLetter(dayName);
  };

  return capitalizedLocale;
};

const capitalizedSv = capitalizeLocale(sv);

export default capitalizedSv;
