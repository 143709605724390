import "./AdminHandleBookings.scss";
import { useEffect, useRef, useState } from "react";
import {
  IActivity,
  IBooking,
  ICancelBookingInputType,
  ICoach,
  ICourt,
  ICourtType,
  IDailyComment,
  IInitiateBookingResponse,
  IInitiateBookingSpec,
  IPermission,
  IPrices,
  IRehabSummary,
  ISummary,
  ISummaryItem,
  IUser,
} from "../../types/api";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import {
  capitalizeWords,
  hardcodeDateIsInRangeApr20Aug21,
} from "../../shared/utility";
import { Auth } from "aws-amplify";
import { Id, toast } from "react-toastify";
import { CognitoUser } from "../../types/cognito";
import CreateBookingPopup from "../../containers/Bookings/CreateBookingPopup/CreateBookingPopup";
import { cancelBooking, initiateBooking } from "../../api/bookings/bookings";
import { BookingEnum, StatusEnum } from "../../shared/enums";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import { formatDate, formatToIsoDate } from "../../shared/dateUtils";
import { isBefore, startOfDay, parseISO, subDays } from "date-fns";
import Spinner from "../../components/UI/Spinner/Spinner";
import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
import ChooseUserPopup from "../../containers/Admin/ChooseUserPopup/ChooseUserPopup";
import AdminHandleBookingPopup from "../../containers/Admin/AdminHandleBookingPopup/AdminHandleBookingPopup";
import CreateActivityPopup from "../../containers/Activities/CreateActivityPopup/CreateActivityPopup";
import { bookActivity } from "../../api/activities/activities";
import BookActivityPopup from "../../containers/Activities/BookHandleActivityPopup/BookHandleActivityPopup";
import HandleRehabTimesPopup from "../../containers/Rehab/HandleRehabTimesPopup/HandleRehabTimesPopup";
import SlotHistoryPopup from "../../containers/SlotHistory/SlotHistoryPopup/SlotHistoryPopup";
import MoveBookingPopup from "../../containers/Bookings/MoveBookingPopup/MoveBookingPopup";

interface AdminHandleBookingsProps {}

function AdminHandleBookings(props: AdminHandleBookingsProps) {
  const [selectedSlotToHandle, setSelectedSlotToHandle] =
    useState<ISummaryItem>();
  const [selectedSlotIsSemiNoShow, setSelectedSlotIsSemiNoShow] =
    useState<boolean>(false);
  const [showCreateActivityPopup, setShowCreateActivityPopup] =
    useState<boolean>(false);
  const [showChooseUserPopup, setShowChooseUserPopup] =
    useState<boolean>(false);
  const [showHandleBookingPopup, setShowHandleBookingPopup] =
    useState<boolean>(false);
  const [showSlotHistoryPopup, setShowSlotHistoryPopup] =
    useState<boolean>(false);
  const [showMoveBookingPopup, setShowMoveBookingPopup] =
    useState<boolean>(false);
  const [showMoveActivitySlotPopup, setShowMoveActivitySlotPopup] =
    useState<boolean>(false);
  const [rehabSummary, setRehabSummary] = useState<IRehabSummary>();
  const [showBookRehabPopup, setShowBookRehabPopup] = useState<boolean>(false);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [timeAndCourtString, setTimeAndCourtString] = useState<string>("");
  const [chosenUser, setChosenUser] = useState<IUser>();
  const [userSummaries, setUserSummaries] = useState<ISummary[]>([]);
  const [coaches, setCoaches] = useState<ICoach[]>();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [latestCoPlayersForUser, setLatestCoPlayersForUser] = useState<IUser[]>(
    []
  );
  const [toastIdHistory, setToastIdHistory] = useState<Id | null>(null);
  const [toastIdMarkField, setToastIdMarkField] = useState<Id | null>(null);
  const [toastIdMoveBooking, setToastIdMoveBooking] = useState<Id | null>(null);

  const [searchForCoachOnly, setSearchForCoachOnly] = useState<boolean>(false);

  const [
    selectedSlotsForActivityCreation,
    setSelectedSlotsForActivityCreation,
  ] = useState<{ court_id: string; start_time: string; end_time: string }[]>(
    []
  );
  const [selectedBookedSlotForMovement, setSelectedBookedSlotForMovement] =
    useState<ISummaryItem>();
  const [selectedEmptySlotForMovement, setSelectedEmptySlotForMovement] =
    useState<ISummaryItem>();
  const [selectingBookedSlotForMovement, setSelectingBookedSlotForMovement] =
    useState<boolean>(false);
  const [selectingActivitySlots, setSelectingActivitySlots] =
    useState<boolean>(false);
  const [selectingForHistory, setSelectingForHistory] =
    useState<boolean>(false);
  const [selectedSlotForHistory, setSelectedSlotForHistory] =
    useState<ISummaryItem>();
  const [
    selectedSlotForActivitySlotMovement,
    setSelectedSlotForActivitySlotMovement,
  ] = useState<ISummaryItem>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isBookingInitiated, setIsBookingInitiated] = useState(false);
  const isBookingInitiatedRef = useRef(isBookingInitiated);
  useEffect(() => {
    isBookingInitiatedRef.current = isBookingInitiated;
  }, [isBookingInitiated]);
  const [activeActivitiesForUser, setActiveActivitiesForUser] = useState<
    IActivity[]
  >([]);
  const [prices, setPrices] = useState<IPrices[]>([]);
  const [showMaxBookingsWarningPopup, setShowMaxBookingsWarningPopup] =
    useState(false);
  const [showNotAvailableForMember, setShowNotAvailableForMember] =
    useState(false);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [memberType, setMemberType] = useState<IPermission>();
  const [searchString, setSearchString] = useState<string>("");
  const [currentSelectedCoach, setCurrentSelectedCoach] = useState<ICoach>();
  const [currentCourtType, setCurrentCourtType] =
    useState<ICourtType>("INDOOR");
  const [bookingToCancel, setBookingToCancel] = useState<IBooking>();
  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [activeBookingsForUser, setActiveBookingsForUser] = useState<
    IBooking[]
  >([]);
  const [showBookExtraPopup, setShowBookExtraPopup] = useState<boolean>(false);

  const [showBookActivityPopup, setShowBookActivityPopup] =
    useState<boolean>(false);

  const [courts, setCourts] = useState<ICourt[]>([]);
  const [summaries, setSummaries] = useState<ISummary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [filterDate, setFilterDate] = useState(() => {
    const storedDate = localStorage.getItem("filterDate");
    const now = formatDate(new Date(), "yyyy-MM-dd");

    return storedDate && new Date(storedDate) >= new Date(now)
      ? new Date(storedDate)
      : new Date(now);
  });

  const [currentChosenSummaryItem, setCurrentChosenSummaryItem] =
    useState<ISummaryItem>();
  const [currentChosenCourt, setCurrentChosenCourt] = useState<ICourt>(
    courts[0]
  );
  const [bookingResponse, setBookingResponse] =
    useState<IInitiateBookingResponse>();
  const [showCreateBookingPopup, setShowCreateBookingPopup] =
    useState<boolean>(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("filterDate", formatToIsoDate(filterDate));
    setSelectingActivitySlots(false);
    setSelectedSlotsForActivityCreation([]);
    setCurrentChosenSummaryItem(undefined);
  }, [filterDate]);

  const fetchChosenItems = async ({
    fetchCourts = false,
    fetchPrices = false,
    fetchMemberType = false,
    fetchPermissions = false,
    fetchCoaches = false,
    fetchSlots = false,
    fetchSlotsByMember = false,
    fetchActivities = false,
    fetchLatestCoPlayers = false,
    fetchRehabSlotsAdmin = false,
    fetchActiveBookingsByMemberId = false,
    fetchActiveActivitiesByMemberId = false,
  }) => {
    setLoading(true);
    if (!user) return;
    setCurrentChosenSummaryItem(undefined);

    const permission_id = permissions.find(
      (permission) => permission.member_type === chosenUser?.member_type
    )?.permission_id;

    try {
      const response = await combinedQuery({
        active: true,
        requestor: "admin",
        date: formatToIsoDate(filterDate),
        member_id: chosenUser?.member_id,
        permission_id: permission_id,
        court_type: currentCourtType,
        fetchCourts: fetchCourts,
        fetchRehabSlotsAdmin: fetchRehabSlotsAdmin,
        fetchPrices: fetchPrices,
        fetchMemberType: fetchMemberType,
        fetchPermissions: fetchPermissions,
        fetchCoaches: fetchCoaches,
        fetchSlots: fetchSlots,
        fetchSlotsByMember: fetchSlotsByMember,
        fetchActivities: fetchActivities,
        fetchLatestCoPlayers: fetchLatestCoPlayers,
        fetchActiveBookingsByMemberId: fetchActiveBookingsByMemberId,
        fetchActiveActivities: fetchActiveActivitiesByMemberId,
      });
      if (
        fetchMemberType &&
        response.memberType &&
        !("message" in response.memberType)
      ) {
        setMemberType(response.memberType);
      }

      if (
        fetchActiveBookingsByMemberId &&
        response.activeBookings &&
        !("message" in response.activeBookings)
      ) {
        setActiveBookingsForUser(response.activeBookings);
      }

      if (
        fetchActiveActivitiesByMemberId &&
        response.activeActivities &&
        !("message" in response.activeActivities)
      ) {
        setActiveActivitiesForUser(response.activeActivities);
      }

      if (fetchPrices && response.prices && !("message" in response.prices)) {
        setPrices(response.prices);
      }
      if (
        fetchRehabSlotsAdmin &&
        response.rehabSlots &&
        !("message" in response.rehabSlots)
      ) {
        setRehabSummary(response.rehabSlots);
      }
      if (
        fetchPermissions &&
        response.permissions &&
        !("message" in response.permissions)
      ) {
        setPermissions(response.permissions);
      }
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }

      if (
        fetchActivities &&
        response.activities &&
        !("message" in response.activities)
      ) {
        setActivities(response.activities);
      }
      if (
        fetchCoaches &&
        response.coaches &&
        !("message" in response.coaches)
      ) {
        setCoaches(response.coaches);
      }
      if (fetchSlots && response.slots && !("message" in response.slots)) {
        setSummaries(response.slots);
      }
      if (
        fetchSlotsByMember &&
        response.slotsByMember &&
        !("message" in response.slotsByMember)
      ) {
        setUserSummaries(response.slotsByMember);
      }
      if (
        fetchLatestCoPlayers &&
        response.latestCoPlayers &&
        !("message" in response.latestCoPlayers)
      ) {
        setLatestCoPlayersForUser(response.latestCoPlayers);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let firstRun = true;

    const fetchItemsIfNeeded = () => {
      if (
        !isBookingInitiatedRef.current &&
        !chosenUser &&
        showBookActivityPopup === false
      ) {
        fetchChosenItems({ fetchSlots: true });
      }

      timeoutRef.current = setTimeout(fetchItemsIfNeeded, 60000);
    };

    const startTimeout = () => {
      timeoutRef.current = setTimeout(fetchItemsIfNeeded, 60000);
    };

    if (firstRun) {
      firstRun = false;
      startTimeout();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [filterDate, currentCourtType, user, chosenUser, showBookActivityPopup]);

  useEffect(() => {
    if (user) {
      fetchChosenItems({
        fetchCourts: true,
        fetchPrices: true,
        fetchMemberType: true,
        fetchCoaches: true,
        fetchSlots: true,
        fetchPermissions: true,
        fetchActivities: true,
        fetchRehabSlotsAdmin: true,
      });
    }
    return () => {};
  }, [user]);

  useEffect(() => {
    let chosenItems: {} = {
      fetchRehabSlotsAdmin: true,
      fetchDailyComment: true,
    };
    if (chosenUser) {
      chosenItems = {
        ...chosenItems,
        fetchSlotsByMember: true,
        fetchActiveBookingsByMemberId: true,
        fetchActiveActivitiesByMemberId: true,
      };
    } else {
      chosenItems = {
        ...chosenItems,
        fetchSlots: true,
      };
    }
    fetchChosenItems(chosenItems);
  }, [filterDate, currentCourtType]);

  useEffect(() => {
    fetchChosenItems({
      fetchSlotsByMember: true,
      fetchLatestCoPlayers: true,
      fetchActiveBookingsByMemberId: true,
      fetchActiveActivitiesByMemberId: true,
    });
  }, [chosenUser]);

  const handleBookActivity = async () => {
    if (!chosenUser || !currentChosenSummaryItem) return;
    const member_id = chosenUser.member_id;
    const datetime_start =
      formatDate(filterDate, "yyyy-MM-dd").slice(0, 10) +
      "T" +
      currentChosenSummaryItem.start_time;
    const court_id = currentChosenCourt.court_id;
    try {
      const response = await bookActivity(
        member_id,
        court_id,
        datetime_start,
        "admin"
      );
      if (response && !("message" in response)) {
        toast.success("Bokning skapad");
        fetchChosenItems({
          fetchSlotsByMember: true,
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const checkCanHaveAnotherBooking = (datetime_start: string) => {
    const permission = permissions.find(
      (permission) => permission.member_type === chosenUser?.member_type
    );
    if (!permission || !currentChosenSummaryItem || !chosenUser) return false;
    const [extraHours, extraMinutes, extraSeconds] =
      permission.extra_booking_time.split(":");

    const today = new Date();
    let allBookings = 0;
    activeBookingsForUser.forEach(() => {
      allBookings++;
    });
    activeActivitiesForUser.forEach(() => {
      allBookings++;
    });

    if (allBookings === 0) return true;
    if (allBookings > 1) {
      return false;
    }
    if (allBookings === 1 && permission.extra_booking_time === "23:59:00") {
      return false;
    } else if (
      allBookings === 1 &&
      permission.extra_booking_time !== "23:59:00"
    ) {
      const [hours, minutes, seconds] = datetime_start.split(":");

      const selectedBookingDate = new Date(filterDate);
      selectedBookingDate.setHours(
        Number(hours),
        Number(minutes),
        Number(seconds),
        0
      );
      const todayExtraBookingTime = new Date(today);
      todayExtraBookingTime.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );

      const tomorrow = subDays(today, -1);
      tomorrow.setHours(23, 59, 0, 0);
      const todayLateBookingTime = new Date(today);
      todayLateBookingTime.setHours(23, 59, 0, 0);

      if (today > todayExtraBookingTime && selectedBookingDate < tomorrow) {
        return true;
      }
      if (
        today < todayExtraBookingTime &&
        selectedBookingDate < todayLateBookingTime
      ) {
        return true;
      }
    }
    if (allBookings === 1 && activeBookingsForUser.length > 0) {
      //case1
      const booking = activeBookingsForUser[0];
      const bookingDate = parseISO(booking.datetime_start);
      const oneDayBeforeBooking = subDays(bookingDate, 1);

      const extraBookingTimeDayBeforeBooking = new Date(oneDayBeforeBooking);
      extraBookingTimeDayBeforeBooking.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );
      if (extraBookingTimeDayBeforeBooking < today) return true;
    } else if (allBookings === 1 && activeActivitiesForUser.length > 0) {
      const activity = activeActivitiesForUser[0];
      const activityDate = parseISO(activity.datetime_start);
      const oneDayBeforeActivity = subDays(activityDate, 1);

      const extraBookingTimeDayBeforeBooking = new Date(oneDayBeforeActivity);

      extraBookingTimeDayBeforeBooking.setHours(
        Number(extraHours),
        Number(extraMinutes),
        Number(extraSeconds),
        0
      );
      if (extraBookingTimeDayBeforeBooking < today) return true;
    }
    return false;
  };
  const handleCancelBooking = async (booking: IBooking) => {
    setBookingToCancel(undefined);
    const coachToCancle = booking.coach_id.length > 0 ? booking.coach_id : [];
    const bookingToCancel: ICancelBookingInputType = {
      court_id: booking.court_id,
      datetime_start: booking.datetime_start,
      booking_id: booking.booking_id,
      slot_index: booking.slot_index,
      coach_id: coachToCancle,
    };
    try {
      const cancelBookingResponse = await cancelBooking(
        bookingToCancel,
        "admin"
      );
      if (cancelBookingResponse) {
        toast.success("Bokning avbokad");
        fetchChosenItems({
          fetchSlots: true,
        });
      }
    } catch (error: any) {
      toast.error("Error cancelling booking" + error);
    }
  };

  const handleInitiateBooking = async () => {
    if (!chosenUser || !currentChosenSummaryItem) {
      toast.error("Välj en användare");
      return;
    }
    setShowCreateBookingPopup(true);
    const dateTimeString =
      formatDate(filterDate, "yyyy-MM-dd") +
      "T" +
      currentChosenSummaryItem.start_time;

    const bookingToInitiate: IInitiateBookingSpec = {
      court_id: currentChosenCourt.court_id,
      datetime_start: dateTimeString,
      member_id: chosenUser?.member_id,
      booking_type: BookingEnum.CONTRACT,
    };
    try {
      const initiateBookingResponse = await initiateBooking(bookingToInitiate);
      if (initiateBookingResponse.errors) {
        setShowCreateBookingPopup(false);
      }
      if (initiateBookingResponse) {
        setBookingResponse(initiateBookingResponse);
      }
    } catch (error) {
      toast.error("Error creating booking" + error);
      setShowCreateBookingPopup(false);
    }
  };
  useEffect(() => {
    if (currentChosenSummaryItem) {
      setTimeAndCourtString(
        `${currentChosenSummaryItem?.start_time.substring(
          0,
          5
        )} - ${currentChosenSummaryItem?.end_time.substring(0, 5)}  ${
          currentChosenCourt.name
        }`
      );
    } else setTimeAndCourtString("");
  }, [currentChosenSummaryItem]);

  const handleConfirmFromBooking = () => {
    setChosenUser(undefined);
    setUserSummaries([]);
    setCurrentSelectedCoach(undefined);
    setBookingResponse(undefined);
    fetchChosenItems({
      fetchSlots: true,
    });
  };

  useEffect(() => {
    if (
      currentChosenSummaryItem &&
      currentChosenSummaryItem.activity === null
    ) {
      if (chosenUser === undefined) {
        setCurrentChosenSummaryItem(undefined);
        setShowChooseUserPopup(true);
        return;
      }
      if (currentChosenSummaryItem.available_for_member_type === false) {
        setShowNotAvailableForMember(true);
        return;
      }
      if (checkCanHaveAnotherBooking(currentChosenSummaryItem.start_time)) {
        handleInitiateBooking();
        return;
      } else {
        setShowMaxBookingsWarningPopup(true);
        return;
      }
    } else if (
      currentChosenSummaryItem &&
      currentChosenSummaryItem.activity !== null
    ) {
      setShowBookActivityPopup(true);
    }
  }, [currentChosenSummaryItem]);

  const handleSlotClick = (slot: ISummaryItem, court: ICourt) => {
    if (selectingBookedSlotForMovement) {
      if (selectedBookedSlotForMovement) {
        if (toastIdMoveBooking) {
          toast.dismiss(toastIdMoveBooking);
          setToastIdMoveBooking(null);
        }
        setSelectedEmptySlotForMovement(slot);
        setShowMoveBookingPopup(true);
        setCurrentChosenCourt(court);
      }
      return;
    } else if (selectingForHistory) {
      setSelectedSlotForHistory(slot);
      setCurrentChosenCourt(court);
      setShowSlotHistoryPopup(true);
      return;
    } else if (selectingActivitySlots) {
      setSelectedSlotsForActivityCreation((prevSelectedSlots) => {
        const isSlotSelected = prevSelectedSlots.some(
          (selectedSlot) =>
            selectedSlot.court_id === court.court_id &&
            selectedSlot.start_time === slot.start_time
        );
        if (isSlotSelected) {
          return prevSelectedSlots.filter(
            (selectedSlot) =>
              selectedSlot.court_id !== court.court_id ||
              selectedSlot.start_time !== slot.start_time
          );
        } else {
          return [
            ...prevSelectedSlots,
            {
              court_id: court.court_id,
              start_time: slot.start_time,
              end_time: slot.end_time,
            },
          ];
        }
      });
    } else {
      setCurrentChosenSummaryItem(slot);
      setCurrentChosenCourt(court);
    }
  };
  const handleBookedSlotClick = (
    slot: any,
    court: ICourt,
    isSemiNoShow: boolean
  ) => {
    if (selectingBookedSlotForMovement) {
      if (slot.booking[0].status === StatusEnum.SEMI_NO_SHOW) {
        if (selectedBookedSlotForMovement) {
          setSelectedEmptySlotForMovement(slot);
          setCurrentChosenCourt(court);
          setShowMoveBookingPopup(true);
          if (toastIdMoveBooking) {
            toast.dismiss(toastIdMoveBooking);
          }
          return;
        }
        return;
      }
      setSelectedBookedSlotForMovement(slot);
      if (toastIdMoveBooking) {
        toast.update(toastIdMoveBooking, {
          render: "Välj en obokad tid att flytta till",
        });
      }

      return;
    } else if (selectingForHistory) {
      setSelectedSlotForHistory(slot);
      setCurrentChosenCourt(court);
      setShowSlotHistoryPopup(true);
      return;
    } else if (selectingActivitySlots) {
      setSelectedSlotsForActivityCreation((prevSelectedSlots) => {
        const isSlotSelected = prevSelectedSlots.some(
          (selectedSlot) =>
            selectedSlot.court_id === court.court_id &&
            selectedSlot.start_time === slot.start_time
        );
        if (isSlotSelected) {
          return prevSelectedSlots.filter(
            (selectedSlot) =>
              selectedSlot.court_id !== court.court_id ||
              selectedSlot.start_time !== slot.start_time
          );
        } else {
          return [
            ...prevSelectedSlots,
            {
              court_id: court.court_id,
              start_time: slot.start_time,
              end_time: slot.end_time,
            },
          ];
        }
      });
    } else {
      setSelectedSlotToHandle(slot);
      setShowHandleBookingPopup(true);
      setSelectedSlotIsSemiNoShow(isSemiNoShow);
    }
  };
  const handleActivitySlotClick = (slot: any, court: ICourt) => {
    if (selectingBookedSlotForMovement) {
      toast.error("Bokning kan inte flyttas till en aktivitet");
      return;
    }
    if (selectingForHistory) {
      setSelectedSlotForHistory(slot);
      setCurrentChosenCourt(court);
      setShowSlotHistoryPopup(true);
      return;
    }
    setCurrentChosenSummaryItem(slot);
    setCurrentChosenCourt(court);
  };

  const handleToggleCourtType = (type: "INDOOR" | "OUTDOOR") => {
    setCurrentCourtType(type);
  };

  const handleSearchBookings = (e: any) => {
    setSearchString(e.target.value);
  };

  const handleUserSelection = () => {
    setSelectingActivitySlots(false);
    setSelectedSlotsForActivityCreation([]);
    if (chosenUser) {
      setChosenUser(undefined);
      setUserSummaries([]);
      fetchChosenItems({
        fetchSlots: true,
      });
    } else {
      setShowChooseUserPopup(true);
    }
  };

  const checkChosenSlot = (slot: any, court: ICourt) => {
    return (
      slot.start_time === currentChosenSummaryItem?.start_time &&
      court.court_id === currentChosenCourt.court_id
    );
  };

  const slotsToRender = userSummaries.length > 0 ? userSummaries : summaries;

  const renderSlotRow = (
    slot: ISummaryItem,
    index: number,
    court: ICourt,
    summaryForCourt: ISummaryItem[]
  ) => {
    const time = slot.start_time.substring(0, 5);
    const hasBooking = slot.booking.length > 0;
    const isPendingBooking =
      hasBooking && slot.booking[0].status === StatusEnum.PENDING;
    const booking = slot.booking[0];
    const activity = slot.activity;
    const hasActivity = activity !== null;
    const isAvailable = slot.available_for_member_type === true;
    const hasCoach = hasBooking && booking.coach_id.length > 0;
    const currentDateTime = new Date();
    const currentDateStart = startOfDay(currentDateTime);
    const filterDateStart = startOfDay(filterDate);
    let currentSelectedUserClass = "";

    let pushDown15pxClass = "";

    const previousSlot = index > 0 ? summaryForCourt[index - 1] : null;

    if ((court.name === "B25" || court.name === "B26") && previousSlot) {
      const previousSlotStartTime = new Date(
        `${formatDate(filterDate, "yyyy-MM-dd")}T${previousSlot.start_time}`
      );
      const currentSlotStartTime = new Date(
        `${formatDate(filterDate, "yyyy-MM-dd")}T${slot.start_time}`
      );

      const timeDifferenceInMinutes =
        (currentSlotStartTime.getTime() - previousSlotStartTime.getTime()) /
        (1000 * 60);

      if (timeDifferenceInMinutes === 75) {
        pushDown15pxClass = "push-down-15px";
      }
    }
    let matchesSearchString = false;

    const slotStartTime = new Date(
      `${formatDate(filterDate, "yyyy-MM-dd")}T${slot.start_time}`
    );
    const slotEndTime = new Date(
      `${formatDate(filterDate, "yyyy-MM-dd")}T${slot.end_time}`
    );
    const isInPast =
      isBefore(slotEndTime, currentDateTime) ||
      isBefore(filterDateStart, currentDateStart);
    const hasStarted = isBefore(slotStartTime, currentDateTime);

    let textColor = "";
    if (hasBooking && chosenUser) {
      if (booking.member_id === chosenUser?.member_id) {
        textColor = "primary-text";
      } else {
        textColor = "secondary-text";
      }
    } else {
      if (!hasActivity && isAvailable) {
        textColor = "primary-text";
      } else {
        textColor = "secondary-text";
      }
    }

    let isSemiNoShow = false;
    let semiNoshowClass = "";

    let finalPrizeOverZero = false;
    if (hasBooking) {
      finalPrizeOverZero = booking.final_price.some((p) => p.price > 0);
      if (finalPrizeOverZero) {
      }
      if (booking.status === StatusEnum.SEMI_NO_SHOW) {
        isSemiNoShow = true;
        semiNoshowClass = "semi-no-show-slot";
      }
    }

    const coach =
      hasCoach &&
      coaches?.find((coach) => coach.coach_id === booking.coach_id[0]);
    const coachName = coach && coach.first_name + " " + coach.last_name;
    if (searchString && hasBooking) {
      if (searchForCoachOnly) {
        matchesSearchString =
          (hasCoach && booking.coach_id[0].includes(searchString)) ||
          (coachName &&
            coachName.toLowerCase().includes(searchString.toLowerCase()))
            ? true
            : false;
      } else {
        matchesSearchString =
          booking.member_id.includes(searchString) ||
          booking.member_name
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          (booking.co_players &&
            booking.co_players.some((player) =>
              player.member_id.includes(searchString)
            ));
      }
    } else if (searchString && hasActivity) {
      matchesSearchString = activity.participants.some((participant) =>
        participant.member_id.includes(searchString)
      );
    }
    if (
      hasActivity &&
      activity.participants.some((participant) =>
        participant.member_id.includes(searchString)
      )
    ) {
      console.log(activity);
    }
    if (currentSelectedCoach) {
      matchesSearchString =
        (slot.coach_available.find(
          (coach) => coach === currentSelectedCoach.coach_id
        ) !== undefined &&
          isAvailable &&
          !isInPast &&
          !hasActivity &&
          !hasBooking) ||
        (hasActivity &&
          slot.activity.coach_id &&
          slot.activity.coach_id.some(
            (coach) => coach === currentSelectedCoach.coach_id
          ));
    }

    const matchesClass = matchesSearchString
      ? "matches-search"
      : searchString.length > 0 || currentSelectedCoach !== undefined
      ? "no-match"
      : "";

    if (hasBooking && chosenUser) {
      currentSelectedUserClass =
        (chosenUser.first_name + " " + chosenUser.last_name ===
          slot.booking[0].member_name &&
          chosenUser.member_id === slot.booking[0].member_id) ||
        (slot.booking[0].co_players &&
          slot.booking[0].co_players.some(
            (player) => player.member_id === chosenUser.member_id
          ))
          ? "selected-user"
          : "";
    }

    let selectedClass = "";
    const isSelectedForActivityCreation = selectedSlotsForActivityCreation.some(
      (selectedSlot) =>
        selectedSlot.court_id === court.court_id &&
        selectedSlot.start_time === slot.start_time
    );
    const isInInervalForActivityCreation =
      selectedSlotsForActivityCreation.some((selectedSlot) => {
        const allSelectedSlots = selectedSlotsForActivityCreation;

        const earliestStartTime = Math.min(
          ...allSelectedSlots.map((slot) =>
            new Date(`1970-01-01T${slot.start_time}`).getTime()
          )
        );
        const latestStartTime = Math.max(
          ...allSelectedSlots.map((slot) =>
            new Date(`1970-01-01T${slot.start_time}`).getTime()
          )
        );

        const currentSlotTime = new Date(
          `1970-01-01T${slot.start_time}`
        ).getTime();

        const isCourtSelected = selectedSlotsForActivityCreation.some(
          (slot) => slot.court_id === court.court_id
        );

        return (
          isCourtSelected &&
          currentSlotTime >= earliestStartTime &&
          currentSlotTime <= latestStartTime
        );
      });

    if (checkChosenSlot(slot, court)) {
      selectedClass = "selected-slot";
    }
    if (slot.end_time > "22:00:00") {
      return null;
    }
    if (
      court.court_type === "OUTDOOR" &&
      slot.start_time >= "20:00:00" &&
      !hardcodeDateIsInRangeApr20Aug21(filterDate) &&
      !hasBooking
    ) {
      return null; // Skip rendering this slot
    }
    if (loading) {
      return (
        <tr key={index}>
          <td>
            <div className={`summary-item-wrapper ${pushDown15pxClass} `}>
              <div className="text-m summary-content-wrapper"></div>
            </div>
          </td>
        </tr>
      );
    }
    if (isPendingBooking) {
      return (
        <tr key={index}>
          <td>
            <div
              className={`summary-item-wrapper ${pushDown15pxClass} booking-in-progress`}
            >
              <div className="text-m summary-content-wrapper">Bokas...</div>
            </div>
          </td>
        </tr>
      );
    }
    if (
      (hasActivity && chosenUser && !isInPast) ||
      (hasActivity &&
        chosenUser &&
        activity.participants &&
        activity.participants.some(
          (participant) => chosenUser.member_id === participant.member_id
        )) ||
      (hasActivity && !chosenUser)
    ) {
      const activityName = activity.name.split("%-%")[0];
      const truncatedName =
        activityName.length > 20
          ? `${activityName.substring(0, 19)}..`
          : activityName;

      if (activity.participants.length >= activity.participants_limit) {
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${activity.color} ${matchesClass} pointer  ${pushDown15pxClass} `}
                onClick={() => handleActivitySlotClick(slot, court)}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>{truncatedName}</span>
                </div>
                {activity.comment && (
                  <i className="fa-medium fa-circle-info comment-icon"></i>
                )}
              </div>
            </td>
          </tr>
        );
      }

      if (
        slot.activity.participants &&
        slot.activity.participants.some(
          (participant) => participant.member_id === chosenUser?.member_id
        )
      ) {
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${matchesClass} selected-slot pointer  ${pushDown15pxClass} `}
                onClick={() => handleActivitySlotClick(slot, court)}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>Aktivitet</span>
                </div>
                {activity.comment && (
                  <i className="fa-regular fa-circle-info comment-icon"></i>
                )}
              </div>
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={index}>
            <td>
              <div
                className={`summary-item-wrapper ${matchesClass} ${activity.color} pointer  ${pushDown15pxClass} `}
                onClick={() => handleActivitySlotClick(slot, court)}
              >
                <div className="text-m summary-content-wrapper">
                  <div className="text-m">{`${time}`}</div>
                  <span>{truncatedName}</span>
                </div>
                {activity.comment && (
                  <i className="fa-regular fa-circle-info comment-icon"></i>
                )}
              </div>
            </td>
          </tr>
        );
      }
    }

    const coachAvailable = slot.coach_available.length > 0;
    const statusClassMap: any = {
      [StatusEnum.CONFIRMED]: "has-booking",
      [StatusEnum.CHECKED_IN]: "checked-in-slot",
      [StatusEnum.NO_SHOW]: "no-show-slot",
      [StatusEnum.SEMI_NO_SHOW]: "semi-no-show-slot",
      [StatusEnum.CANCELLED]: "canceled-slot",
      [StatusEnum.PAID]: "paid-slot",
    };

    const bookingStatusClass = booking?.status
      ? statusClassMap[booking.status]
      : "";

    let truncatedName = "";
    if (hasBooking) {
      truncatedName = capitalizeWords(booking.member_name);
    }
    const nameString =
      truncatedName.length > 20
        ? `${truncatedName.substring(0, 19)}..`
        : truncatedName;

    return (
      <tr key={index} className={textColor}>
        <td>
          {hasBooking && !isSemiNoShow ? (
            <div
              className={`summary-item-wrapper ${matchesClass} pointer ${bookingStatusClass} ${currentSelectedUserClass}  ${pushDown15pxClass} ${
                isSelectedForActivityCreation ? "highlight-selected" : ""
              } ${isInInervalForActivityCreation ? "highlight-interval" : ""} 
              ${
                slot === selectedBookedSlotForMovement && "marked-for-movement"
              }`}
              onClick={() => {
                handleBookedSlotClick(slot, court, false);
              }}
            >
              <div className="text-m">{`${time}`}</div>

              <div className="text-m">{capitalizeWords(nameString)}</div>
              {finalPrizeOverZero && bookingStatusClass !== "paid-slot" && (
                <i className="fa-regular fa-sack-dollar inclueds-prize"></i>
              )}
              {hasCoach && (
                <i className="fa-regular fa-user booking-has-coach"></i>
              )}
              {booking.comment && (
                <i className="fa-regular fa-circle-info comment-icon"></i>
              )}
            </div>
          ) : isInPast && isSemiNoShow ? (
            <div
              className={`summary-item-wrapper ${selectedClass} ${matchesClass} ${currentSelectedUserClass} ${semiNoshowClass} pointer  ${pushDown15pxClass} `}
              onClick={() => {
                handleBookedSlotClick(slot, court, true);
              }}
            >
              <div className="text-m summary-content-wrapper">
                <div className="text-m">{`${time}`}</div>
                <div className="text-m">{isSemiNoShow && nameString}</div>
              </div>
              {booking.comment && (
                <i className="fa-regular fa-circle-info comment-icon"></i>
              )}
            </div>
          ) : isInPast ? (
            <div
              className={`summary-item-wrapper ${selectedClass} ${matchesClass} ${currentSelectedUserClass} passed-unbooked pointer  ${pushDown15pxClass} `}
              onClick={() => {
                if (selectingForHistory) {
                  handleSlotClick(slot, court);
                }
              }}
            >
              <div className="text-m summary-content-wrapper">
                <div className="text-m">{`${time}`}</div>
              </div>
            </div>
          ) : (
            <div
              className={`summary-item-wrapper ${selectedClass} ${matchesClass} ${semiNoshowClass} ${currentSelectedUserClass} is-available pointer  ${pushDown15pxClass}${
                isSelectedForActivityCreation ? "highlight-selected" : ""
              } ${isInInervalForActivityCreation ? "highlight-interval" : ""}`}
              onClick={
                hasStarted && isSemiNoShow && !chosenUser
                  ? () => handleBookedSlotClick(slot, court, true)
                  : hasStarted && isSemiNoShow && chosenUser
                  ? () => handleSlotClick(slot, court)
                  : () => handleSlotClick(slot, court)
              }
            >
              <div className="text-m summary-content-wrapper">
                <div className="text-m">{`${time}`}</div>
                <div className="text-m">{isSemiNoShow && nameString}</div>
              </div>

              {checkChosenSlot(slot, court) ? (
                <div></div>
              ) : isSemiNoShow ? (
                hasCoach && (
                  <i className="fa-regular fa-user booking-has-coach"></i>
                )
              ) : (
                coachAvailable && (
                  <div className="text-m">
                    <i className="fa-regular fa-user"></i>
                  </div>
                )
              )}
            </div>
          )}
        </td>
      </tr>
    );
  };

  const getEarliestStartTime = () => {
    let earliestTime = "23:59";

    courts.forEach((court) => {
      const summaryForCourt = slotsToRender.find(
        (summary) => summary.court_id === court.court_id
      );
      if (summaryForCourt && summaryForCourt.slots.length > 0) {
        const firstSlotTime = summaryForCourt.slots[0].start_time;
        if (firstSlotTime < earliestTime) {
          earliestTime = firstSlotTime;
        }
      }
    });

    return earliestTime;
  };

  const calculateTimeDifference = (earliestTime: string, courtTime: string) => {
    const [earliestHour, earliestMinute] = earliestTime.split(":").map(Number);
    const [courtHour, courtMinute] = courtTime.split(":").map(Number);

    const earliestTotalMinutes = earliestHour * 60 + earliestMinute;
    const courtTotalMinutes = courtHour * 60 + courtMinute;

    return courtTotalMinutes - earliestTotalMinutes;
  };

  const renderCourtColumn = (court: ICourt) => {
    const summaryForCourt = slotsToRender.find(
      (summary) => summary.court_id === court.court_id
    );
    const firstSlotTime = summaryForCourt?.slots[0].start_time || "";

    const timeDifference = calculateTimeDifference(
      getEarliestStartTime(),
      firstSlotTime
    );

    const pushDownClass = timeDifference > 0 ? `pushed-down-court` : "";

    if (!summaryForCourt || (!summaryForCourt.slots && !loading)) {
      return (
        <td key={court.court_id}>
          <table className="nested-courts-table">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
      );
    }

    return (
      <td key={court.court_id} className={pushDownClass}>
        <table className="nested-courts-table">
          <tbody>
            {summaryForCourt.slots.map((slot, index) =>
              renderSlotRow(slot, index, court, summaryForCourt.slots)
            )}
          </tbody>
        </table>
      </td>
    );
  };

  if (!courts || !summaries || !coaches) {
    return <Spinner />;
  }
  return (
    <div className="admin-bookings-page-container">
      <div className="admin-bookings-layout print-section">
        <div className="admin-bookings-declarations">
          <div className="admin-bookings-oversight-info-wrapper">
            <h1 className="text-l primary-text">Översikt</h1>
          </div>
          <div className="admin-bookings-declerations-buttons">
            {chosenUser && (
              <div className="chosen-user-info-wrapper text-m">
                <span>
                  {capitalizeWords(chosenUser.first_name)}{" "}
                  {capitalizeWords(chosenUser.last_name)}
                </span>

                <span>({chosenUser.member_id})</span>
                <span> {capitalizeWords(chosenUser.member_type)},</span>
                <span>No shows: {chosenUser.noshow_count}</span>
              </div>
            )}
            <button
              className="text-m select-style admin-actions-setting"
              onClick={handleUserSelection}
            >
              {chosenUser ? (
                <div className="chosen-user-wrapper">
                  {capitalizeWords(chosenUser.first_name) +
                    " " +
                    capitalizeWords(chosenUser.last_name)}
                </div>
              ) : (
                <span>Välj medlem</span>
              )}
            </button>
            <select
              className="coaches-select text-m admin-actions-setting"
              value={currentSelectedCoach ? currentSelectedCoach.coach_id : ""}
              onChange={(e) => {
                const selectedCoach = coaches.find(
                  (coach) => coach.coach_id === e.target.value
                );
                setCurrentSelectedCoach(selectedCoach || undefined);
              }}
            >
              <option value="">Välj tränare</option>
              {coaches.map((coach, index) => {
                const name =
                  capitalizeWords(coach.first_name) +
                  " " +
                  capitalizeWords(coach.last_name);
                return (
                  <option key={index} value={coach.coach_id}>
                    {name}
                  </option>
                );
              })}
            </select>

            <div className="search-input-wrapper">
              <input
                className="search-input text-m admin-actions-setting"
                value={searchString}
                onChange={handleSearchBookings}
                placeholder="Sök"
              />
              <i className="fa-regular fa-magnifying-glass search-icon"></i>
            </div>
            <div className="toggle-flex-between">
              <label className="toggle text-m">
                <input
                  type="checkbox"
                  name="coach"
                  checked={searchForCoachOnly}
                  onChange={(e) => setSearchForCoachOnly(e.target.checked)}
                />
                <span className="slider round"></span>
              </label>
              <span className="text-m secondary-text">Sök efter coach</span>
            </div>
          </div>
        </div>
        <div className="admin-bookings-action-wrapper">
          <div className="bookings-weekday-selector-wrapper">
            <div className="admin-booking-court-type text-m">
              <button
                className={`toggle-button ${
                  currentCourtType === "INDOOR" ? "active" : ""
                }`}
                onClick={() => handleToggleCourtType("INDOOR")}
              >
                Inne
              </button>
              <button
                className={`toggle-button ${
                  currentCourtType === "OUTDOOR" ? "active" : ""
                }`}
                onClick={() => handleToggleCourtType("OUTDOOR")}
              >
                Ute
              </button>
            </div>
            <WeekDaySelector
              value={filterDate}
              onChange={setFilterDate}
              includeWeekdays
              textM
            />
            {filterDate.getDate() !== new Date().getDate() && (
              <a
                className="text-m no-wrap"
                onClick={() => setFilterDate(new Date())}
              >
                Till idag
              </a>
            )}
          </div>
          <div className="admin-bookings-add-wrapper">
            <button
              className="text-m"
              onClick={() => {
                setShowBookRehabPopup(true);
              }}
            >
              Rehab tider
            </button>

            <button
              className="text-m"
              onClick={() => setShowCreateActivityPopup(true)}
            >
              Skapa aktivitet
            </button>
            <button
              disabled={selectingForHistory || selectingBookedSlotForMovement}
              className="text-m"
              onClick={() => {
                if (selectingActivitySlots) {
                  setSelectedSlotsForActivityCreation([]);
                }
                setSelectingBookedSlotForMovement(false);
                setSelectingActivitySlots(!selectingActivitySlots);
                setSelectingForHistory(false);
              }}
            >
              {selectingActivitySlots ? "Avbryt markering" : "Markera fält"}
            </button>
            <button
              disabled={
                selectingActivitySlots || selectingBookedSlotForMovement
              }
              className="text-m"
              onClick={() => {
                setSelectingBookedSlotForMovement(false);
                setSelectingActivitySlots(false);
                setSelectingForHistory(!selectingForHistory);
              }}
            >
              {selectingForHistory ? "Avbryt Historik" : "Historik"}
            </button>
            <button
              disabled={selectingActivitySlots || selectingForHistory}
              className="text-m"
              onClick={() => {
                setSelectingForHistory(false);
                setSelectingActivitySlots(false);
                setSelectingBookedSlotForMovement(
                  !selectingBookedSlotForMovement
                );

                if (!selectingBookedSlotForMovement) {
                  const id = toast.info("Markera bokning att flytta", {
                    autoClose: false,
                  });
                  setToastIdMoveBooking(id);
                } else {
                  setSelectedBookedSlotForMovement(undefined);
                  if (toastIdMoveBooking) {
                    toast.dismiss(toastIdMoveBooking);
                    setToastIdMoveBooking(null);
                  }
                }
              }}
            >
              {selectingBookedSlotForMovement
                ? "Avbryt flyttning"
                : "Flytta bokning"}
            </button>
          </div>
        </div>
        <div className="courts-table-overflow-wrapper">
          <table className="courts-table">
            <thead>
              <tr>
                {courts.map((court, index) => {
                  if (court.court_type === currentCourtType)
                    return (
                      <th
                        key={index}
                        className={`text-m secondary-text ${
                          court.name === "B6" && "padding-right-20px"
                        }`}
                      >
                        {court.name} {court.name === "B27" ? "(hardcourt)" : ""}
                      </th>
                    );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {courts.map((court, index) => {
                  if (court.court_type === currentCourtType)
                    return renderCourtColumn(court);
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ChooseUserPopup
        showPopup={showChooseUserPopup}
        onClose={() => setShowChooseUserPopup(false)}
        onConfirm={(user) => {
          setChosenUser(user);
        }}
      />

      <CreateBookingPopup
        showPopup={showCreateBookingPopup}
        filterDate={filterDate}
        courtType={currentCourtType}
        court={currentChosenCourt}
        user={user}
        chosenCoach={currentSelectedCoach}
        timeAndCourtString={timeAndCourtString}
        onConfirm={() => {
          handleConfirmFromBooking();
        }}
        onClose={() => {
          setShowCreateBookingPopup(false);
          setCurrentChosenSummaryItem(undefined);
        }}
        bookingToConfirm={bookingResponse}
        coachesList={currentChosenSummaryItem?.coach_available || []}
        latestCoPlayers={latestCoPlayersForUser}
        coaches={coaches as ICoach[]}
        adminChosenUser={chosenUser}
        permissions={permissions}
        price={currentChosenSummaryItem?.price}
        prices={prices}
      />

      <BookActivityPopup
        showPopup={showBookActivityPopup}
        onClose={() => {
          setShowBookActivityPopup(false);
          setCurrentChosenSummaryItem(undefined);
          if (chosenUser) {
            fetchChosenItems({
              fetchSlotsByMember: true,
            });
          } else {
            fetchChosenItems({
              fetchSlots: true,
            });
          }
        }}
        onConfirm={() =>
          fetchChosenItems({
            fetchSlotsByMember: true,
          })
        }
        timeAndCourtString={timeAndCourtString}
        slot={currentChosenSummaryItem as ISummaryItem}
        court={currentChosenCourt}
        user={chosenUser}
        filterDate={filterDate}
        courts={courts}
      />
      <AdminHandleBookingPopup
        onClose={() => {
          setShowHandleBookingPopup(false);
        }}
        showPopup={showHandleBookingPopup}
        slot={selectedSlotToHandle as ISummaryItem}
        courts={courts}
        onRefetchComment={() =>
          chosenUser
            ? fetchChosenItems({
                fetchSlotsByMember: true,
              })
            : fetchChosenItems({
                fetchSlots: true,
              })
        }
        onConfirm={() =>
          chosenUser
            ? fetchChosenItems({
                fetchSlotsByMember: true,
                fetchActiveBookingsByMemberId: true,
                fetchActiveActivitiesByMemberId: true,
              })
            : fetchChosenItems({
                fetchSlots: true,
              })
        }
        coaches={coaches}
        isSemiNoShow={selectedSlotIsSemiNoShow}
        filterDate={filterDate}
        prices={prices}
      />
      <HandleRehabTimesPopup
        user={user}
        showPopup={showBookRehabPopup}
        onClose={() => setShowBookRehabPopup(false)}
        onConfirm={() =>
          fetchChosenItems({
            fetchRehabSlotsAdmin: true,
          })
        }
        rehabSummary={rehabSummary}
        date={filterDate}
      />
      <CreateActivityPopup
        showPopup={showCreateActivityPopup}
        onClose={() => {
          setSelectingActivitySlots(false);
          setSelectedSlotsForActivityCreation([]);
          setShowCreateActivityPopup(false);
        }}
        onConfirm={() =>
          fetchChosenItems({
            fetchSlots: true,
          })
        }
        activities={activities}
        coachesList={coaches}
        courts={courts}
        permissions={permissions}
        selectedSlotsProp={selectedSlotsForActivityCreation}
        filterDate={filterDate}
      />
      <SlotHistoryPopup
        showPopup={showSlotHistoryPopup}
        onClose={() => {
          setShowSlotHistoryPopup(false);
          setSelectedSlotForHistory(undefined);
          setSelectingForHistory(false);
        }}
        onConfirm={() => console.log()}
        slot={selectedSlotForHistory}
        court={currentChosenCourt}
        filterDate={filterDate}
      />
      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={() => handleCancelBooking(bookingToCancel as IBooking)}
        questionText={"Är du säker på att du vill avboka bokningen?"}
        confirmText={"Avboka"}
        denyText={"Avbryt"}
      />
      <HandleConfirmationPopup
        showPopup={showMaxBookingsWarningPopup}
        onClose={() => setShowMaxBookingsWarningPopup(false)}
        onConfirm={() => {
          handleInitiateBooking();
          setShowMaxBookingsWarningPopup(false);
        }}
        questionText={
          capitalizeWords(chosenUser?.first_name.toString() || "") +
          " har nått max antal bokningar, är du säker på att du vill starta en ny bokning?"
        }
        confirmText={"Boka ändå"}
        denyText={"Avbryt"}
      />
      <HandleConfirmationPopup
        showPopup={showNotAvailableForMember}
        onClose={() => setShowNotAvailableForMember(false)}
        onConfirm={() => {
          handleInitiateBooking();
          setShowNotAvailableForMember(false);
        }}
        questionText={
          capitalizeWords(chosenUser?.first_name.toString() || "") +
          " har inte tillgång till denna tid. Bokaren har antingen en bokning som kolliderar med denna tid eller inte tillgång pga. spelrätt"
        }
        confirmText={"Boka ändå"}
        denyText={"Avbryt"}
      />
      <HandleConfirmationPopup
        showPopup={showBookExtraPopup}
        onClose={() => setShowBookExtraPopup(false)}
        onConfirm={() => {
          if (currentChosenSummaryItem?.activity === null) {
            handleInitiateBooking();
          } else {
            handleBookActivity();
          }
        }}
        questionText={
          "Medlemmen har redan bokat max antal bokningar, eller har inte tillgång till denna tid. Vill fortsätta ändp?"
        }
        confirmText={"Ja"}
        denyText={"Avbryt"}
      />

      <MoveBookingPopup
        showPopup={showMoveBookingPopup}
        onClose={() => {
          setShowMoveBookingPopup(false);
          setSelectingBookedSlotForMovement(false);
          setSelectedBookedSlotForMovement(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({
            fetchSlots: true,
          });
        }}
        bookedSlot={selectedBookedSlotForMovement}
        slotForMovement={selectedEmptySlotForMovement}
        newCourt={currentChosenCourt}
        courts={courts}
        filterDate={filterDate}
      />
    </div>
  );
}

export default AdminHandleBookings;
