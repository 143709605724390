import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { IPasswordChangeData } from "../../types/api";

export const initiatePasswordReset = async (email: string) => {
  const query = `mutation MyMutation($email: String!) {
        initiatePasswordReset(email: $email){
        ... on PasswordReset {
            success_message
            }
        ... on Error {
            message
            code
        }
    }
    }`;

  try {
    const getInitiateResponse = (await API.graphql({
      query: query,
      variables: { email: email },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getInitiateResponse.data.initiatePasswordReset;
    if (response) {
      return true;
    }
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const confirmPasswordReset = async (
  requestData: IPasswordChangeData
) => {
  const query = `mutation MyMutation($email: String!, $new_password: String!, $confirmation_code: String!) {
        confirmPasswordReset(email: $email, new_password: $new_password, confirmation_code: $confirmation_code) {
        ... on PasswordReset {
            success_message
            }
        ... on Error {
            message
            code
        }
    }
    }`;

  try {
    const getConfirmResponse = (await API.graphql({
      query: query,
      variables: requestData,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getConfirmResponse.data.confirmPasswordReset;
     if (response.success_message) {
       toast.success(response.success_message);
       return true; 
     } else if (response.code) {
       toast.error(response.code);
       return false;
     } else {
       toast.error("Server fel");
       return false;
     }
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
