import {
  IPermission,
  IPermissionInput,
  IPermissionToEdit,
} from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  createPermissionQueryFn,
  deletePermissionQueryFn,
  editPermissionQueryFn,
  getPermissionByMemberTypeQueryFn,
  listPermissionsQueryFn,
} from "./permissionsQueries";

export const listPermissions = async (): Promise<IPermission[]> => {
  const { query } = listPermissionsQueryFn();

  const myQuery = `
    query listPermissions {
     ${query}
    }
  `;

  try {
    await Auth.currentSession();

    const listPermissionsResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = listPermissionsResponse.data.listPermissions;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getPermissionByPermissionId = async (permissionId: string) => {
  const { query, variables, inputs } =
    getPermissionByMemberTypeQueryFn(permissionId);

  const myQuery = `
    query myQuery(${inputs}) {
      ${query}
    }
  `;
  try {
    const getPermissionResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getPermissionResponse.data.getPermissionByMemberType;
    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getPermissionByMemberType = async (memberType: string) => {
  const getPermissionQuery = `
   query MyQuery {
    getPermissionByMemberType(member_type: $member_type) {
      ... on Permission {
        indoor_access {
          end_time
          start_time
          weekday
        }
      }
      ... on Error {
        message
        code
      }
    }
  }`;
  try {
    const getPermissionResponse = (await API.graphql({
      query: getPermissionQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: { member_type: memberType },
    })) as GraphQLResult<any>;

    const response = getPermissionResponse.data.getPermissionByMemberType;
    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const editPermission = async (
  permissionId: string,
  permissionToEdit: Partial<IPermissionToEdit>
) => {
  const { query, inputs, variables } = editPermissionQueryFn(
    permissionId,
    permissionToEdit
  );

  const myQuery = `mutation myMutation(${inputs}) {
    ${query}
  }`;

  try {
    const getEditResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getEditResponse.data.updatePermission;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createPermission = async (
  IpermissionToCreate: IPermissionInput
) => {
  const { query, inputs, variables } =
    createPermissionQueryFn(IpermissionToCreate);
  const myQuery = `
    mutation myMutation(${inputs}) {
      ${query}
    }
  `;

  try {
    const getCreateResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createPermission;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deletePermission = async (permissionId: string) => {
  const { query, variables, inputs } = deletePermissionQueryFn(permissionId);
  const myQuery = `
    mutation myMutation(${inputs}) {
      ${query}
    }
  `;
  try {
    const getDeleteResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getDeleteResponse.data.deletePermission;
    if ("message" in response) {
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
