import { useCallback, useEffect, useRef, useState } from "react";
import { IUser } from "../../../types/api";
import MidPopup from "../../../hoc/MidPopup/MidPopup";
import { capitalizeWords, debounce } from "../../../shared/utility";
import { freezeMembership, searchUsers } from "../../../api/users/users";
import { toast } from "react-toastify";
import "./FreezeUserPopup.scss";
import DateInput from "../../../components/UI/DateInput/DateInput";
import WeekDaySelector from "../../../hoc/DatePicker/Datepicker";
import { addDays } from "date-fns";
import { formatToIsoDate } from "../../../shared/dateUtils";
interface IChooseUserPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: (user: IUser) => void;
}
function FreezeUserPopup(props: IChooseUserPopupProps) {
  const { onClose, showPopup, onConfirm } = props;
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
  const [searchString, setSearchString] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSetStartDate = (date: Date | null) => {
    if (date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        toast.error("Välj ett aktuellt datum");
        return;
      }
      setStartDate(date);

      if (endDate <= date) {
        setEndDate(addDays(date, 1));
      }
    }
  };

  const handleSetEndDate = (date: Date | null) => {
    if (date) {
      if (date <= startDate) {
        toast.error("Slutdatum måste vara minst en dag efter startdatum");
        setEndDate(addDays(startDate, 1));
      } else {
        setEndDate(date);
      }
    }
  };

  const handleClose = () => {
    setStartDate(new Date());
    setEndDate(addDays(new Date(), 1));
    setSearchString("");
    setSearchedUsers([]);
    setSelectedUser(null);
    onClose();
  };

  const lastWarningTimeRef = useRef<number>(0);

  const showWarning = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeRef.current >= 5000) {
      lastWarningTimeRef.current = now;
      toast.warning("Inga användare hittades");
    }
  }, []);

  const fetchSearchUsers = async (search: string) => {
    try {
      const response = await searchUsers(search, "admin", true);
      if (response.length === 0) {
        showWarning();
      }
      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (showPopup && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopup]);

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };

  useEffect(() => {
    if (searchString.length > 2) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  const handleChoosePlayer = (player: IUser) => {
    setSearchString("");
    setSelectedUser(player);
  };

  const handleFreezeUser = async () => {
    if (selectedUser) {
      try {
        const response = await freezeMembership(
          selectedUser.member_id,
          formatToIsoDate(startDate),
          formatToIsoDate(endDate),
          selectedUser.member_type
        );
        if ("message" in response) {
          toast.error(response.message);
        } else {
          toast.success("Användaren har frysits");
          handleClose();
        }
      } catch (error: any) {
        toast.error(error);
      }
    }
  };
  return (
    <MidPopup
      onClose={handleClose}
      showPopup={showPopup}
      leftTopElement={<span className="text-m">Välj medlem att frysa</span>}
      onConfirm={() => {
        if (selectedUser) {
          onConfirm(selectedUser);
        }
      }}
    >
      <div className="choose-users-popup-wrapper">
        <div className="user-input-btn-wrapper">
          <div className="search-input-wrapper">
            <input
              className="users-search-input text-m"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              placeholder="Sök på namn & medlemsnummer"
              ref={inputRef}
            ></input>
            <i className="fa-regular fa-magnifying-glass search-icon"></i>
          </div>
        </div>
        {searchedUsers.length > 0 &&
          searchedUsers.map((player) => {
            return (
              <div
                key={player.member_id}
                className="player-wrapper pointer text-m"
                onClick={() => handleChoosePlayer(player)}
              >
                <div className="player-name">
                  <span>
                    {capitalizeWords(player.first_name)}{" "}
                    {capitalizeWords(player.last_name)}
                  </span>
                </div>
                <div className="player-id">{player.member_id}</div>
                <a className="choose-player">Välj medlem</a>
              </div>
            );
          })}
        <div className="freeze-date-pickers-wrapper">
          <div className="freeze-date-picker">
            <span className="text-m">Från</span>
            <WeekDaySelector
              includeWeekdays={false}
              value={startDate}
              onChange={handleSetStartDate}
              isSmall
            />
          </div>
          <div className="freeze-date-picker">
            <span className="text-m">Till</span>
            <WeekDaySelector
              includeWeekdays={false}
              value={endDate}
              onChange={handleSetEndDate}
              isSmall
            />
          </div>
        </div>
        {selectedUser && (
          <button onClick={handleFreezeUser}>
            Frys {selectedUser.first_name} {selectedUser.last_name}
          </button>
        )}
      </div>
    </MidPopup>
  );
}

export default FreezeUserPopup;
