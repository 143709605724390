import { useRef, useState } from "react";
import { IPermission } from "../../../types/api";
import MidPopup from "../../../hoc/MidPopup/MidPopup";

import { toast } from "react-toastify";
import "./ExportUsersPopup.scss";

interface IChooseUserPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: (permissions: string[]) => void;
  permissions: IPermission[];
}
function ExportUsersPopup(props: IChooseUserPopupProps) {
  const { onClose, showPopup, onConfirm, permissions } = props;
  const [selectedPermissions, setSelectedPermissions] = useState<{
    [key: string]: boolean;
  }>({});

  const handleExportUsers = () => {
    const selectedPermissionNames = permissions
      .filter((permission) => selectedPermissions[permission.permission_id])
      .map((permission) => permission.member_type);

    onConfirm(selectedPermissionNames);
    handleClose();
  };

  const handleSelectAllPermissions = () => {
    const allSelected = permissions.every(
      (permission) => selectedPermissions[permission.permission_id]
    );

    const updatedSelectedPermissions = permissions.reduce(
      (acc, permission) => ({
        ...acc,
        [permission.permission_id]: !allSelected,
      }),
      {}
    );

    setSelectedPermissions(updatedSelectedPermissions);
  };

  const togglePermissionSelection = (permissionId: string) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [permissionId]: !prev[permissionId],
    }));
  };

  const handleClose = () => {
    setSelectedPermissions({});
    onClose();
  };

  return (
    <MidPopup
      onClose={handleClose}
      showPopup={showPopup}
      leftTopElement={
        <span className="text-m">Välj medlemstyper att exportera</span>
      }
      onConfirm={() => {
        if (true) {
        }
      }}
    >
      <div className="export-users-popup-wrapper">
        <div className="export-users-wrapper">
          {permissions.map((permission, index) => {
            if (permission.member_type === "Kö") {
              return null;
            }
            return (
              <div key={index} className="export-users-permission-wrapper">
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    checked={
                      selectedPermissions[permission.permission_id] || false
                    }
                    onChange={() =>
                      togglePermissionSelection(permission.permission_id)
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <span className="text-m">{permission.member_type}</span>
              </div>
            );
          })}
        </div>
        <div className="export-users-btns-wrapper">
          <button
            className="btn btn-primary text-m"
            onClick={() => handleSelectAllPermissions()}
          >
            {permissions.every(
              (permission) => selectedPermissions[permission.permission_id]
            )
              ? "Avmarkera alla"
              : "Välj alla"}
          </button>
          <button
            className="btn btn-primary text-m"
            onClick={() => handleExportUsers()}
            disabled={Object.values(selectedPermissions).every(
              (permission) => !permission
            )}
          >
            Exportera
          </button>
        </div>
      </div>
    </MidPopup>
  );
}

export default ExportUsersPopup;
