import { ICourtToCreate, ICourtToEdit } from "../../types/api";

export const listCourtsQueryFn = () => {
  const query = `
        listCourts {
            ... on Court {
            court_id
            court_api
            name
            court_type
            availability {
                interval {
                    end_time
                    price
                    start_time
                }
                weekday
            }
          }
          ... on Error {
            message
            code
          }
        }
    `;
  return {
    query,
  };
};

export const editCourtQueryFn = (courtToEdit: ICourtToEdit) => {
  const query = ` updateCourt(court_id: $court_id, input_data: $input_data) {
      ... on Court {
        court_id
        name
      }
      ... on Error {
        message
        code
      }
    }
  `;
  const court_id = courtToEdit.court_id;
  const input_data = courtToEdit.input_data;
  const inputs = `$court_id: String!, $input_data: CourtInput!`;

  return {
    inputs,
    query,
    variables: { court_id: court_id, input_data: input_data },
  };
};


export const createCourtQueryFn = (courtToCreate: ICourtToCreate) => {
  const query = ` createCourt(input: $input) {
      ... on Court {
        court_id
        name
        court_api
        court_type
        availability {
          interval {
            end_time
            price
            start_time
          }
          weekday
        }
      }
      ... on Error {
        message
        code
      }
    }
  `;
  const variables = courtToCreate;
  const inputs = `$input: CourtInput!`;

  return {
    inputs,
    query,
    variables: { input: variables },
  };
}

export const deleteCourtQueryFn = (court_id: string) => {
  const query = ` deleteCourt(court_id: $court_id) {
      ... on Court {
        name
      }
      ... on Error {
        message
        code
      }
    }
  `;
  const inputs = `$court_id: String!`;

  return {
    inputs,
    query,
    variables: { court_id: court_id },
  };
};

