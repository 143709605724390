import { useState, useRef, Fragment } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  IRehabBooking,
  IRehabSummary,
  IRehabSummaryItem,
} from "../../../types/api";
import "./BookRehabSlotPopup.scss";
import { formatDateToSwedish } from "../../../shared/utility";
import greenCheck from "../../../assets/images/green-check.svg";
import { CognitoUser } from "../../../types/cognito";
import { toast } from "react-toastify";
import { createRehabBooking } from "../../../api/rehab/rehab";
import { formatDate } from "../../../shared/dateUtils";

interface IBookRehabSlotPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: () => void;
  rehabSummary?: IRehabSummary;
  date: Date;
  user: CognitoUser | null;
  activeBookings: IRehabBooking[];
}

function BookRehabSlotPopup(props: IBookRehabSlotPopupProps) {
  const { rehabSummary, date, showPopup, onClose, onConfirm, user } = props;
  const [isReady, setIsReady] = useState(false);
  const { weekday, dateAndMonth } = formatDateToSwedish(date);
  const [selectedSlot, setSelectedSlot] = useState<IRehabSummaryItem | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  
  const handleClose = () => {
    setSelectedSlot(null);
    setIsReady(false);
    onClose();
  };
  const handleConfirmBooking = async () => {
    
    if (selectedSlot?.start_time === undefined) {
      return;
    }
    if(props.activeBookings.length > 0) {
      toast.error("Du kan max ha en aktiv bokning i taget");  
      return;
    }
    setIsLoading(true);
    const loadToast = toast.loading("Bokar tid...");
    const bookingToCreate = {
      datetime_start: `${formatDate(date, "yyyy-MM-dd")}T${
        selectedSlot?.start_time
      }`,
      datetime_end: `${formatDate(date, "yyyy-MM-dd")}T${
        getEndTime(selectedSlot?.start_time) + ":00"
      }`,
      flag: 1,
      member_id: props.user?.attributes["custom:member_id"] || "",
    };

    if (bookingToCreate.member_id === "") {
      return;
    }
    try {
      const response = await createRehabBooking(bookingToCreate);
      if (response && !("message" in response)) {
        setIsLoading(false);
        toast.dismiss(loadToast);
        toast.success("Rehab tid bokad");
        onConfirm();
        handleClose();
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.dismiss(loadToast);
      toast.error(error);
    }
  };

  const getEndTime = (startTime: string) => {
    const hours = parseInt(startTime.slice(0, 2));
    const minutes = parseInt(startTime.slice(3, 5));

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    date.setMinutes(date.getMinutes() + 15);

    const newHours = date.getHours().toString().padStart(2, "0");
    const newMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  };

  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">Boka 15 minuters Rehab-bad</span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-top">
            {isReady && (
              <button className="text-m" onClick={() => {
                !isLoading && handleConfirmBooking()}}>
                Boka tid
              </button>
            )}
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {weekday}, {dateAndMonth}
                  </span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Vald tid</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {selectedSlot
                      ? selectedSlot.start_time.slice(0, 5) +
                        " - " +
                        getEndTime(selectedSlot.start_time).slice(0, 5)
                      : ""}
                  </span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="rehab-slots-wrapper">
            <span className="text-m">Tider</span>
            {rehabSummary &&
              rehabSummary.slots.map((slot, index) => {
                const selectedClass =
                  selectedSlot?.start_time === slot.start_time
                    ? "selected"
                    : "";
                const isInPast =
                  formatDate(props.date, "yyyy-MM-dd") <
                    formatDate(new Date(), "yyyy-MM-dd") ||
                  (slot.start_time < formatDate(new Date(), "HH:mm:ss") &&
                    formatDate(props.date, "yyyy-MM-dd") ===
                      formatDate(new Date(), "yyyy-MM-dd"));

                if (isInPast) {
                  return (
                    <Fragment key={index}></Fragment>
                    // <div
                    //   key={slot.start_time}
                    //   className={`rehab-slot-wrapper text-m is-past`}
                    // >
                    //   <div className="rehab-slot-time">
                    //     {slot.start_time.slice(0, 5)}
                    //   </div>
                    // </div>
                  );
                }
                if (
                  slot.booking !== null &&
                  slot.booking.member_id ===
                    user?.attributes["custom:member_id"]
                ) {
                  const myBookingClass =
                    slot.booking.member_id ===
                    user?.attributes["custom:member_id"]
                      ? "my-booking"
                      : "is-taken";
                  return (
                    <div
                      key={slot.start_time}
                      className={`rehab-slot-wrapper text-m ${myBookingClass}`}
                    >
                      <div className="rehab-slot-time">
                        <span>
                          {slot.start_time.slice(0, 5)}
                          {" - " + getEndTime(slot.start_time).slice(0, 5)}
                        </span>
                        <span>Bokad tid</span>
                      </div>
                    </div>
                  );
                } else if (slot.booking !== null) {
                  const myBookingClass =
                    slot.booking.member_id ===
                    user?.attributes["custom:member_id"]
                      ? "my-booking"
                      : "is-taken";

                  return (
                    <div
                      key={slot.start_time}
                      className={`rehab-slot-wrapper text-m is-taken`}
                    >
                      <div className="rehab-slot-time">
                        <span>
                          {slot.start_time.slice(0, 5)}
                          {" - " + getEndTime(slot.start_time).slice(0, 5)}
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={slot.start_time}
                      className={`rehab-slot-wrapper pointer text-m ${selectedClass}`}
                      onClick={() => {
                        toast.success(`${slot.start_time.slice(0, 5)} vald`);
                        setSelectedSlot(slot);
                        setIsReady(true);
                      }}
                    >
                      <div className="rehab-slot-time">
                        <span>
                          {slot.start_time.slice(0, 5)}
                          {" - " + getEndTime(slot.start_time).slice(0, 5)}
                        </span>
                        <a className="choose-time">Välj tid</a>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default BookRehabSlotPopup;
