import React from "react";
import loginImage from "../../assets/images/KLTK-login.webp";
import kltkLogo from "../../assets/images/KLTK-volley.svg";
import "./BaseAuthLayout.scss";

interface BaseAuthProps {
  children: React.ReactNode;
  header: string;
  subHeader?: string;
}

function BaseAuthLayout(props: BaseAuthProps) {
  return (
    <div className="base-auth-layout">
      <div className="base-auth-layout-left">
        <img src={kltkLogo} alt="kltk-logo" className="kltk-logo" />
        <div className="base-auth-layout-left-top">
          <div className="text-xl"> {props.header}</div>
          {props.subHeader && <div className="text-s"> {props.subHeader}</div>}
        </div>
        <div className="base-auth-left-children">{props.children}</div>
      </div>
      <div className="base-auth-layout-right">
        <div className="base-auth-layout-image-wrapper">
          <img src={loginImage} alt="placeholder" />
        </div>
        <div className="base-auth-layout-contact-wrapper">
          <div className="text-m"> Kontakt </div>
          <div className="line-divider marg-10"></div>
          <div className="contact-label-link-wrapper">
            <div className="text-s"> E-post kansli:</div>
            <a href="mailto:kansli@kltk.se" className="text-s">
              kansli@kltk.se
            </a>
          </div>
          <div className="contact-label-link-wrapper mt-5">
            <div className="text-s"> Telefon kansli:</div>
            <a href="tel:08-4591515" className="text-s">
              08-459 15 15
            </a>
          </div>
          <div className="contact-label-link-wrapper mt-5">
            <div className="text-s"> Telefon reception:</div>
            <a href="tel:08-4591500" className="text-s">
              08-459 15 00
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseAuthLayout;
