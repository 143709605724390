import "./CreateAdminPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import { capitalizeWords } from "../../../shared/utility";
import { TitleEnum } from "../../../shared/enums";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { createAdmin } from "../../../api/admin/admin";

interface ICreateAdminPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
interface AdminData {
  first_name: string;
  last_name: string;
  email: string;
  [key: string]: string | boolean;
}

const initialAdminData: AdminData = {
  first_name: "",
  last_name: "",
  email: "",
};

function CreateAdminPopup(props: ICreateAdminPopupProps) {
  const [showHandleCreateAdminPopup, setShowHandleCreateAdminPopup] =
    useState(false);
  const titleValues = Object.values(TitleEnum);
  const [adminData, setAdminData] = useState(initialAdminData);

  const [hasAdminDataChanged, setHasAdminDataChanged] = useState(false);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const isInput = (element: EventTarget): element is HTMLInputElement =>
      element instanceof HTMLInputElement;

    const { name, value } = e.target;

    let inputValue: string | boolean = value;
    if (isInput(e.target) && e.target.type === "checkbox") {
      inputValue = e.target.checked;
    }
    switch (name) {
      case "first_name":
        break;
      case "last_name":
        break;
      case "email":
        inputValue = value.toLowerCase();
        break;
      default:
        break;
    }

    setAdminData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  useEffect(() => {
    const isDataChanged = () => {
      return Object.keys(initialAdminData).some(
        (key) => adminData[key] !== initialAdminData[key]
      );
    };

    setHasAdminDataChanged(isDataChanged());
  }, [adminData]);

  const handleClose = () => {
    setAdminData(initialAdminData);
    props.onClose();
  };
  const handleCreateAdmin = async () => {
    try {
      const adminToCreate = {
        ...adminData,
      };

      const createAdminResponse = await createAdmin(adminToCreate);
      if (!createAdminResponse) return;

      toast.success(`Admin: ${adminToCreate.first_name} skapad`);

      handleClose();
      props.onConfirm();
    } catch (error: any) {
      const errorMessage =
        error.message || "An unexpected error occurred. Please try again.";
      toast.error(`Error creating Admin: ${errorMessage}`);
    }
  };

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={() => setShowHandleCreateAdminPopup(true)}
      leftTopElement={<span className="text-m">Lägg till ny admin</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAdmin();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="firstName" className="text-m">
                Förnamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="firstName"
                name="first_name"
                required
                placeholder="Förnamn"
                value={capitalizeWords(adminData.first_name)}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <label htmlFor="lastName" className="text-m">
                Efternamn*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                id="lastName"
                required
                name="last_name"
                placeholder="Efternamn"
                value={capitalizeWords(adminData.last_name)}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="email" className="text-m">
                E-postadress*
              </label>
              <input
                className="on-transparent text-m"
                type="text"
                name="email"
                id="email"
                required
                placeholder="Ange E-postadress"
                value={adminData.email}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="general-popup-flex-end">
            <button
              type="submit"
              className="text-m"
              disabled={!hasAdminDataChanged}
            >
              Skapa ny admin
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleCreateAdminPopup}
        onClose={() => setShowHandleCreateAdminPopup(false)}
        onConfirm={() => handleClose()}
        questionText={`Är du säker på att avbryta?`}
        confirmText="Avbryt"
        denyText="Nej"
      />
    </Popup>
  );
}

export default CreateAdminPopup;
