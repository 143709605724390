import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";

export const changePassword = async (member_id: string, password: string) => {
  const query = `mutation MyMutation($member_id: String!, $password: String!) {
        AdminSetPassword(member_id: $member_id, password: $password) {
            ... on User {
            account_type
            email_cc
            email
            }
            ... on Error {
            code
            message
            }
        }
    }`;

  try {
    const response = (await API.graphql({
      query: query,
      variables: { member_id: member_id, password: password },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    if ("message" in response.data.AdminSetPassword) {
      toast.error(`${response.data.AdminSetPassword.message}`);
      return false;
    }
    return response.data.AdminSetPassword;
  } catch (error: any) {
    toast.error(`${error.errors[0].message}`);
    return false;
  }
};
