import { useEffect, useState } from "react";
import "./AdminHandleFacility.scss";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import { IFacility } from "../../types/api";
import {
  formatDateToSwedish,
  getSwedishWeekday,
  getWeekdaySwedishName,
} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "react-toastify";

interface IAdminHandleFacilityProps {}

function AdminHandleFacility(props: IAdminHandleFacilityProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [facility, setFacility] = useState<IFacility>();

  const fetchChosenItems = async ({ fetchFacility = false }) => {
    setLoading(true);
    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchFacility: fetchFacility,
      });
      if (
        fetchFacility &&
        response.facility &&
        !("message" in response.facility)
      ) {
        setFacility(response.facility);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchFacility: true,
    });
  }, []);
  if (!facility) return <Spinner />;
  return (
    <div className="admin-handle-facility-layout">
      <h1 className="text-l primary-text">Ordinarie öppettider</h1>
      <div className="handle-facility-actions-wrapper">
        {/* <button> Redigera öppettider </button> */}
      </div>
      <div className="facility-times-wrapper text-m">
        {facility.default_hours.map((item, index) => {
          const timesString =
            item.opening_hours.slice(0, 5) +
            " - " +
            item.closing_hours.slice(0, 5);
          const day = getWeekdaySwedishName(item.weekday);
          return (
            <div key={index} className="facility-time-item">
              <div className="facility-time-day">{day}</div>
              <div className="facility-time-times">{timesString}</div>
            </div>
          );
        })}
      </div>
      <h2 className="text-l primary-text">Avvikande ordinarie öppettider</h2>
      <div className="facility-seasonal-batches-wrapper text-m">
        {facility.seasonal_hours.map((seasonInterval, index) => {
          return (
            <div key={index} className="facility-seasonal-times-wrapper text-m">
              <h3 className="text-l secondary-text">
                {seasonInterval.alias} ({seasonInterval.start_date} -{" "}
                {seasonInterval.end_date})
              </h3>
              {seasonInterval.weekday_hours.map((item, index) => {
                const timesString =
                  item.opening_hours.slice(0, 5) +
                  " - " +
                  item.closing_hours.slice(0, 5);
                const day = getWeekdaySwedishName(item.weekday);
                return (
                  <div className="facility-time-item">
                    <div className="facility-time-day">{day}</div>
                    <div className="facility-time-times">{timesString}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="facility-special-days-wrapper text-m">
        <h2 className="text-l primary-text">Dagsaktuella öppettider</h2>
        {facility.special_days.map((day, index) => {
          if (day.closing_hours === "Stängt")
            return (
              <div
                key={index}
                className="facility-special-days-times-wrapper text-m"
              >
                <div className="facility-time-item">
                  <div className="facility-time-day">
                    <span>{day.alias} </span>
                    <span>{day.date}</span>
                  </div>
                  <div className="facility-time-times">Stängt</div>
                </div>
              </div>
            );
          return (
            <div
              key={index}
              className="facility-special-days-times-wrapper text-m"
            >
              <div className="facility-time-item">
                <div className="facility-time-day">
                  <span>{day.alias} </span>
                  <span>{day.date}</span>
                </div>
                <div className="facility-time-times">
                  {day.opening_hours.slice(0, 5)} -{" "}
                  {day.closing_hours.slice(0, 5)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AdminHandleFacility;
