import { useLocation } from "react-router-dom";
import Navbar from "../../containers/Navbar/Navbar";
import "./Layout.scss";

interface ILayoutProps {
  children: React.ReactNode;
  isAdmin?: boolean;
  isCoach?: boolean;
}

function Layout(props: ILayoutProps) {
  const { isAdmin, isCoach } = props;
  const location = useLocation();
  return (
    <div className={`app-wrapper ${isAdmin ? "app-wrapper-admin" : ""}`}>
      {location.pathname !== "/visualArt" && (
        <Navbar isAdmin={isAdmin} isCoach={isCoach} />
      )}

      <div className="content">{props.children}</div>
    </div>
  );
}

export default Layout;
