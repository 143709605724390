import { useEffect, useState } from "react";
import "./UserMembers.scss";
import { IUser } from "../../types/api";
import { listUsers, searchUsers } from "../../api/users/users";
import {
  capFirstAndRemoveDash,
  capitalizeWords,
} from "../../shared/utility";
import { toast } from "react-toastify";

interface IAdminHandleusersProps {}

function AdminHandleUsers(props: IAdminHandleusersProps) {
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);

  const [searchString, setSearchString] = useState<string>("");

  async function fetchUsers() {
    try {
      const users = await listUsers("user", 30, "none");
      setUsers(users.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }
  useEffect(() => {
    fetchUsers();
  }, []);

  /** Search users */
  const fetchSearchUsers = async (search: string) => {
    try {
      const response = await searchUsers(search, "user", false);
      if (response.length === 0) {
        toast.warning("Inga användare hittades");
      }
      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (searchString.length > 0) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };
  /** End search users  */

  const usersToDisplay = searchString.length > 0 ? searchedUsers : users;
  if (users.length === 0) return null;
  return (
    <div className="user-handle-users-layout">
      <h1 className="text-l primary-text">Medlemmar</h1>
      <div className="handle-users-actions-wrapper">
        <div className="search-input-wrapper">
          <input
            className="users-search-input text-m"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder="Sök "
          ></input>
          <i className="fa-regular fa-magnifying-glass search-icon"></i>
        </div>
      </div>
      <table className="users-table-no-click">
        <thead>
          <tr>
            <th className="text-m secondary-text">Medlemsnummer</th>
            <th className="text-m secondary-text">Förnamn</th>
            <th className="text-m secondary-text">Efternamn</th>
            <th className="text-m secondary-text">Medlemskap</th>
            <th className="text-m secondary-text">Telefon</th>
          </tr>
        </thead>
        <tbody>
          {usersToDisplay.map((user, index) => {
            return (
              <tr key={index}>
                <td className="text-m primary-text">{user.member_id}</td>
                <td className="text-m primary-text">
                  {capitalizeWords(user.first_name)}
                </td>
                <td className="text-m primary-text">
                  {capitalizeWords(user.last_name)}
                </td>
                <td className="text-m primary-text">
                  {capFirstAndRemoveDash(user.member_type)}
                </td>

                <td className="text-m primary-text">{user.phone_number}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminHandleUsers;
